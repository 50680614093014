import { useSearchParams } from "react-router-dom"
import TxTable from "../../../components/tables/transactions-table/table"
import CenteredTableBody from "../../../components/ui/layouts/platform/body/CenteredTableBody"

const TransactionsBody = () => {
	const [searchParams, setSearchParams] = useSearchParams()
	return (
		<CenteredTableBody className="max-h-[95%] overscroll-none overflow-hidden   scrollbar-hide">
			<TxTable
				transaction_id={searchParams.get("transaction_id") ?? undefined}
				payment_id={searchParams.get("payment_id") ?? undefined}
				showFilters
				scrollable
				viewOptions
				onCloseModal={() => {
					setSearchParams({})
				}}
			/>
			<div className="pointer-events-none fixed inset-x-0 bottom-0 lg:bottom-[4%] h-[7%] bg-gradient-to-t from-white dark:from-background" />
		</CenteredTableBody>
	)
}

export default TransactionsBody
