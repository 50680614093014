import { Info } from "lucide-react"
import { getAmountInPreferredCurrency } from "../../../lib/utils"
import { WithdrawData } from "../../../pages/withdraw/withdraw-wizard-types"
import { Alert } from "../../ui/alert"
import { Button } from "../../ui/button"
import Col from "../../ui/layouts/column"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import Row from "../../ui/layouts/row"
import { Section } from "../../ui/section"
import { SectionHeader } from "../../ui/section-header"
import { Separator } from "../../ui/separator"
import { SheetContent } from "../../ui/sheet"
import { Heading1, Heading5, Heading6 } from "../../ui/texts/TextStyle"
import BodyItem from "../payment-modal/body/BodyItem"
import { formatAmountAndCurrency } from "./WithdrawConfirmationModal"

interface CryptoSheetContentProps {
	data: WithdrawData
	handleConfirm: () => void
	prices?: any
}

export default function CryptoSheetContent({
	data,
	handleConfirm,
	prices,
}: CryptoSheetContentProps) {
	return (
		<SheetContent side="right">
			<CenteredBody>
				<SectionHeader
					title="Confirmá tu envío"
					subtitle="Estás por retirar dinero de tu cuenta."
				/>
				<Col className="gap-betweenCards">
					<Heading6 className="text-text-muted font-medium">Recibirás</Heading6>
					<Row className="gap-betweenTexts">
						<img
							src={`/currencies/${data?.currency?.toLowerCase()}-icon.svg`}
							alt=""
							className="size-10"
						/>
						<Col className="gap-betweenTexts">
							<Heading1 className="font-normal text-text">
								{formatAmountAndCurrency(
									data.quote.amount,
									data.quote.currency,
									true
								)}
							</Heading1>
							<div className="flex">
								{/* TODO make it show the amount in preferred currency (ARS) */}
								<Heading5 className="font-medium text-text-muted">
									{`(${formatAmountAndCurrency(
										getAmountInPreferredCurrency(
											data.quote.amount,
											data.quote.currency,
											"ARS",
											prices
										),
										"ARS",
										false
									)})`}
								</Heading5>
							</div>
						</Col>
					</Row>
				</Col>

				<Separator orientation="horizontal" className="bg-border" />
				<Section>
					<BodyItem
						name="Dirección de destino"
						value={data.address ?? ""}
						overflow
					/>
					<BodyItem name="Red" value={data.network ?? ""} />
					<BodyItem
						name="Costo de la red"
						value={
							<Row className="gap-betweenTexts">
								<span>
									{formatAmountAndCurrency(
										data.quote.fee.amount_rounded,
										data.quote.fee.currency,
										true
									)}
								</span>

								{
									<span className="text-text-muted">{`(${formatAmountAndCurrency(
										getAmountInPreferredCurrency(
											data.quote.fee.amount_rounded,
											data.quote.fee.currency,
											"ARS",
											prices
										),
										"ARS",
										false
									)})`}</span>
								}
							</Row>
						}
					/>
					<BodyItem
						name="Monto total"
						value={
							<Row className="gap-betweenTexts">
								<span>
									{formatAmountAndCurrency(
										data.quote.total_amount,
										data.quote.currency,
										true
									)}
								</span>

								<span className="text-text-muted">{`(${formatAmountAndCurrency(
									getAmountInPreferredCurrency(
										data.quote.total_amount,
										data.quote.currency,
										"ARS",
										prices
									),
									"ARS",
									false
								)})`}</span>
							</Row>
						}
					/>
				</Section>
				<Alert
					icon={<Info />}
					title="Cuidado"
					variant="warning"
					description="Asegurate de que tu billetera soporte la red seleccionada. De lo contrario perderás los fondos"
				/>
				<Button
					size="full"
					onClick={handleConfirm}
					data-test="withdraw-confirmation-modal-confirm-button"
				>
					Confirmar
				</Button>
			</CenteredBody>
		</SheetContent>
	)
}
