import { UseMutationResult, useMutation } from "@tanstack/react-query"
import { apiPrivate } from "../services/interceptors"

export type OrderActionType = "confirm" | "cancel"

export type ConfirmPayload = {
	reason: ConfirmationReason
}

export type CancelPayload = {
	reason: CancelationReason
	notifyClient: boolean
	restock: boolean
	staffNote: string
}

export type OrderAction =
	| {
			order_action: "confirm"
			confirm_payload: ConfirmPayload
	  }
	| {
			order_action: "cancel"
			cancel_payload: CancelPayload
	  }

export type CancelationReason =
	| "CUSTOMER"
	| "DECLINED"
	| "FRAUD"
	| "INVENTORY"
	| "OTHER"
	| "STAFF"

export type ConfirmationReason =
	| "EXTERNAL_PAYMENT_METHOD"
	| "COMBINED_ORDERS_PAYMENT"
	| "TOLERABLE_UNDERPAYMENT"
	| "OTHER"

async function putOrderAction(payment_id: string, action: OrderAction) {
	await apiPrivate.put(`/payments/${payment_id}/order`, action)
}

export const useMutateOrderAction = (
	payment_id: string | undefined
): UseMutationResult<void, Error, OrderAction> => {
	return useMutation({
		mutationKey: ["order-action", payment_id],
		mutationFn: (action: OrderAction) => {
			if (!payment_id) {
				throw new Error("Payment ID is required")
			}
			return putOrderAction(payment_id, action)
		},
	})
}
