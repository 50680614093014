import {
	Identify,
	identify,
	init,
	setUserId,
	track,
} from "@amplitude/analytics-browser"
import { ReactNode, useEffect } from "react"
import { useAuth } from "../../context/AuthContext"
import { AnalyticsContext, AnalyticsContextState } from "./AnalyticsContext"

const amplitudeAnalytics: AnalyticsContextState = {
	setUser: (id) => {
		try{
			setUserId(id)
		}catch(error){
			console.error("Error setting user in analytics provider", err)
		}
	},
	trackEvent: (name, data) => {
		try{
			track(name, data)
		}catch(error){
			console.error("Error tracking event", err)
		}
	},
	setUserProperty: (name, value) => {
		try{
			const identifyEvent = new Identify()
			identifyEvent.set(name, value)
			identify(identifyEvent)
		}catch(error){
			console.error("Error settting user property", err)
		}
	},
}

export const AmplitudeAnalyticsProvider = ({
	children,
	apiKey,
}: {
	children: ReactNode | ReactNode[]
	apiKey: string
}) => {
	const { user } = useAuth()
	useEffect(() => {
		init(apiKey)
	}, [apiKey])

	useEffect(() => {
		if (user?.team_id || user?.user_id)
			amplitudeAnalytics.setUser(user.team_id ?? user.user_id)
	}, [user?.team_id, user?.user_id])

	return (
		<AnalyticsContext.Provider value={amplitudeAnalytics}>
			{children}
		</AnalyticsContext.Provider>
	)
}
