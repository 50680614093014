import { isTransferOrCrypto } from "../../../lib/utils"
import {
	Price,
	TableTransaction,
} from "../../tables/transactions-table/columns"
import SlidingCard from "../../ui/sliding-card"
import ExpiredPaymentModalBody from "./body/ExpiredPaymentModalBody"
import OutboundTransactionModalBody from "./body/OutboundTransactionModalBody"
import PendingPaymentModalBody from "./body/PendingPaymentModalBody"
import SuccessPaymentModalBody from "./body/SuccessPaymentModalBody"
import PaymentStatusRow from "./header/PaymentStatusRow"
import { useState, useEffect } from 'react'

export interface PaymentComission {
	comission?: Price
	comissionInRequestedCurrency?: Price
	percent?: number
}

interface PaymentModalProps {
	open: boolean
	onClose: () => void
	payment: TableTransaction
	onPaymentUpdate: (payment: TableTransaction) => void;
}

const PaymentModal = ({ open, onClose, payment: initialPayment, onPaymentUpdate }: PaymentModalProps) => {
  const [currentPayment, setCurrentPayment] = useState(initialPayment);

  useEffect(() => {
    setCurrentPayment(initialPayment);
  }, [initialPayment.id]);

  const handlePaymentUpdate = (updatedPayment: TableTransaction) => {
    setCurrentPayment(updatedPayment);
    onPaymentUpdate(updatedPayment);
  };

  useEffect(() => {
    if (!open) {
      setCurrentPayment(initialPayment);
    }
  }, [open, initialPayment]);

  return (
    <SlidingCard
      open={open}
      onOpenChange={(open) => {
        if (!open) onClose()
      }}
      side={"right"}
    >
      <div>
        <PaymentStatusRow 
          status={currentPayment.status} 
          payment={currentPayment}
          onPaymentUpdate={handlePaymentUpdate}
        />
        {renderModalBody(currentPayment)}
      </div>
    </SlidingCard>
  )
}

function renderModalBody(payment: TableTransaction) {
	switch (payment.status) {
		case "SUCCESS":
		case "OVERPAID":
		case "UNDERPAID":
		case "INBOUND":
			return <SuccessPaymentModalBody payment={payment} />
		case "pending":
		case "processed":
		case "SENT":
		case "PROCESSED":
		case "OUTBOUND":
			return (
				<OutboundTransactionModalBody
					payment={payment}
					variant={payment.currency === "ARS" ? "transfer" : "crypto"}
				/>
			)
		case "PENDING":
			return (
				<PendingPaymentModalBody
					payment={payment}
					variant={isTransferOrCrypto(payment.payment_options)}
				/>
			)
		case "EXPIRED":
			return (
				<ExpiredPaymentModalBody
					payment={payment}
					variant={isTransferOrCrypto(payment.payment_options)}
				/>
			)
	}
}

export default PaymentModal
