import { ReactNode } from "react"
import BodyTitle from "./BodyTitle"
import { Separator } from "../../../ui/separator"
import { Heading6 } from "../../../ui/texts/TextStyle"
import Spacer from "../../../ui/spacer"
import Col from "../../../ui/layouts/column"
import { Section } from "../../../ui/section"

const BodyBlock = ({
	children,
	title,
	isFirst = false,
	subtitle,
}: {
	children: ReactNode | ReactNode[]
	title: string
	isFirst?: boolean
	subtitle?: string
}) => {
	return (
		<div>
			{!isFirst && <Separator className="my-5  border border-border" />}
			<Col className="gap-betweenTexts">
				<BodyTitle title={title} />
				{subtitle ? (
					<Heading6 className="text-text-muted">{subtitle}</Heading6>
				) : null}
			</Col>
			<Spacer axis="vertical" type="belowSectionHeader" />
			<Section>{children}</Section>
		</div>
	)
}

export default BodyBlock
