import { ColumnDef, Row } from "@tanstack/react-table"
import { ArchiveX, Check, CircleX } from "lucide-react"
import PaymentSuccessStatus from "../../../ui/status-icons/PaymentSuccessStatus"
import PendingPaymentStatus from "../../../ui/status-icons/PendingPaymentStatus"
import PendingWithdrawStatus from "../../../ui/status-icons/PendingWithdrawStatus"
import RejectedWithdrawStatus from "../../../ui/status-icons/RejectedWithdrawStatus"
import SuccessWithdrawStatus from "../../../ui/status-icons/SuccessWithdrawStatus"
import { Heading6 } from "../../../ui/texts/TextStyle"
import { TableTransaction, TableTransactionStatus } from "../columns"

export const tableTransactionStatus = [
	"SUCCESS",
	"PENDING",
	"OVERPAID",
	"UNDERPAID",
	"SENT",
	"EXPIRED",
]
export function StatusColumn(): ColumnDef<TableTransaction> {
	return {
		accessorKey: "status",
		header: () => (
			<div className="text-left text-h6 items-center flex flex-row gap-2">
				<Check className="size-icon" />
				Estado
			</div>
		),
		filterFn: (
			row: Row<TableTransaction>,
			id: string,
			filterValue: string[]
		) => {
			const status = row.getValue(id) as string
			return filterValue.includes(status)
		},
		cell: ({ row }: { row: Row<TableTransaction> }) => {
			const status = row.getValue(
				"status"
			) as (typeof tableTransactionStatus)[number]

			return <StatusCell status={status} />
		},
		enableColumnFilter: true,
	}
}

export const statusIcons: {
	[key: TableTransactionStatus]: JSX.Element
} = {
	SUCCESS: <PaymentSuccessStatus className="w-7 h-7 stroke-[#1FC801]" />,

	processed: <SuccessWithdrawStatus className="w-7 h-7 stroke-icon-error" />,
	PROCESSED: <SuccessWithdrawStatus className="w-7 h-7 stroke-icon-error" />,
	SENT: <SuccessWithdrawStatus className="w-7 h-7 stroke-icon-error" />,
	OUTBOUND: <SuccessWithdrawStatus className="w-7 h-7 stroke-icon-error" />,

	UNMATCHED: <PaymentSuccessStatus className="w-7 h-7 stroke-icon-warning" />,

	processing: <PendingWithdrawStatus className="w-7 h-7 stroke-icon-muted" />,
	pending: <PendingWithdrawStatus className="w-7 h-7" />,

	PENDING: <PendingPaymentStatus className="w-7 h-7" />,

	OVERPAID: <PaymentSuccessStatus className="w-7 h-7 stroke-icon-warning" />,
	UNDERPAID: <PaymentSuccessStatus className="w-7 h-7 stroke-icon-warning" />,
	INBOUND: <PaymentSuccessStatus className="w-7 h-7 stroke-[#1FC801]" />,

	EXPIRED: <CircleX className="w-7 h-7  stroke-icon-muted" />,

	rejected: <RejectedWithdrawStatus />,
	reversed: <RejectedWithdrawStatus />,
}

export const statusTranslations: {
	[key: TableTransactionStatus]: string
} = {
	SUCCESS: "Completa",
	PENDING: "Pendiente",
	pending: "Pendiente",
	OVERPAID: "Sobrante",
	UNDERPAID: "Insuficiente",
	SENT: "Enviada",
	INBOUND: "Recibida",
	PROCESSED: "Enviada",
	processed: "Enviada",
	EXPIRED: "Expirado",
	REJECTED: "Rechazada",
	rejected: "Rechazado",
	UNMATCHED: "Sin asignar",
}

export const statusDescriptions: {
	[key: TableTransactionStatus]: string
} = {
	SUCCESS: "El pago fue completado en su totalidad",
	PENDING: "A la espera de confirmación del pago",
	pending: "Retiro pendiente de confirmación",
	OVERPAID: "Se recibió el pago con un monto mayor al solicitado",
	UNDERPAID: "Se recibió el pago con un monto menor al solicitado",
	INBOUND: "El dinero se acreditó en su totalidad",
	SENT: "El envío de dinero fue completado en su totalidad",
	processed: "El envío de dinero fue completado en su totalidad",
	EXPIRED: "El tiempo para completar el pago ha expirado",
	REJECTED: "El pago fue rechazado",
	rejected: "El envío fue rechazado",
}

export function StatusCell({
	status,
}: {
	status: (typeof tableTransactionStatus)[number]
}) {
	return (
		<div className="flex items-center w-fit ">
			{statusIcons[status]}
			<Heading6 className="capitalize ml-2 font-medium font-h6 group-hover:translate-x-1 transition duration-400">
				{statusTranslations[status]}
			</Heading6>
		</div>
	)
}
