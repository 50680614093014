import {
	ColumnDef,
	ColumnFiltersState,
	Row,
	RowSelectionState,
	VisibilityState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	useReactTable,
} from "@tanstack/react-table"
import { Lightbulb } from "lucide-react"
import { useState } from "react"
import { Alert } from "../alert"
import { Skeleton } from "../skeleton"
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../table/table"
import {
	DataTablePagination,
	PaginationHandler,
} from "./data-table-pagination-footer"
import { use } from "chai"

export interface DataTableProps<TData, TValue> {
	columns: ColumnDef<TData, TValue>[]
	data: TData[]
	onRowSelected?: (row: TData) => void
	className?: string
	rowHeightClass?: string
	viewOptions?: boolean
	variant?: "default" | "bordered"
	rowClassName?: (row: Row<TData>) => string
	loading?: boolean
	paginationHandler?: PaginationHandler
	tableItem?: string
	columnVisibility?: Record<string, boolean>
}

export function DataTable<TData, TValue>({
	columns,
	data,
	onRowSelected,
	className,
	rowHeightClass = "h",
	viewOptions,
	variant = "default",
	rowClassName = () => "",
	loading = false,
	paginationHandler,
	tableItem,
	columnVisibility,
  }: DataTableProps<TData, TValue>) {
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  
	const table = useReactTable({
	  data,
	  columns,
	  getCoreRowModel: getCoreRowModel(),
	  getFilteredRowModel: getFilteredRowModel(),
	  onColumnFiltersChange: setColumnFilters,
	  onRowSelectionChange: setRowSelection,
	  state: {
		columnFilters,
		columnVisibility: columnVisibility,
		rowSelection,
	  },
	  enableFilters: true, 
	  manualFiltering: false,
	})

	return (
		<div>
			<div
				className={
					variant === "bordered"
						? "h-full rounded-md border border-border bg-background-subtle"
						: ""
				}
			>
				<Table
					className={className}
					containerClassName={`h-fit w-full max-h-[780px] rounded-md ${
						variant === "bordered" ? "" : "pb-16"
						} [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] overflow-y-auto`}
				>
					<TableHeader
						className={`  ${
							variant === "bordered" ? "" : "sticky top-0 bg-background z-30"
						} `}
					>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableHead key={header.id}>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef.header,
														header.getContext()
												  )}
										</TableHead>
									)
								})}
							</TableRow>
						))}
					</TableHeader>
					<TableBody>
						{loading ? (
							<TableRow>
								<TableCell colSpan={columns.length} className="h-24">
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
									<Skeleton className="h-[50px] w-full mb-betweenOptions" />
								</TableCell>
							</TableRow>
						) : table.getRowModel().rows?.length ? (
							table.getRowModel().rows.map((row) => (
								<TableRow
									className={`hover:bg-background p-xs group ${rowHeightClass} ${rowClassName(
										row
									)}`}
									key={row.id}
									data-state={row.getIsSelected() && "selected"}
									onClick={() => {
										if (onRowSelected) {
											onRowSelected(row.original)
										}
										row.toggleSelected(!row.getIsSelected())
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<TableCell key={cell.id}>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext()
											)}
										</TableCell>
									))}
								</TableRow>
							))
						) : (
							<TableRow>
								<TableCell
									colSpan={columns.length}
									className="h-24 bg-background rounded-b-md  py-6 "
								>
									<Alert
										title={`Parece que todavía no hay ${
											tableItem ?? "transacciones"
										}`}
										description={`Cuando haya ${
											tableItem ?? "transacciones"
										}, las veras reflejadas aca.`}
										icon={<Lightbulb />}
									></Alert>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</div>
			{paginationHandler && (
				<DataTablePagination paginationHandler={paginationHandler} />
			)}
		</div>
	)
}
