import { Price, TransferQuote } from "@crypto-payment-gateway/models"
import { InfoIcon } from "lucide-react"
import { Quote } from "../../../pages/checkout/id"
import { formatDate } from "../../../utils/format"
import { formatAmountAndCurrency } from "../../modals/withdraw-confirmation/WithdrawConfirmationModal"
import { Alert } from "../../ui/alert"
import Col from "../../ui/layouts/column"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import Row from "../../ui/layouts/row"
import { Section } from "../../ui/section"
import { SectionHeader } from "../../ui/section-header"
import { Heading5, Heading6 } from "../../ui/texts/TextStyle"
import { toast } from "../../ui/use-toast"
import PaymentQuoteCard from "./PaymentQuoteCard"

const TransferPaymentCheckout = ({
	quotes,
	pendingHandler,
	price,
	expiration_timestamp,
	user_info,
}: {
	quotes: Quote[]
	pendingHandler: () => void
	price: Price
	expiration_timestamp: string
	user_info: {
		full_name?: string
		email: string
		cuit?: string
	}
}) => {
	const quote = quotes.find(
		(q: Quote) => q.currency === price.currency
	) as TransferQuote
	return (
		<CenteredBody className="pt-8">
			<SectionHeader
				title={"Datos de pago"}
				subtitle={
					<span>
						Podrás transferir a estos datos
						<span className="text-text-info font-bold animate-pulse">
							{" "}
							hasta el {formatDate(new Date(expiration_timestamp))}.
						</span>
					</span>
				}
			/>
			<Alert
				icon={<InfoIcon className="size-14" />}
				title="Cuidado"
				variant="warning"
				description={
					<span className="text-h6 text-text-warning-strong">
						Los datos de pago son{" "}
						<strong className="text-text-warning">
							exclusivos para esta orden de compra
						</strong>
						. No intentes volver a usarlos para otra compra.
					</span>
				}
			></Alert>
			<Section>
				<div className="w-full">
					<div className="grid grid-cols-2 gap-2">
						<div className="flex col-span-1">
							<PaymentQuoteCard headline="Titular" size="lg" status="enabled">
								<Col className="gap-betweenComponents group-hover/slide:translate-x-2 transition duration-500">
									<Heading5>
										{user_info?.full_name ?? user_info?.email}
									</Heading5>
									{user_info?.cuit && (
										<Row className="gap-betweenTexts">
											<Heading6 className="font-normal text-text-muted">
												CUIT:
											</Heading6>
											<Heading6 className="text-text font-semibold">
												{user_info?.cuit}
											</Heading6>
										</Row>
									)}
									<Row className="items-center gap-2">
										<div className=" justify-center [&>*]:rounded-full items-center">
											<img
												src="https://raw.githubusercontent.com/talo-pay/entidades-financieras-logos-arg/713c795bb21b5e3d67e73ce7c8b37dfd461824ed/logos/PSP/SVG/02167_POLUX%20CAPITALS%20S.A.svg"
												alt=""
												className="h-6 w-6"
											/>
										</div>
										<Heading6 className="font-normal text-text-muted">
											Polux Capitals
										</Heading6>
									</Row>
								</Col>
							</PaymentQuoteCard>
						</div>
						<div className="flex col-span-1">
							<PaymentQuoteCard
								headline="CVU"
								size="lg"
								warning="No podrás utilizar este CVU para otras compras."
								status="enabled"
								item={{ title: (quote!.address as string) || "" }}
								copyHandler={() => {
									navigator.clipboard.writeText(quote!.address! as string)
									toast({ title: "¡Copiado!" })
								}}
							/>
						</div>

						<div className="flex col-span-1">
							<PaymentQuoteCard
								headline="Monto"
								size="sm"
								status="enabled"
								item={{
									title: formatAmountAndCurrency(
										quote.amount,
										quote.currency,
										false
									),
								}}
								copyHandler={() => {
									navigator.clipboard.writeText(quote!.amount! as string)
									toast({ title: "¡Copiado!" })
								}}
							/>
						</div>
						<div className=" flex col-span-1">
							<PaymentQuoteCard
								headline="Alias"
								size="sm"
								warning="none"
								status="enabled"
								item={{ title: quote!.alias || "" }}
								copyHandler={() => {
									navigator.clipboard.writeText(quote!.alias! as string)
									toast({ title: "¡Copiado!" })
								}}
							/>
						</div>
					</div>
				</div>
			</Section>
		</CenteredBody>
	)
}

export default TransferPaymentCheckout
