import { Price } from "../../tables/transactions-table/columns"
import { TableMovement } from "../../tables/transactions-table/data"
import SlidingCard from "../../ui/sliding-card"
import OutboundTransactionModalBody from "../payment-modal/body/OutboundTransactionModalBody"
import InboundTransactionModalBody from "./body/InboundTransactionBody"
import TransactionStatusRow from "./header/TransactionStatusRow"

export interface PaymentComission {
	comission?: Price
	comissionInRequestedCurrency?: Price
	percent?: number
}

interface TransactionModalProps {
	open: boolean
	onClose: () => void
	payment: TableMovement
}
const TransactionModal = ({
	open,
	onClose,
	payment,
}: TransactionModalProps) => {
	console.log(payment)
	return (
		<SlidingCard
			open={open}
			onOpenChange={(open) => {
				if (!open) onClose()
			}}
			side={"right"}
		>
			<div>
				<TransactionStatusRow status={payment.status} />
				{renderModalBody(payment)}
			</div>
		</SlidingCard>
	)
}
function renderModalBody(payment: TableMovement) {
	switch (payment.status) {
		case "UNMATCHED":
		case "INBOUND":
		case "PROCESSED":
			return <InboundTransactionModalBody transaction={payment} />
		case "pending":
		case "processed":
		case "SENT":
		case "rejected":
		case "OUTBOUND":
			return (
				<OutboundTransactionModalBody
					payment={payment}
					//esta no tiene payment_options porque es outbound
					variant={payment.currency === "ARS" ? "transfer" : "crypto"}
				/>
			)
	}
}

export default TransactionModal
