import { useQuery } from "@tanstack/react-query"
import { getUserStores, StoreType } from "../services/users"

export interface StoreApp {
	app_id: string
	store_type: StoreType
	app_data?: {
		script_id?: number
	}
	user_id: string
	store_id: string
}

export interface Store {
	// Required fields
	store_type: StoreType
	store_id: string
	user_id: string
	creation_timestamp: string
	last_modified_timestamp: string
	is_deleted: boolean

	// Optional fields
	store_name?: string
	store_url?: string
	contact_email?: string
	logo_url?: string | null
	ig_url?: string
	store_apps?: StoreApp[]

	// Platform specific fields
	tiendanube_blob?: {
		url_with_protocol: string
		country: string
		business_address: string | null
		email: string
	}

	// Shopify specific fields
	installation_link?: string
}



export default function useFetchStores(user_id: string | undefined) {
	return useQuery({
		queryKey: ["stores"],
		queryFn: async () => {
			if (!user_id) {
				throw new Error("User ID not provided")
			}
			const { data } = await getUserStores(user_id)
			return data.data.stores as Store[]
		},
	})
}
