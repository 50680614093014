import { ColumnDef, CellContext } from "@tanstack/react-table"
import { Calendar } from "lucide-react"

interface DateAndTimeCellProps<T> {
	accessorKey: keyof T
	enableDateFilter?: boolean
	filterFn?: any
}

export function DateCell<T>(props: DateAndTimeCellProps<T>): ColumnDef<T> {
	return {
		accessorKey: props.accessorKey as string,
		header: () => (
			<div className="text-center justify-center text-h6 items-center flex flex-row gap-2 w-fit">
				<Calendar className="size-icon" />
				Fecha
			</div>
		),
		enableColumnFilter: props.enableDateFilter ?? false,
		filterFn: props.filterFn,
		cell: (info: CellContext<T, unknown>) => {
			const dateTime = info.getValue() as {
				date: string
				time: string
			}

			const isToday =
				new Date(dateTime?.date).toDateString() === new Date().toDateString()

			return (
				<div className="flex flex-row gap-betweenComponents text-h6 text-nowrap justify-center w-fit">
					{isToday ? <div>{dateTime?.time}</div> : <div>{dateTime?.date}</div>}
				</div>
			)
		},
	}
}
