import { clsx, type ClassValue } from "clsx"
import { formatEther, parseEther, parseUnits } from "ethers"
import { NavigateFunction } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import { PaymentComission } from "../components/modals/payment-modal/PaymentModal"
import { Price } from "../components/tables/transactions-table/columns"
import { PaymentOption } from "../pages/charge/charge-wizard-types"
import { formatPriceAmount, trimBn } from "../utils/numbers"

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs))
}

export function shouldBeDefined<T>(value: T | undefined): T {
	if (value === undefined) {
		throw new Error("Value is undefined")
	}
	return value
}


export function hasMultipleCurrencies(payment: any) {
	if (payment.transaction_fields?.total_paid) {
		return payment.transaction_fields?.total_paid?.mixed_currency
	} else if (payment.transactions?.length > 1 && payment.transaction_fields) {
		return payment.transactions.some(
			(t: any) => t.currency !== payment.transaction_fields.currency
		)
	} else {
		return false
	}
}

export function getPaymentReceivedAmount(payment: any): Price | undefined {
	if (hasMultipleCurrencies(payment)) {
		if (payment.transaction_fields?.total_paid?.amount) {
			const amount = formatEther(payment.transaction_fields.total_paid.amount)
			const formattedAmount = formatPriceAmount(
				amount,
				undefined,
				payment.transaction_fields.total_paid?.currency
			)
			return formattedAmount
				? {
						amount: formattedAmount,
						currency: payment.transaction_fields?.total_paid?.currency,
				  }
				: undefined
		}
	} else {
		if (payment.transaction_fields) {
			const amount = formatEther(payment.transaction_fields.amount)
			const formattedAmount = formatPriceAmount(
				amount,
				undefined,
				payment.transaction_fields.currency
			)
			return formattedAmount
				? {
						amount: formattedAmount,
						currency: payment.transaction_fields.currency,
				  }
				: undefined
		}
	}
}

export function getPaymentExpectedAmount(
	payment: any,
	quote: any
): Price | undefined {
	if (
		payment.payment_status === "PENDING" ||
		payment.payment_status === "EXPIRED" ||
		!quote ||
		hasMultipleCurrencies(payment)
	) {
		const amount = formatPriceAmount(
			payment.price.amount,
			undefined,
			payment.price.currency
		)
		if (amount) {
			return {
				amount,
				currency: payment.price.currency,
			}
		}
	}
	if (payment.transaction_fields) {
		const amount = formatEther(quote?.amount)
		const formattedAmount = formatPriceAmount(
			amount,
			undefined,
			quote?.currency
		)
		if (formattedAmount) {
			return {
				amount: formattedAmount,
				currency: quote?.currency,
			}
		}
	}
}

export function getWithdrawnAmount(payment: any): Price | undefined {
	if (payment.amount) {
		var amount = formatPriceAmount(
			formatEther(payment.amount),
			undefined,
			payment.currency
		)
		if (amount && payment.currency) {
			return {
				amount: amount,
				currency: payment.currency,
			}
		}
	}
}
export function getWithdrawnAmountArs(payment: any): Price | undefined {
	if (payment.amount) {
		var amount = formatPriceAmount(payment.amount, undefined, payment.currency)
		if (amount && payment.currency) {
			return {
				amount: amount,
				currency: payment.currency,
			}
		}
	}
}
export function getPaymentCurrency(payment: any) {
	if (hasMultipleCurrencies(payment)) {
		return payment.transaction_fields?.total_paid?.currency
	}
	return payment.transaction_fields?.currency
}

export function getPaymentReceivedAmountArs(payment: any) {
	if (payment.transaction_fields && payment.transaction_fields.total_paid) {
		return {
			amount: formatPriceAmount(payment.transaction_fields.total_paid.amount)!,
			currency: payment.transaction_fields.total_paid.currency,
		}
	}
}

export function getPaymentCreditedAmountArs(payment: any) {
	if (
		payment.transaction_fields &&
		payment.transaction_fields.credited_amount
	) {
		return {
			amount: formatPriceAmount(payment.transaction_fields.credited_amount)!,
			currency: payment.transaction_fields.currency,
		}
	} else if (payment.transaction_fields?.total_paid) {
		return {
			amount: formatPriceAmount(
				parseFloat(payment.transaction_fields.total_paid.amount) -
					parseFloat(payment.transaction_fields.commission_amount)
			)!,
			currency: payment.transaction_fields.currency,
		}
	}
}

export function getPaymentReceivedAmountInRequestedCurrency(
	payment: any,
	quote: any
): Price | undefined {
	var amount
	var currency

	if (payment.transaction_fields?.total_paid) {
		amount = payment.transaction_fields.total_paid.amount
		currency = payment.transaction_fields.total_paid.currency
	}
	if (quote && payment.transaction_fields && payment.price) {
		const parsedAmount = BigInt(payment?.transaction_fields.amount)
		const parsedPriceAmount = parseUnits(payment?.price.amount.toString(), 18)
		const quoteAmount = BigInt(quote.amount)
		amount = formatEther((parsedAmount * parsedPriceAmount) / quoteAmount)
		currency = payment.price.currency
	}
	if (amount && currency) {
		const aux = formatPriceAmount(amount, undefined, currency)
		return aux
			? {
					amount: aux,
					currency,
			  }
			: undefined
	}
}

export function getPaymentCommissionArs(payment: any) {
	var amount
	var currency
	var percent
	if (
		payment.transaction_fields &&
		payment.transaction_fields.commission_amount
	) {
		amount = payment.transaction_fields.commission_amount
		currency = payment.transaction_fields.currency
		percent =
			((parseFloat(payment.transaction_fields.commission_amount) /
				parseFloat(payment.transaction_fields.total_paid.amount)) *
				100) /
			1.21
	} else if (
		payment.transaction_fields &&
		payment.transaction_fields.commission
	) {
		amount =
			(parseFloat(payment.transaction_fields.amount) *
				payment.transaction_fields.commission) /
			100
		currency = payment.transaction_fields.currency
		percent = payment.transaction_fields.commission / 1.21
	} else if (payment.transactions && payment.transactions.length > 1) {
		const trx = payment.transactions[0]
		amount = trx.commission_amount
		currency = trx.currency
	}
	return {
		comission:
			amount && currency ? { amount: amount, currency: currency } : undefined,
		comissionInRequestedCurrency:
			amount && currency ? { amount: amount, currency: currency } : undefined,
		percent: percent,
	}
}

export function getPaymentComission(
	payment: any,
	quote: any
): PaymentComission | undefined {
	let amount: string | undefined
	let currency: string | undefined
	let amountInRequestedCurrency: string | undefined
	let requestedCurrency: string | undefined

	if (
		payment.transaction_fields &&
		payment.transaction_fields.commission_amount
	) {
		amount = formatEther(BigInt(payment.transaction_fields.commission_amount))
		currency = payment.transaction_fields.commission_currency
	}
	if (
		payment.transaction_fields &&
		!payment.transaction_fields.total_paid?.mixed_currency
	) {
		const comissionPercent = BigInt(payment.transaction_fields.commission)
		const subtractedAmount =
			(BigInt(payment.transaction_fields.amount) * comissionPercent) /
			BigInt(100)
		amount = formatEther(subtractedAmount)
		currency = payment.transaction_fields.currency
	}

	if (quote.amount && amount) {
		const amountBigInt = BigInt(parseEther(amount).toString())
		const paymentPriceBigInt = BigInt(
			parseEther(payment.price.amount.toString()).toString()
		)
		const quoteAmountBigInt = BigInt(quote.amount)
		const aux = formatEther(
			(amountBigInt * paymentPriceBigInt) / quoteAmountBigInt
		)
		amountInRequestedCurrency = formatPriceAmount(aux)
		requestedCurrency = payment.price.currency
	}

	if (amount) {
		amount = formatPriceAmount(
			formatEther(BigInt(parseEther(amount).toString())),
			undefined,
			currency
		)
	}

	return {
		comission:
			amount && currency ? { amount: amount, currency: currency } : undefined,
		comissionInRequestedCurrency:
			amountInRequestedCurrency && requestedCurrency
				? {
						amount: amountInRequestedCurrency,
						currency: requestedCurrency,
				  }
				: undefined,
	}
}

export function getPaymentCreditedAmount(
	received: Price | undefined,
	comission: Price | undefined
): Price | undefined {
	if (!received || !comission) return
	if (received.currency !== comission.currency) return

	const receivedAmount = parseFloat(received.amount.toString().replace(",", ""))
	const comissionAmount = parseFloat(
		comission.amount.toString().replace(",", "")
	)
	const amount = formatPriceAmount(
		receivedAmount - comissionAmount,
		undefined,
		received.currency
	)
	if (amount) {
		return {
			amount: amount,
			currency: received.currency,
		}
	}
}

export function getPaymentNetwork(payment: any) {
	return payment.transaction_fields?.network
}

export function getPaymentAddress(payment: any) {
	try {
		return (
			payment.transaction_fields?.address ??
			payment.quotes[0].address ??
			undefined
		)
	} catch (error) {
		console.error("Error getting payment address", error)
	}
	return undefined
}

export function getPaymentAddressArs(payment: any) {
	return payment.quotes?.[0]?.address
}

export function getPaymentSenderAddress(payment: any) {
	if (payment.transactions && payment.transactions.length > 0) {
		return payment.transactions[0].sender_address
	}
	return undefined
}

export function getPaymentSenderCuit(payment: any) {
	if (payment.transactions && payment.transactions.length === 1) {
		return payment.transactions[0]?.transaction_data?.PROCESSED?.senderCuit
	}
	return undefined
}

interface TransactionData {
    PROCESSED?: {
        senderTitular?: string;
    };
    [key: string]: any;
}

interface Transaction {
    transaction_data?: TransactionData;
    [key: string]: any;
}

export function getPaymentSenderTitular(payment: any): string | undefined {
    try {
        if (!payment?.transactions || !Array.isArray(payment.transactions)) {
            return undefined;
        }

        if (payment.transactions.length === 0) {
            return undefined;
        }

        // First try the direct path for efficiency
        const firstTransaction = payment.transactions[0];
        const directPathTitular = firstTransaction?.transaction_data?.PROCESSED?.senderTitular;
        
        if (directPathTitular) {
            return directPathTitular;
        }

        // If not found in direct path, do a deep search
        const findSenderTitular = (obj: any, depth = 0): string | undefined => {
            if (!obj || typeof obj !== 'object') return undefined;
            if (depth > 5) return undefined; // Prevent infinite recursion

            // Check PROCESSED path first
            if (obj.PROCESSED?.senderTitular) {
                return obj.PROCESSED.senderTitular;
            }

            // Check all other paths
            for (const key in obj) {
                if (typeof obj[key] === 'object') {
                    const found = findSenderTitular(obj[key], depth + 1);
                    if (found) return found;
                }
            }

            return undefined;
        };

        // Search through each transaction
        for (const transaction of payment.transactions) {
            const found = findSenderTitular(transaction);
            if (found) return found;
        }

        return undefined;
    } catch (error) {
        console.error("[getPaymentSenderTitular] Error:", error);
        return undefined;
    }
}

export function getPaymentAlias(payment: any) {
	return payment.quotes?.[0]?.alias
}

export function getFormattedStoreUrl(store_url: string) {
	if (!store_url || store_url === "") {
		return undefined
	}
	if (store_url.includes("://")) {
		const match = store_url.match(/\/\/(.*)/)
		if (match && match[1]) {
			return match[1]
		}
		return undefined
	} else {
		return store_url
	}
}

type Bank_List = {
	bancos: {
		[key: string]: {
			img: string
			legalName: string
			displayName: string
		}
	}
	psp: {
		[key: string]: {
			img: string
			legalName: string
			displayName: string
		}
	}
}

export function findImgForBankInJson(bank_id: string, json: Bank_List) {
	let bank_img = json.bancos[bank_id] ? json.bancos[bank_id].img : undefined

	if (!bank_img) {
		bank_img = json.psp[bank_id] ? json.psp[bank_id].img : undefined
	}

	return bank_img
}

export function findDisplayNameForBankInJson(bank_id: string, json: Bank_List) {
	let bank_name = json.bancos[bank_id]
		? json.bancos[bank_id].displayName
		: undefined
	if (!bank_name) {
		bank_name = json.psp[bank_id] ? json.psp[bank_id].displayName : undefined
	}
	return bank_name
}

export function isTransferOrCrypto(
	payment_options: PaymentOption[] | undefined
) {
	if (!payment_options) {
		return undefined
	}

	try {
		if (
			payment_options.includes(PaymentOption.CRYPTO) ||
			payment_options.includes(PaymentOption.PIX)
		) {
			return "crypto"
		}
		if (payment_options.includes(PaymentOption.TRANSFER)) {
			return "transfer"
		}
	} catch (err) {
		console.error("Error Parsing isTransferOrCrypto", err)
	}
	return undefined
}

export function getPspOrBankId(str: string): [pspOrBankId : string, "psp_id" | "bank_id"] {
	if (str.startsWith("000")) {
		return [str.substring(3, 7), "psp_id"]
	} else {
		return [str.substring(0, 4), "bank_id"]
	}
}
//TODO: When user is typed changed the 'any' notation to the correct type
export function redirectToEndOfOnboarding(
	navigate: NavigateFunction,
	user: any
) {
	const kycEpoch = new Date(2024, 7, 21)
	const userCreationDate = new Date(user.creation_timestamp)
	if (userCreationDate < kycEpoch) {
		navigate("/settings/payment-methods")
	} else {
		navigate("/onboarding/success")
	}
}

export function getAmountInPreferredCurrency(
	amount: string,
	currency: string,
	destinationCurrency: string | undefined,
	prices: Price[]
) {
	return Number(
		trimBn(
			calculateConversionPrice(
				{
					amount: formatEther(amount),
					currency: currency,
				},
				destinationCurrency,
				prices
			)
		)
	)
}

export function calculateConversionPrice(
	originalPrice: { amount: string; currency: string },
	destinationCurrency: any,
	prices: any
): string {
	if (!prices || !originalPrice || !destinationCurrency) {
		throw new Error("Missing data to calculate conversion price")
	}
	if (originalPrice.currency === destinationCurrency) {
		return originalPrice.amount
	}
	// Get original price currency conversion rate
	const conversionRateOrigin = prices.find(
		(elem: any) => elem.currency === originalPrice.currency
	)?.amount
	const conversionRateDestination = prices.find(
		(elem: any) => elem.currency === destinationCurrency
	)?.amount
	// Regla de 3 simple
	// destPriceConversionRate /(orignalPriceConversionRateAmount * originalPrice.amount
	const amountInDestinationCurrency =
		(BigInt(conversionRateDestination) * parseEther(originalPrice.amount)) /
		BigInt(conversionRateOrigin)

	//cant convert from number with decimals to bigint so we convert to string

	return formatEther(amountInDestinationCurrency.toString())
}
