import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTransaction } from "../columns"
import { Store } from "../../../../hooks/useFetchStores"
import { Store as StoreIcon } from "lucide-react"
import { TableMovement } from "../data"

export function StoreCell<T extends TableTransaction | TableMovement>({
	accessorKey,
	header,
	stores,
}: {
	accessorKey: string
	header: string
	stores: Store[]
}): ColumnDef<T> {
	return {
		accessorKey: accessorKey,
		size: 270,
		enableHiding: true,
		enableResizing: true,
		header: () => (
			<div className="text-left text-h6 items-center flex flex-row gap-2">
				<StoreIcon className="size-icon" />
				{header}
			</div>
		),
		cell: ({ row }: { row: Row<T> }) => {
			try {
				if (stores && stores.length < 2) {
					return
				}
				const store_name = row.original.store_name as string
				if (!store_name || (store_name && store_name === "")) {
					return (
						<div className="justify-end  h-4 w-full rounded-xs bg-background-elevated"></div>
					)
				} else {
					return (
						<div className="text-h6 font-medium w-fit block overflow-hidden overflow-ellipsis m-0 text-nowrap">
							{store_name}
						</div>
					)
				}
			} catch {
				return
			}
		},
		// Add or update the filter function to invert selection
		filterFn: (row , columnId, filterValue) => {
			const storeName = String(row.getValue(columnId))
			return !filterValue.includes(storeName)
		},
	}
}
