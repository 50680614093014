import { ColumnDef, Row } from "@tanstack/react-table"
import { Hash } from "lucide-react"
import { TableData } from "../../../ui/data-table/data-table-toolbar"
import { Chip } from "../../../ui/chip"
import { getOrderStatus, TableMovement } from "../data"
import { TableTransaction } from "../columns"

export function OrderCell<T extends TableData>({
	accessorKey,
	header,
}: {
	accessorKey: string
	header: string
}): ColumnDef<T> {
	return {
		accessorKey: accessorKey,
		size: 80,
		header: () => (
			<div className="text-left text-h6 items-center flex flex-row gap-2">
				<Hash className="size-icon" />
				{header}
			</div>
		),
		cell: ({ row }: { row: Row<T> }) => {
			const orderId = row.getValue(accessorKey) as string
			const action = getOrderStatus(row.original as TableTransaction)
			if (orderId === "-") {
				if (row.original.status === "UNMATCHED") {
					return (
						<Chip className="size-icon bg-yellow-500/5 text-text-warning border border-border-warning text-[12px] ">
							Sin asignar
						</Chip>
					)
				} else {
					return (
						<div className="justify-end max-w-[100px] h-4 rounded-xs bg-background-elevated"></div>
					)
				}
			} else {
				return (
					<div className="flex flex-col">
						<div className="text-h6 font-medium overflow-hidden text-ellipsis whitespace-nowrap">
							#{orderId}
						</div>
						{action && action.type === "confirm" && (
							<Chip
								size="xs"
								className="cursor-default bg-blue-500/10 text-blue-500 border-blue-500/40 text-xs whitespace-nowrap mt-1"
							>
								Confirmación manual
							</Chip>
						)}
						{action && action.type === "cancel" && (
							<Chip
								size="xs"
								className="cursor-default bg-red-500/10 text-red-500 border-red-500/40 text-xs whitespace-nowrap mt-1"
							>
								Cancelación manual
							</Chip>
						)}
					</div>
				)
			}
		},
	}
}
