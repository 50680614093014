"use client"

import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { cn } from "../../lib/utils"

const TableTabs = TabsPrimitive.Root

const TableTabsList = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.List>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={cn(
			"inline-flex w-full h-fit  items-center justify-center  text-h6 text-text-muted",
			className
		)}
		{...props}
	/>
))
TableTabsList.displayName = TabsPrimitive.List.displayName

const TableTabsTrigger = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Trigger>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Trigger
		ref={ref}
		className={cn(
			"inline-flex pb-3 p-4 gap-2  items-center justify-center whitespace-nowrap w-full h-full text-h6 font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border-b border-swatch-100  data-[state=active]:text-swatch-100 ",
			className
		)}
		{...props}
	/>
))
TableTabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TableTabsContent = React.forwardRef<
	React.ElementRef<typeof TabsPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={cn(
			"  ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
			className
		)}
		{...props}
	/>
))
TableTabsContent.displayName = TabsPrimitive.Content.displayName

export { TableTabs, TableTabsList, TableTabsTrigger, TableTabsContent }
