import {
	statusDescriptions,
	statusIcons,
	statusTranslations,
} from "../../../tables/transactions-table/columns/movementsTypeCell"
import { tableMovementStatus } from "../../../tables/transactions-table/data"
import { Heading5, Heading6 } from "../../../ui/texts/TextStyle"

const TransactionStatusRow = ({
	status,
}: {
	status: (typeof tableMovementStatus)[number]
}) => {
	return (
		<div className="flex flex-col bg-background-subtle mb-6 border border-border p-md rounded-md gap-betweenComponents">
			<div className={`flex flex-row items-center text-text `}>
				<>
					<div className="size-fit pr-betweenComponents">
						{statusIcons[status]}
					</div>
					<div className="flex flex-col">
						<Heading5 className="font-medium pb-[4px]">
							{statusTranslations[status]}
						</Heading5>

						<Heading6 className="text-text-muted font-medium">
							{statusDescriptions[status]}
						</Heading6>
					</div>
				</>
			</div>
		</div>
	)
}

export default TransactionStatusRow
