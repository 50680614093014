import bank_list from "../../../../lib/psp_bank_id.json"
import { findDisplayNameForBankInJson } from "../../../../lib/utils"
import { formatPriceAmount } from "../../../../utils/numbers"
import { CurrencyImage } from "../../../tables/transactions-table/columns/currencyImageCell"
import { TableMovement } from "../../../tables/transactions-table/data"
import { Heading2, Heading6 } from "../../../ui/texts/TextStyle"
import BodyBlock from "../../payment-modal/body/BodyBlock"
import BodyItem from "../../payment-modal/body/BodyItem"

const InboundTransactionModalBody = ({
	transaction,
}: {
	transaction: TableMovement
}) => {
	const bankOrPspId = transaction.psp_id || transaction.bank_id
	const displayName = bankOrPspId
		? findDisplayNameForBankInJson(bankOrPspId, bank_list)
		: undefined
	const comm_str =
		transaction.comission && transaction.comission.percent
			? `(${transaction.comission.percent.toFixed(2)}% + IVA)`
			: ""
	return (
		<div className="grid grid-cols-1 items-center">
			<BodyBlock title={"Resumen"}>
				<BodyItem
					name="Hora"
					value={transaction.creationDateTime.time}
					overflow={true}
				/>

				<BodyItem
					name="Fecha"
					value={transaction.creationDateTime.date}
					overflow={true}
				/>
				<BodyItem
					name="Monto recibido"
					value={`${transaction.amount} ${transaction.currency}`}
				/>
				{transaction.comission && transaction.comission.comission && (
					<BodyItem
						name={`Comisión ${comm_str}`}
						value={`-${formatPriceAmount(
							transaction.comission.comission.amount,
							undefined,
							transaction.comission.comission.currency
						)} ${transaction.comission.comission.currency}`}
					/>
				)}

				<div className="flex justify-between items-center py-betweenComponents">
					<Heading6 className="font-medium text-text-muted">
						Acreditado
					</Heading6>
					{transaction.credited && (
						<div className="flex-col  mb-2 whitespace-nowrap ">
							<div className="flex justify-end">
								<CurrencyImage currency={transaction.credited.currency} />

								<Heading2 className="ml-betweenTexts font-medium whitespace-nowrap">
									{formatPriceAmount(
										transaction.credited.amount,
										undefined,
										transaction.credited.currency
									)}
								</Heading2>

								<Heading2 className="ml-betweenTexts font-light">
									{transaction.credited.currency}
								</Heading2>
							</div>
							{/* {payment.creditedInRequestedCurrency && (
									<div className="flex justify-end">
										<Heading5 className="font-medium text-text-muted">
											{payment.creditedInRequestedCurrency.amount}{" "}
											{payment.creditedInRequestedCurrency.currency}
										</Heading5>
									</div>
								)} */}
						</div>
					)}
				</div>

				{transaction.store_name && (
					<BodyItem name="Tienda" value={transaction.store_name} />
				)}

				{transaction.orderId && (
					<BodyItem name="Nro de orden" value={transaction.orderId} />
				)}
			</BodyBlock>

			<BodyBlock title={"Destino"}>
				{transaction.alias && (
					<BodyItem name="Alias" value={transaction.alias} />
				)}

				<BodyItem
					name="CVU"
					value={transaction.address}
					copy={true}
					overflow={true}
				/>
			</BodyBlock>
			<BodyBlock title={"Datos del cliente"}>
				{transaction.sender_titular && (
					<BodyItem
						name="Nombre"
						value={transaction.sender_titular}
						copy={true}
					/>
				)}
				{displayName && <BodyItem name="Banco" value={displayName} />}

				{transaction.senderCuit && (
					<BodyItem name="CUIT" value={transaction.senderCuit} />
				)}

				<BodyItem
					name="CVU"
					value={transaction.sender}
					copy={true}
					overflow={true}
				/>
			</BodyBlock>
			{/* {Object.entries(transaction).map(([key, v]) => {
				return <BodyItem name={key} value={`${v}`} />
			})} */}
		</div>
	)
}
export default InboundTransactionModalBody
