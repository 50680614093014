import { Heading5, Heading6 } from "../../../ui/texts/TextStyle"
import CopyButton from "../../../ui/buttons/CopyButton"
import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { Button } from "../../../ui/button"
import { cn } from "../../../../lib/utils"

const BodyItem = ({
	name,
	value,
	copy = false,
	overflow = false,
	url,
	disabledButton,
}: {
	name: string
	value: ReactNode | string
	copy?: boolean
	overflow?: boolean
	url?: boolean
	disabledButton?: boolean
}) => {
	const handleClick = () => {
		if (typeof value === "string" && value.startsWith("http")) {
			console.log("Navigating to:", value) // Debugging line
			window.open(value, "_blank")
		}
	}

	return (
		<div className="grid grid-cols-2 w-full h-9 justify-between items-center">
			<Heading6 className="w-fit font-medium text-text-muted items-center">
				{name}
			</Heading6>
			<div className="w-full h-6 whitespace-nowrap line flex flex-row justify-end items-center">
				{url ? (
					<button
						className={`w-full text-h5 text-right font-medium overflow-ellipsis overflow-hidden 
						${url ? "text-blue-500 hover:underline cursor-pointer" : ""}
						${disabledButton ? "text-text-muted" : ""}`}
						onClick={handleClick}
					>
						{value}
					</button>
				) : (
					<div className={`w-full text-h5 text-right justify-end font-medium overflow-ellipsis overflow-hidden
						${disabledButton ? "text-text-muted" : ""}`}>
						{value}
					</div>
				)}

				{copy && typeof value === "string" && (
					<CopyButton
						size={20}
						buttonSize="icon"
						variant="ghost"
						textToCopy={value}
						className={cn("hover:bg-transparent cursor-pointer hover:text-secondary", disabledButton ? "text-text-muted" : "")}
						disabled={disabledButton}
					/>
				)}
			</div>
		</div>
	)
}

export default BodyItem
