import { formatEther, parseEther } from "ethers"
import { useEffect, useState } from "react"
import { Price } from "../../components/tables/transactions-table/columns"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { SavedAddress } from "../../components/withdraw/SelectAddressStep"
import WithdrawWizardBody from "../../components/withdraw/WithdrawWizardBody"
import { useAuth } from "../../context/AuthContext"
import {
	AnalyticsContextState,
	useAnalytics,
} from "../../lib/analytics/AnalyticsContext"
import {
	createUserAddress,
	getUserOutboundAddresses,
} from "../../services/addresses"
import { getUserCashoutCvus } from "../../services/cvu"
import { getPrices } from "../../services/prices"
import {
	createOutboundTransaction,
	createOutboundTransactionCvu,
	createOutboundTransactionPix,
	createTransactionQuote,
} from "../../services/transactions"
import { BalanceItem, groupBalancesByCurrency } from "../../utils/balances"
import { trimBn } from "../../utils/numbers"
import { WithdrawSteps, WithdrawWizardData } from "./withdraw-wizard-types"

const WithdrawWizard = () => {
	const [wizardData, setWizardData] = useState<WithdrawWizardData>({
		step: WithdrawSteps.SelectType,
		loading: false,
		data: {},
	})
	const [prices, setPrices] = useState<Price[]>()
	const [addresses, setAddresses] = useState<SavedAddress[] | undefined>()

	const { balances, user, fetchBalance } = useAuth()

	const groupedBalances = groupBalancesByCurrency(balances ?? [])

	const { trackEvent } = useAnalytics() as AnalyticsContextState

	useEffect(() => {
		if (
			!wizardData.data.currency ||
			wizardData.data.withdrawType === "transfer"
		)
			return
		const getPricesAsync = async () => {
			const prices = await getPrices({
				amount: 1,
				currency: wizardData.data.currency!,
			})
			setPrices(prices)
		}
		getPricesAsync()
	}, [wizardData.data.currency])

	useEffect(() => {
		if (!wizardData.data.withdrawType) return

		if (wizardData.data.withdrawType === "transfer") {
			return
		}

		const quoteDelay = setTimeout(async () => {
			if (
				wizardData.data.amount &&
				wizardData.data.amount.amount !== "" &&
				wizardData.data.amount.amount !== "0" &&
				Number(wizardData.data.amount.amount) > 0 &&
				wizardData.data.amount.currency !== ""
			) {
				try {
					setWizardData({
						...wizardData,
						loading: true,
					})
					// HAY que convertirlo
					const amount: string = calculateConversionPrice(
						{
							amount: wizardData.data.amount.amount,
							currency: wizardData.data.amount.currency,
						},
						wizardData.data.currency
					)

					const bal = balances?.find(
						(balance: BalanceItem) =>
							balance.currency === wizardData.data.currency &&
							balance.network === wizardData.data.network
					)
					const quoteRes = await createTransactionQuote(
						user.user_id,
						amount,
						wizardData.data.network!,
						wizardData.data.currency!,
						wizardData.data.address!,
						wizardData.data.amount.amount ===
							Number(trimBn(formatEther(bal!.amount)))
					)
					setWizardData({
						...wizardData,
						loading: false,
						data: {
							...wizardData.data,
							quote: quoteRes,
						},
					})
				} catch (err) {
					console.error(err)
					setWizardData({
						...wizardData,
						loading: false,
						errorMessage: "Error al calcular costo de red",
					})
				}
			}
		}, 1000)

		return () => clearTimeout(quoteDelay)
	}, [
		wizardData.data.amount?.amount,
		wizardData.data.amount?.currency,
		balances,
		user.user_id,
	])

	useEffect(() => {
		if (!wizardData.data.currency || !wizardData.data.network) return

		loadAddresses()
	}, [wizardData.data.currency, wizardData.data.network])

	useEffect(() => {
		if (wizardData.data.withdrawType === "transfer") {
			setWizardData({
				...wizardData,
				data: {
					...wizardData.data,
					network: "POLLUX",
					currency: "ARS",
				},
			})
		}
		if (wizardData.data.withdrawType === "pix") {
			setWizardData({
				...wizardData,
				data: {
					...wizardData.data,
					network: "POLYGON",
					currency: "USDT",
				},
			})
		}

		if (wizardData.data.currency && wizardData.data.network) {
			loadAddresses()
		}
	}, [wizardData.data.withdrawType])

	const loadAddresses = async () => {
		var addRes
		if (wizardData.data.withdrawType === "transfer") {
			addRes = await getUserCashoutCvus({
				user_id: user.user_id,
			})
		} else {
			addRes = (await getUserOutboundAddresses({
				user_id: user.user_id,
				network: wizardData.data.network!,
				currency: wizardData.data.currency!,
			})) as SavedAddress[]
		}

		setAddresses(addRes ?? [])
	}

	function calculateConversionPrice(
		originalPriceNum: { amount: string | number; currency: string },
		destinationCurrency: any
	): string {
		if (!prices || !originalPriceNum || !destinationCurrency) {
			throw new Error("Missing data to calculate conversion price")
		}
		const originalPrice = {
			amount: originalPriceNum.amount.toString(),
			currency: originalPriceNum.currency,
		}

		if (originalPrice.currency === destinationCurrency) {
			return parseEther(originalPrice.amount).toString()
		}
		// Get original price currency conversion rate
		const conversionRate = prices.find(
			(elem) => elem.currency === originalPrice.currency
		)?.amount
		// Regla de 3 simple
		// 1 /(orignalPriceConversionRateAmount * originalPrice.amount
		const amountInDestinationCurrency =
			(parseEther("1") * parseEther(originalPrice.amount)) /
			BigInt(conversionRate as string)

		return amountInDestinationCurrency.toString()
	}

	const handleCreateCvuTx = async () => {
		try {
			setWizardData({
				...wizardData,
				step: WithdrawSteps.Loading,
			})
			var amount = wizardData.data.amount!.amount!
			//If number toFixed
			if (typeof amount === "number") {
				amount = amount.toFixed(2)
			}

			const tx = await createOutboundTransactionCvu({
				user_id: user.user_id,
				pollux_account_id: user.pollux_account_id,
				amount: `-${amount}`,
				address: wizardData.data.address!,
			})
			if (!tx || !tx.transaction_id) {
				throw new Error("Error al crear transacción")
			}
			setWizardData({
				...wizardData,
				step: WithdrawSteps.Success,
				data: {
					...wizardData.data,
					transaction_id: tx.transaction_id,
				},
			})
			fetchBalance()
		} catch {
			setWizardData({
				...wizardData,
				step: WithdrawSteps.EnterAmount,
				errorMessage: "Error al crear transacción",
			})
		}
	}

	const handleCreatePixTx = async () => {
		try {
			setWizardData({
				...wizardData,
				step: WithdrawSteps.Loading,
			})
			const tx = await createOutboundTransactionPix(
				user.user_id,
				wizardData.data.quote.id
			)
			if (!tx || !tx.transaction_id) {
				throw new Error("Error al crear transacción")
			}
			setWizardData({
				...wizardData,
				step: WithdrawSteps.Success,
				data: {
					...wizardData.data,
					transaction_id: tx.transaction_id,
				},
			})
			fetchBalance()
		} catch {
			setWizardData({
				...wizardData,
				step: WithdrawSteps.EnterAmount,
				errorMessage: "Error al crear transacción",
			})
		}
	}

	const handleCreateCryptoTx = async () => {
		try {
			setWizardData({
				...wizardData,
				step: WithdrawSteps.Loading,
			})
			const tx = await createOutboundTransaction(
				user.user_id,
				wizardData.data.quote.id
			)
			const bal = balances?.find(
				(balance: BalanceItem) =>
					balance.currency === wizardData.data.currency &&
					balance.network === wizardData.data.network
			)
			const proportionalAmount =
				(Number(wizardData.data?.amount?.amount) /
					Number(formatEther(bal!.amount))) *
				100

			trackEvent("withdraw", {
				event_type: "withdraw",
				currency: wizardData.data.currency,
				network: wizardData.data.network,
				user_id: user.user_id,
				withdrawal_at: tx.creation_timestamp,
				proportional_amount: proportionalAmount,
			})
			setWizardData({
				...wizardData,
				step: WithdrawSteps.Success,
				data: {
					...wizardData.data,
					transaction_id: tx.transaction_id,
				},
			})
			fetchBalance()
		} catch {
			setWizardData({
				...wizardData,
				step: WithdrawSteps.EnterAmount,
				errorMessage: "Error al crear transacción",
			})
		}
	}

	const handleCreateTx = async () => {
		if (wizardData.data.withdrawType === "crypto") {
			await handleCreateCryptoTx()
		} else if (wizardData.data.withdrawType === "pix") {
			await handleCreateCryptoTx()
		} else {
			await handleCreateCvuTx()
		}

		fetchBalance()
	}

	const handleNewAddress = async ({
		alias,
		address,
	}: {
		alias: string
		address: string
	}) => {
		await createUserAddress({
			user_id: user.user_id,
			network: wizardData.data.network!,
			currency: wizardData.data.currency!,
			address,
			alias,
		})
		await loadAddresses()
	}
	return (
		<CenteredBody bottom>
			<WithdrawWizardBody
				addresses={addresses}
				handleNewAddress={handleNewAddress}
				wizardData={wizardData}
				balances={balances}
				onDataChanged={setWizardData}
				//TODO: sacar networkBalances
				networkBalances={
					balances?.filter(
						(balance: BalanceItem) =>
							balance.currency === wizardData.data.currency &&
							balance.network === wizardData.data.network
					) ?? []
				}
				prices={prices}
				handleConfirmTx={handleCreateTx}
			/>
		</CenteredBody>
	)
}

export default WithdrawWizard
