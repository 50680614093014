import { AlertCircle, CircleAlert, Copy, Pencil } from "lucide-react"

import Col from "../../ui/layouts/column"
import { Heading6 } from "../../ui/texts/TextStyle"
import Row from "../../ui/layouts/row"
import { ReactNode } from "react"

const PaymentQuoteCard = ({
	headline,
	size,
	status,
	updateHandler,
	copyHandler,
	item,
	warning,
	children,
}: {
	children?: ReactNode | ReactNode[]
	warning?: string
	headline: string
	size: "sm" | "lg"
	status: "enabled" | "disabled"
	updateHandler?: () => void
	copyHandler?: () => void
	item?:
		| {
				logo?: string
				title: string
		  }
		| undefined
}) => {
	const baseStyle =
		"flex flex-col group/slide flex-start justify-between p-sm rounded-sm"
	const sizeStyle = size === "lg" ? "h-52" : "h-28" // Ejemplo, deberás añadir las clases correctas en tailwind.config.js
	const statusStyle = status === "disabled" ? "opacity-40" : "opacity-100"
	const editable = Boolean(updateHandler || copyHandler)

	return (
		<div
			className={`relative inline-flex w-full bg-background-subtle ${baseStyle} ${sizeStyle} ${statusStyle} ${
				warning ? "outline outline-border-warning-muted outline-1" : ""
			}`}
			onClick={editable ? updateHandler || copyHandler : undefined}
		>
			{warning && (
				<span className="absolute flex size-3 -top-[4px] -right-[4px]">
					<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-border-warning-muted opacity-75"></span>
					<span className="relative inline-flex rounded-full h-3 w-3 bg-border-warning-muted"></span>
				</span>
			)}

			{/* Header + Icons */}
			<div className="flex w-full justify-between">
				<div className="text-h6 group-hover/slide:translate-x-2 transition duration-200">
					{headline}
				</div>
				<div className="flex space-x-2">
					{updateHandler && (
						<Pencil
							className="size-5 cursor-pointer"
							onClick={(e) => {
								e.stopPropagation()
								updateHandler()
							}}
						/>
					)}
					{copyHandler && (
						<Copy
							className="size-5 cursor-pointer"
							onClick={(e) => {
								e.stopPropagation()
								copyHandler()
							}}
						/>
					)}
				</div>
			</div>

			{/* Body */}
			<Col className="gap-betweenComponents">
				{item && (
					<div className="flex items-center space-x-2 text-h5 font-semibold group-hover/slide:translate-x-2 transition duration-500">
						{item.logo && (
							<div className="size-md grid  justify-center [&>*]:rounded-full items-center">
								<img src={item.logo} alt="" className="h-6 w-6" />
							</div>
						)}
						<span className="truncate">{item.title}</span>
					</div>
				)}
				{children}
				{warning !== "none" && warning && (
					<div className="flex items-start space-x-2 text-h5 font-semibold transition duration-500">
						<CircleAlert className="size-7 mr-2 text-text-warning-strong group-hover/slide:text-text-warning" />
						<Heading6 className="text-text-warning-strong group-hover/slide:text-text-warning">
							{warning}
						</Heading6>
					</div>
				)}
			</Col>
		</div>
	)
}

export default PaymentQuoteCard
