import { Price } from "@crypto-payment-gateway/models"
import { Info } from "lucide-react"
import { useState } from "react"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { Heading5 } from "../ui/texts/TextStyle"
import PaymentDescriptionModal from "./PaymentDescriptionModal"

const PaymentDescription = (params: {
	time: Date
	email: string
	price: Price
	avatarUrl?: string
	motive?: string
	language?: "spanish" | "portuguese"
}) => {
	const [isModalOpen, setModalOpen] = useState<boolean>(false)

	return (
		<CenteredBody>
			<div className="flex flex-col gap-betweenTexts">
				<div className="flex items-center">
					<Heading5 className="font-medium text-text-muted">
						{params.language === "portuguese"
							? "Total da compra"
							: "Total de compra"}
					</Heading5>
					<PaymentDescriptionModal
						onClose={() => setModalOpen(false)}
						isModalOpen={isModalOpen}
						time={params.time}
						email={params.email}
						avatarUrl={params.avatarUrl}
						motive={params.motive}
					/>

					<Info
						onClick={() => {
							setModalOpen(true)
						}}
						className="size-icon mx-1 text-text-interactive cursor-pointer"
					/>
				</div>
				<div className="flex flex-row gap-betweenTexts items-center">
					<object
						data={`/currencies/${params.price.currency.toLowerCase()}-icon.svg`}
						className="md:size-md size-sm"
					/>

					<div
						className="text-h1 md:text-d2 font-semibold md:font-semibold"
						data-test="payment-link-checkout-amount"
					>
						{params.price.amount.toLocaleString()}
					</div>
					<div className="text-h1 md:text-d2 md:font-extralight font-extralight">
						{params.price.currency}
					</div>
				</div>
			</div>
		</CenteredBody>
	)
}

export default PaymentDescription
