import { useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useGlobalStore } from "../../hooks/useGlobalStore"
import {
	AnalyticsContextState,
	useAnalytics,
} from "../../lib/analytics/AnalyticsContext"
import { getPathForUser } from "../../lib/roles"
import { updateToken } from "../../services/interceptors"
import {
	AuthProvider,
	checkEmailExists,
	createUser,
	deleteFirebaseUser,
	verifyTeam,
} from "../../services/users"
import { Heading6 } from "../ui/texts/TextStyle"
import SignupConfirmPasswordStep from "./SignupConfirmPasswordStep"
import SignupEmailStep from "./SignupEmailStep"
import FormFooter from "./SignupFormFooter"
import SingupPasswordStep from "./SignupPasswordStep"

export enum SignupSteps {
	Email,
	Password,
	ConfirmPassword,
}

export const SignupBox = () => {
	const navigate = useNavigate()
	const { state }: any = useLocation()
	const { signup, getErrorMessage, user } = useAuth()
	const { trackEvent } = useAnalytics() as AnalyticsContextState
	let [searchParams] = useSearchParams()
	const [step, setStep] = useState(SignupSteps.Email)

	const userId = searchParams.get("userId")
	const verificationCode = searchParams.get("verificationCode")
	const teamId = searchParams.get("teamId")
	const email = searchParams.get("email")

	const isTeam = userId && verificationCode && teamId && email
	const [isLoading, setIsLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState<string>()
	const [data, setData] = useState({
		email: isTeam ? email : (state && state.email) || "",
		password: (state && state.password) || "",
		passwordConfirm: (state && state.passwordConfirm) || "",
		passwordRequirements: {
			length: (state && state.password && state.password.length >= 8) || false,
			number: (state && state.password && state.password.match(/\d/)) || false,
			special:
				(state &&
					state.password &&
					state.password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/)) ||
				false,
		},
	})
	const { globalStore, setGlobalStore } = useGlobalStore()
	const handleNavigation = (user_claims: any) => {
		if (globalStore.deepLink) {
			const { deepLink, ...rest } = globalStore
			setGlobalStore(rest)
			navigate(deepLink)
		} else {
			const userPath = getPathForUser(user_claims)
			if (userPath) {
				navigate(userPath)
			} else {
				window.location.href = process.env.REACT_APP_NEXT_LANDING_URL!
			}
		}
	}
	const validData = () => {
		switch (step) {
			case SignupSteps.Email:
				return data.email.length > 0
			case SignupSteps.Password:
				return data.password.length > 0
			case SignupSteps.ConfirmPassword:
				return data.passwordConfirm.length > 0
		}
	}

	const handleSignup = async () => {
		try {
			const response = await signup(data.email.trim(), data.password)
			const idToken = await response.user.getIdToken()
			updateToken(`FB-${idToken}`)
			var taloRes
			if (isTeam) {
				taloRes = await verifyTeam(
					userId,
					teamId,
					response.user.uid,
					verificationCode
				)
			} else {
				taloRes = await createUser({
					firebase_uid: response.user.uid,
					provider: AuthProvider.Mail,
					avatar_url: response.user.photoUrl ?? undefined,
					email: response.user.email,
					display_name: response.user.displayName ?? undefined,
					email_verified: response.user.emailVerified ?? false,
					deep_link: globalStore.deepLink,
				})
			}
			if (taloRes.status < 300 && taloRes.status >= 200) {
				const newIdToken = await response.user.getIdToken(true)
				updateToken(`FB-${newIdToken}`)
				handleNavigation(taloRes.data.data)
				trackEvent("signup", {
					event_type: "signup",
					sign_up_method: taloRes.data.data.provider,
					user_id: taloRes.data.data.user_id,
					team_id: taloRes.data.data.team_id,
					user_role: taloRes.data.data.roles[0],
					signup_at: taloRes.data.data.creation_timestamp,
				})
			}
		} catch (error: any) {
			setErrorMessage(getErrorMessage(error.code))
			await deleteFirebaseUser()
		}
	}

	let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}")

	const handleSubmit = async () => {
		setErrorMessage(undefined)
		setIsLoading(true)
		switch (step) {
			case SignupSteps.Email:
				const email = data.email.trim()

				if (!regex.test(email)) {
					setErrorMessage(getErrorMessage("auth/invalid-email"))
					break
				} else {
					const emailExistsRes = await checkEmailExists(data.email)
					const emailExists = emailExistsRes.data.data.emailExists
					if (emailExists) {
						setErrorMessage(getErrorMessage("auth/email-already-in-use"))
						break
					} else {
						setStep(SignupSteps.Password)
					}
				}
				break
			case SignupSteps.Password:
				if (
					data.passwordRequirements.length &&
					data.passwordRequirements.number &&
					data.passwordRequirements.special
				) {
					setStep(SignupSteps.ConfirmPassword)
				} else {
					setErrorMessage(getErrorMessage("auth/weak-password"))
				}
				break
			case SignupSteps.ConfirmPassword:
				if (data.password === data.passwordConfirm) {
					await handleSignup()
				} else {
					setErrorMessage(getErrorMessage("auth/passwords-dont-match"))
				}
				break
		}
		setIsLoading(false)
	}

	const handleGoBack = () => {
		console.log("SE LLAMA GO BACK")
		switch (step) {
			case SignupSteps.Password:
				setStep(SignupSteps.Email)
				break
			case SignupSteps.ConfirmPassword:
				setStep(SignupSteps.Password)
				break
		}
	}

	const handlePasswordChange = (password: string) => {
		setData({
			...data,
			password: password,
			passwordRequirements: {
				length: password.length >= 8,
				number: password.match(/\d/),
				special: password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/),
			},
		})
	}

	const handlePasswordConfirmChange = (password: string) => {
		setData({
			...data,
			passwordConfirm: password,
		})
	}

	const handleEmailChange = (email: string) => {
		setData({ ...data, email })
	}

	const wizardSteps = {
		[SignupSteps.Email]: (
			<SignupEmailStep
				onEmailChange={handleEmailChange}
				handleError={(err: string) => setErrorMessage(err)}
				loading={isLoading}
				allowGoogleSignin={!isTeam}
				email={data.email}
				disabled={isTeam ? true : false}
			/>
		),
		[SignupSteps.Password]: (
			<SingupPasswordStep
				onPasswordChange={handlePasswordChange}
				passwordRequirements={data.passwordRequirements}
				password={data.password}
			/>
		),
		[SignupSteps.ConfirmPassword]: (
			<SignupConfirmPasswordStep
				onPasswordChange={handlePasswordConfirmChange}
				password={data.passwordConfirm}
			/>
		),
	}
	console.log(step)
	return (
		<form
			className="grow"
			onSubmit={(e) => {
				e.preventDefault()
				handleSubmit()
			}}
		>
			<div className=" grid gap-2">
				{wizardSteps[step]}
				<div className="h-sm">
					{errorMessage && (
						<Heading6 className="text-text-error">{errorMessage}</Heading6>
					)}
				</div>
				<FormFooter
					handleGoBack={handleGoBack}
					isLoading={isLoading}
					handleSubmit={validData() ? handleSubmit : undefined}
					step={step}
				/>
			</div>
		</form>
	)
}
