import { UpdateUser } from "@crypto-payment-gateway/models"
import { deleteUser, getAuth, sendEmailVerification } from "firebase/auth"
import logger from "../utils/logger"
import { apiPrivate, apiPublic } from "./interceptors"

export const enum AuthProvider {
	Google = "google",
	Mail = "email",
}

export const enum StoreType {
	TIENDANUBE = "tiendanube",
	WOOCOMMERCE = "woocommerce",
	SHOPIFY = "shopify",
}

interface CreateUserStore {
	user_id: string
	store_type: StoreType
}

export interface CreateTiendanubeStore extends CreateUserStore {
	authorization_code: string

	created_by?: string
	app_id: string
}

export interface CreateShopifyStore extends CreateUserStore {
	myshopify_url: string
	authorization_code?: string
	app_id?: string
}

export interface User {
	id?: string
	user_id?: string
	firebase_uid: string
	email: string
	provider: AuthProvider
	email_verified: boolean
	avatar_url: string
	display_name: string
	credentials?: string[]
}

export async function createUser(user: User & { deep_link?: string }) {
	return await apiPrivate.post(
		process.env.REACT_APP_USERS_API_PATH ?? "/",
		user
	)
}
export async function updateUser(
	user_id: string,
	update_user_info: UpdateUser
) {
	return await apiPrivate.patch(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${user_id}`,
		update_user_info
	)
}

export async function createUserStore({
	user_id,
	authorization_code,
	store_type,
	created_by,
	app_id,
}: CreateTiendanubeStore) {
	return await apiPrivate.post(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${user_id}/stores`,
		{
			authorization_code,
			store_type,
			created_by,
			app_id,
		}
	)
}

export async function createShopifyStore({
	user_id,
	myshopify_url,
	authorization_code,
	app_id,
}: CreateShopifyStore) {
	return await apiPrivate.post(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${user_id}/stores`,
		{
			store_type: StoreType.SHOPIFY,
			myshopify_url: myshopify_url,
			authorization_code,
			app_id,
		}
	)
}

export async function verifyTeam(
	userId: string,
	teamId: string,
	firebase_uid: string,
	verificationCode: string
) {
	return await apiPrivate.post(
		`${
			process.env.REACT_APP_USERS_API_PATH ?? "/"
		}${userId}/teams/${teamId}/verifications`,
		{
			firebase_uid,
			verification_code: verificationCode,
		}
	)
}

export async function getTeam(userId: string, teamId: string) {
	return await apiPrivate.get(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${userId}/teams/${teamId}`
	)
}

export async function checkEmailExists(email: string) {
	return await apiPublic.get(
		`${
			process.env.REACT_APP_USERS_API_PATH ?? "/"
		}check-email?email=${encodeURIComponent(email)}`
	)
}

export async function createUserCredentials(
	userId: string,
	alias: string,
	created_by?: string
) {
	return await apiPrivate.post(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${userId}/credentials`,
		{
			alias,
			created_by,
		}
	)
}

export async function createVerificationCode(id: string) {
	return await apiPrivate.post(`/users/${id}/verification-codes`)
}

export async function getUserByid(id: string) {
	return await apiPrivate.get(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${id}`
	)
}

export async function getUserByEmail(email: string) {
	const res = await apiPrivate.get(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}?email=${email}`
	)

	return res.data.data.users as any[]
}

export async function createUserClaims(userId: string) {
	return await apiPrivate.post(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${userId}/claims`
	)
}
export async function getAdminUsers() {
	return await apiPrivate.get(process.env.REACT_APP_USERS_API_PATH ?? "/", {
		params: {
			role: "yoda",
		},
	})
}

export async function getUsers({
	limit,
	cursor,
}: {
	limit?: number
	cursor?: {
		pk: string
		sk: string
	}
}) {
	const cursorEnc = cursor
		? encodeURIComponent(JSON.stringify(cursor))
		: undefined
	logger.info(`getUsers ${limit} ${cursor}`)
	const res = await apiPrivate.get(
		`/users/${limit ? `?limit=${limit}` : ""}${
			cursor ? (limit ? `&cursor=${cursorEnc}` : `?cursor=${cursorEnc}`) : ""
		}`
	)
	return res.data.data
}

export async function getUserCredentials(userId: string) {
	return await apiPrivate.get(
		`${process.env.REACT_APP_USERS_API_PATH ?? "/"}${userId}/credentials `
	)
}

export async function deleteFirebaseUser() {
	const auth = getAuth()
	if (auth.currentUser) {
		return await deleteUser(auth.currentUser)
	} else {
		throw Error("no current user in auth")
	}
}

export async function verifyUserEmail(
	userId: string,
	verificationCode: string
) {
	return await apiPublic.post(`/users/${userId}/verifications`, {
		verificationCode,
	})
}

export async function sendFirebaseEmailVerification() {
	const auth = getAuth()
	if (auth.currentUser) {
		if (!auth.currentUser.emailVerified) {
			logger.info("send email verification ", auth.currentUser)
			return await sendEmailVerification(auth.currentUser)
		}
	} else {
		throw Error("no current user in auth")
	}
}

export async function createTeam(
	user_id: string,

	team: { email: string; alias: string; role: string },
	created_by?: string
) {
	return await apiPrivate.post(`/users/${user_id}/teams`, {
		team: team,
		created_by: created_by,
	})
}

export async function getTeams(user_id: string) {
	return await apiPrivate.get(`/users/${user_id}/teams`)
}

export async function deleteTeam(user_id: string, team_id: string) {
	return await apiPrivate.delete(`/users/${user_id}/teams/${team_id}`)
}

export async function getUserStores(user_id: string) {
	return await apiPrivate.get(`/users/${user_id}/stores`)
}

export async function getPublicUser(user_id: string) {
	return await apiPublic.get(`/users/${user_id}/public`)
}
