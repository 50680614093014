import { useEffect, useState } from "react"
import { Loader, ScanFace } from "lucide-react"
import { Option } from "../../../components/ui/options/option"
import { useAuth } from "../../../context/AuthContext"
import { useMetamapScript } from "../../../hooks/useMetamapScript"
import { useMutation } from "@tanstack/react-query"
import { apiPrivate } from "../../../services/interceptors"
import { KycStatus } from "../personal/onboard-personal-wizard-types.ts"
import {
	AnalyticsContextState,
	useAnalytics,
} from "../../../lib/analytics/AnalyticsContext.ts"

declare const MetamapVerification: any

export interface MetamapButtonProps {
	clientId: string
	flowId: string
	metadata: any
}

export default function MetamapButton({
	clientId,
	flowId,
	metadata,
}: MetamapButtonProps) {
	const [verification, setVerification] = useState<any>()
	const { kyc, user } = useAuth()
	const { isSuccess: scriptLoaded, isLoading, isError } = useMetamapScript()

	const mutation = useMutation({
		mutationFn: ({ userId }: { userId: string }) => {
			return apiPrivate.patch(`/users/${userId}/kyc`, {
				kyc_status: "metamap" as KycStatus,
			})
		},
		retry: 1,
	})

	useEffect(() => {
		if (scriptLoaded) {
			try {
				const verificationInstance = new MetamapVerification({
					clientId: clientId,
					flowId: flowId,
					metadata: metadata,
				})
				setVerification(verificationInstance)
			} catch (e) {
				console.error("Error initializing MetamapVerification:", e)
			}
		}
	}, [clientId, flowId, metadata, scriptLoaded])

	function handleStartVerification() {
		if (verification) {
			verification.start()
			trackEvent(`kyc_metamap_verification_started`, {
				event_type: `Clicked Metamap Verification`,
				event_status: "active",
				origin: "onboarding",
			})
		} else {
			console.error("Verification not initialized")
			trackEvent(`kyc_metamap_verification_starting_error`, {
				event_type: `Metamap Button Error`,
				event_status: "inactive",
				origin: "onboarding",
			})
		}
	}
	const { trackEvent } = useAnalytics() as AnalyticsContextState

	const handleUserStartedSdk = (data: any) => {}

	const handleLoaded = (data: any) => {}

	const handleUserFinishedSdk = async (data: any) => {
		await mutation
			.mutateAsync({ userId: user.user_id })
			.then(() => {
				kyc.refetch()
			})
			.catch((e) => {
				console.error("Error updating user kyc status:", e)
			})
	}

	const handleExitedSdk = (data: any) => {}

	useEffect(() => {
		if (!verification) return
		verification.on("metamap:userStartedSdk", handleUserStartedSdk)
		verification.on("metamap:loaded", handleLoaded)
		verification.on("metamap:userFinishedSdk", handleUserFinishedSdk)
		verification.on("metamap:exitedSdk", handleExitedSdk)

		return () => {
			verification.off("metamap:userStartedSdk", handleUserStartedSdk)
			verification.off("metamap:loaded", handleLoaded)
			verification.off("metamap:userFinishedSdk", handleUserFinishedSdk)
			verification.off("metamap:exitedSdk", handleExitedSdk)
		}
	}, [verification])

	return (
		<>
			{verification && (
				<Option
					title="Realizar reconocimiento facial"
					description="Ten tu documento a mano"
					icon={<ScanFace />}
					iconInside={false}
					leading="arrow"
					onClick={handleStartVerification}
				/>
			)}
			{isLoading && (
				<Option
					title="Realizar reconocimiento facial"
					description="Ten tu documento a mano"
					icon={<ScanFace />}
					iconInside={false}
					leading="div"
					disabled={true}
					div={<Loader className="animate-spin" />}
				/>
			)}
		</>
	)
}
