import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../lib/utils"

const chipVariants = cva(
	"border flex flex-row gap-1 w-fit h-fit text-h6 font-normal items-center justify-center cursor-pointer",
	{
		variants: {
			variant: {
				default:
					"border-border bg-background text-text-muted hover:bg-background-elevated",
				secondary:
					"border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
				destructive:
					"border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
				outline: "text-foreground",
			},

			size: {
				xs: "h-sm rounded-full py-3 px-2 text-h6 text-text-muted",
				sm: "px-3 py-1 rounded-full text-h6",
			},
		},

		defaultVariants: {
			variant: "default",
			size: "sm",
		},
	}
)

export interface ChipProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof chipVariants> {}

function Chip({ className, size, variant, ...props }: ChipProps) {
	return (
		<div
			className={cn(chipVariants({ variant, size }), className)}
			{...props}
		/>
	)
}

export { Chip, chipVariants }
