import { ColumnDef, Row } from "@tanstack/react-table"
import { Store } from "../../../../hooks/useFetchStores"
import { TableMovement } from "../data"
import { CurrencyImageCell } from "./currencyImageCell"
import { DateCell } from "./DateCell"
import { MoneyCell } from "./MoneyCell"
import { TypeColumn } from "./movementsTypeCell"
import { OrderCell } from "./OrderCell"
import { SenderOrRecieverCell } from "./SenderNameCell"
import { StoreCell } from "./StoreCell"

// Update the date filter function for movements
const dateRangeFilter = (
	row: Row<TableMovement>,
	id: string,
	value: { from: Date; to: Date }
) => {
	const dateTimeObj = row.getValue<{ date: string; time: string }>(id)
	if (!dateTimeObj) return false

	// Parse DD/MM/YY date format
	const [day, month, year] = dateTimeObj.date.split("/")
	const [time, period] = dateTimeObj.time.split(" ") // e.g., "08:22 PM"
	const [hours, minutes] = time.split(":")

	// Convert hours to 24-hour format if PM
	let hour = parseInt(hours)
	if (period === "PM" && hour !== 12) {
		hour += 12
	} else if (period === "AM" && hour === 12) {
		hour = 0
	}

	// Create date object with full year (assuming 20xx for YY format)
	const fullYear = 2000 + parseInt(year)
	const rowDate = new Date(
		fullYear,
		parseInt(month) - 1,
		parseInt(day),
		hour,
		parseInt(minutes)
	)

	return rowDate >= value.from && rowDate <= value.to
}

export const getMovementsColumns = (
	stores: Store[] | undefined
): { [key: string]: ColumnDef<TableMovement>[] } => ({
	xl: [
		DateCell({
			accessorKey: "creationDateTime",
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
		TypeColumn(),
		SenderOrRecieverCell<TableMovement>({
			header: "Titular",
		}),
		...(stores && stores.length > 1
			? [
					StoreCell<TableMovement>({
						accessorKey: "store_name",
						header: "Tienda",
						stores: stores,
					}),
			  ]
			: []),
		OrderCell<TableMovement>({
			accessorKey: "orderId",
			header: "Orden",
		}),

		MoneyCell<TableMovement>({
			accessorKey: "amount",
			header: "Monto",
		}),
		CurrencyImageCell<TableMovement>(),
	],
	lg: [
		DateCell({
			accessorKey: "creationDateTime",
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
		TypeColumn(),
		SenderOrRecieverCell<TableMovement>({
			header: "Titular",
		}),
		...(stores && stores.length > 1
			? [
					StoreCell<TableMovement>({
						accessorKey: "store_name",
						header: "Tienda",
						stores: stores,
					}),
			  ]
			: []),
		OrderCell<TableMovement>({
			accessorKey: "orderId",
			header: "Orden",
		}),
		MoneyCell<TableMovement>({
			accessorKey: "amount",
			header: "Monto",
		}),
		CurrencyImageCell<TableMovement>(),
	],
	md: [
		DateCell({
			accessorKey: "creationDateTime",
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
		TypeColumn(),
		OrderCell<TableMovement>({
			accessorKey: "orderId",
			header: "Orden",
		}),
		MoneyCell<TableMovement>({
			accessorKey: "amount",
			header: "Monto",
		}),
	],
	sm: [
		DateCell({
			accessorKey: "creationDateTime",
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
		MoneyCell<TableMovement>({
			accessorKey: "amount",
			header: "Monto",
		}),
	],
})
