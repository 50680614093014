import { Link } from "react-router-dom"
import TxTable from "../../tables/transactions-table/table"
import { Button } from "../../ui/button"
import Col from "../../ui/layouts/column"
import Row from "../../ui/layouts/row"
import SectionTitle from "../../ui/titles/SectionTitle"

interface PaymentsSectionProps {
	bordered?: boolean
}

const PaymentsSection: React.FC<PaymentsSectionProps> = ({
	bordered = true,
}) => {
	return (
		<Col className="h-full">
			<Row className="items-center pb-textToComponent justify-between">
				<SectionTitle
					title="Historial de transacciones"
					subtitle="Las transacciones de tu empresa"
				/>
				<Button variant="outline" size="sm" asChild>
					<Link to="/payments">Ver más</Link>
				</Button>
			</Row>
			<div className="h-full">
				<TxTable defaultView="movements" bordered={bordered} />
			</div>
		</Col>
	)
}

export default PaymentsSection
