import { useLocation } from "react-router-dom"
import { SettingsItemRow } from "../../../components/app/settings/SettingsItem"
import { Section } from "../../../components/ui/section"
import { useAuth } from "../../../context/AuthContext"
import { useFlags } from "../../../context/FlagsContext"
import {
	AnalyticsContextState,
	useAnalytics,
} from "../../../lib/analytics/AnalyticsContext"
import { PrivateRole, PublicRole } from "../../../lib/roles"
import { SettingsSectionItem } from "../../settings/items"

const PluginsSection = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const { hasPrivilege } = useAuth()
	const { getFlag } = useFlags()
	const { trackEvent } = useAnalytics() as AnalyticsContextState

	return subSections
		.filter(
			(subs) =>
				hasPrivilege(
					subs.minimumPrivateRole ?? PrivateRole.CLONE,
					subs.minimumPublicRole ?? PublicRole.POS
				) &&
				subs.subItems.some((subItem) =>
					hasPrivilege(
						subItem.minimumPrivateRole ?? PrivateRole.CLONE,
						subItem.minimumPublicRole ?? PublicRole.POS
					)
				)
		)
		.map((section) => (
			<Section
				title={section.title}
				footer={section.description}
				key={section.title}
			>
				{section.subItems
					.filter(
						(subItem) =>
							hasPrivilege(
								subItem.minimumPrivateRole ?? PrivateRole.CLONE,
								subItem.minimumPublicRole ?? PublicRole.POS
							) &&
							(!subItem.featureFlag ||
								(getFlag(subItem.featureFlag)
									? getFlag(subItem.featureFlag)._value == "true"
									: true))
					)

					.map((item) => (
						<SettingsItemRow
							link={item.link}
							key={item.title}
							title={item.title}
							icon={item.icon}
							iconInside={item.iconInside}
							analytics={() =>
								trackEvent(
									`payment_method_${item.title.toLowerCase()}_started`,
									{
										event_type: `Start ${item.title.toLowerCase()} integration `,
										event_status: "active",
										method: `${item.paymentMethod?.toLowerCase()}`,
										origin: "onboarding",
									}
								)
							}
						/>
					))}
			</Section>
		))
}

export default PluginsSection
