import { AmplitudeAnalyticsProvider } from "../lib/analytics/AmplitudeAnalyticsProvider"
import { LocalAnalyticsProvider } from "../lib/analytics/LocalAnalyticsProvider"
import { shouldBeDefined } from "../lib/utils"

export const NewAnalyticsProvider = ({ children }: any) => {
	if (process.env.NODE_ENV === "production") {
		return (
			<AmplitudeAnalyticsProvider
				apiKey={shouldBeDefined(process.env.REACT_APP_AMPLITUDE_API_KEY)}
			>
				{children}
			</AmplitudeAnalyticsProvider>
		)
	}
	return <LocalAnalyticsProvider>{children}</LocalAnalyticsProvider>
}
