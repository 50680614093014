import { formatPriceAmount } from "../../../../../utils/numbers"
import { TableTransaction } from "../../../../tables/transactions-table/columns"
import { CurrencyImage } from "../../../../tables/transactions-table/columns/currencyImageCell"
import { Heading2, Heading5, Heading6 } from "../../../../ui/texts/TextStyle"
import BodyBlock from "../BodyBlock"
import BodyItem from "../BodyItem"

const SuccessPaymentSummary = ({
	payment,
	variant,
}: {
	payment: TableTransaction
	variant: "transfer" | "crypto" | undefined
}) => {
	const comm_str =
		payment.comission && payment.comission.percent
			? `(${payment.comission.percent.toFixed(2)}% + IVA)`
			: ""
	switch (variant) {
		case "transfer":
			console.log("Payment INSIDE PAYMENT MODAL SUMMARY:", payment)
			return (
				<BodyBlock title="Resumen">
					<BodyItem
						name="Solicitado"
						value={`${formatPriceAmount(payment.price!.amount)} ${
							payment.price!.currency
						}`}
					/>
					{payment.receivedInRequestedCurrency && (
						<BodyItem
							name="Recibido"
							value={`${formatPriceAmount(payment.receivedInRequestedCurrency.amount)} ${
								payment.receivedInRequestedCurrency.currency
							}`}
						/>
					)}
					{payment.comission &&
						payment.comission.comissionInRequestedCurrency && (
							<BodyItem
								name={`Comisión ${comm_str}`}
								value={`-${formatPriceAmount(
									payment.comission.comissionInRequestedCurrency.amount,
									undefined,
									payment.comission.comissionInRequestedCurrency.currency
								)} ${payment.comission.comissionInRequestedCurrency.currency}`}
							/>
						)}

					<div className="flex justify-between items-center py-betweenComponents">
						<Heading6 className="font-medium text-text-muted">
							Acreditado
						</Heading6>
						{payment.credited && (
							<div className="flex-col  mb-2 whitespace-nowrap ">
								<div className="flex justify-end">
									<CurrencyImage currency={payment.credited.currency} />

									<Heading2 className="ml-betweenTexts font-medium whitespace-nowrap">
										{formatPriceAmount(
											payment.credited.amount,
											undefined,
											payment.credited.currency
										)}
									</Heading2>

									<Heading2 className="ml-betweenTexts font-light">
										{payment.credited.currency}
									</Heading2>
								</div>
								{/* {payment.creditedInRequestedCurrency && (
									<div className="flex justify-end">
										<Heading5 className="font-medium text-text-muted">
											{payment.creditedInRequestedCurrency.amount}{" "}
											{payment.creditedInRequestedCurrency.currency}
										</Heading5>
									</div>
								)} */}
							</div>
						)}
					</div>
				</BodyBlock>
			)
		case "crypto":
			return (
				<BodyBlock title="Resumen">
					<BodyItem
						name="Solicitado"
						value={`${formatPriceAmount(payment.price!.amount)} ${
							payment.price!.currency
						} ${
							payment.expected
								? `(${payment.expected.amount} ${payment.expected.currency})`
								: ""
						}`}
					/>
					{payment.receivedInRequestedCurrency && (
						<BodyItem
							name="Recibido"
							value={`${formatPriceAmount(payment.receivedInRequestedCurrency.amount)} ${
								payment.receivedInRequestedCurrency.currency
							} ${
								payment.received &&
								payment.received !== payment.receivedInRequestedCurrency
									? `(${formatPriceAmount(payment.received.amount)} ${payment.received.currency})`
									: ""
							}`}
						/>
					)}
					{payment.comission &&
						payment.comission.comissionInRequestedCurrency && (
							<BodyItem
								name="Comisión"
								value={`-${
									payment.comission.comissionInRequestedCurrency.amount
								} ${payment.comission.comissionInRequestedCurrency.currency} ${
									payment.comission.comission
										? `(-${payment.comission.comission.amount} ${payment.comission.comission.currency})`
										: ""
								}`}
							/>
						)}

					<div className="flex justify-between items-center py-betweenComponents">
						<Heading6 className="font-medium text-text-muted">
							Acreditado
						</Heading6>
						{payment.credited && (
							<div className="flex-col  mb-2 whitespace-nowrap ">
								<div className="flex justify-end">
									<CurrencyImage currency={payment.credited.currency} />

									<Heading2 className="ml-betweenTexts font-medium whitespace-nowrap">
										{formatPriceAmount(
											payment.credited.amount,
											undefined,
											payment.credited.currency
										)}
									</Heading2>

									<Heading2 className="ml-betweenTexts font-light">
										{payment.credited.currency}
									</Heading2>
								</div>
								{payment.creditedInRequestedCurrency && (
									<div className="flex justify-end">
										<Heading5 className="font-medium text-text-muted">
												{formatPriceAmount(payment.creditedInRequestedCurrency.amount)}{" "}
											{payment.creditedInRequestedCurrency.currency}
										</Heading5>
									</div>
								)}
							</div>
						)}
					</div>
				</BodyBlock>
			)
	}
}

export default SuccessPaymentSummary
