import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../lib/utils"
import { Heading5, Heading6 } from "./texts/TextStyle"
import Col from "./layouts/column"
import { Button } from "./button"
import Spacer from "./spacer"

const alertVariants = cva(
	"relative w-full rounded-sm border px-sm py-md flex grid-flow-row gap-betweenComponents",
	{
		variants: {
			variant: {
				default: "bg-transparent text-foreground",
				destructive:
					"border-destructive/50 text-destructive dark:border-destructive [&>svg]:text-destructive",
				warning:
					"border-border-warning-muted  dark:border-warning-muted [&>*]:text-text-warning bg-background-warningMuted dark:bg-transparent text-text-warning-strong dark:text-text-warning",
				link: "bg-transparent text-text",
				info: "border-border-info bg-blue-500/10 text-text-info  [&>svg]:text-text-info ",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	}
)

interface AlertProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof alertVariants> {
	icon: React.ReactNode

	title: string
	description: React.ReactNode | string
	bulletList?: boolean
	buttonLink?: string
	buttonText?: string
	buttonDisabled?: boolean
	onClickHandler?: () => void
}

const Alert: React.FC<AlertProps> = ({
	className,
	icon,
	title,
	description,
	bulletList = false,
	variant,
	buttonLink,
	buttonText,
	buttonDisabled,
	onClickHandler,
	...props
}) => {
	const DescriptionComponent = bulletList ? "ul" : Heading6

	return (
		<div
			role="alert"
			className={cn(alertVariants({ variant }), className)}
			{...props}
		>
			{variant === "link" ? (
				<div className="rounded-full size-fit p-3 [&>*]:size-[18px] [&>*]:stroke-[2.5px] group-hover:animate-[wiggle_1s_ease-in-out_infinite]  bg-background-strong grid justify-center items-center">
					{icon}
				</div>
			) : (
				<div className="size-md grid [&>*]:size-[20px] justify-center items-center">
					{icon}
				</div>
			)}

			<div>
				<Heading5
					className={`mb-1 font-semibold leading-none tracking-tight ${
						variant === "warning" && "text-text-warning "
					}${
						variant === "destructive" &&
						"text-text-error-strong dark:text-text-error"
					}`}
				>
					{title}
				</Heading5>
				{typeof description === "string" ? (
					<DescriptionComponent
						className={`font-normal [&_p]:leading-relaxed ${
							variant === "info" && "text-text-info-strong"
						} ${
							variant === "warning"
								? "text-text-warning-strong "
								: variant === "link" && "text-text-muted text-b1"
						}${variant === "destructive" && "text-text-error-strong"}`}
					>
						{description}
					</DescriptionComponent>
				) : (
					description
				)}
				{variant === "link" ? (
					<Col>
						<Spacer axis="vertical" type="betweenTexts" />
						<div className="w-full h-[1px] bg-border"></div>
						<Spacer axis="vertical" type="betweenTexts" />
						<Button
							variant="link"
							size={"flex"}
							onClick={() => {
								onClickHandler && onClickHandler()
							}}
							className={`w-fit -ml-4 ${
								buttonDisabled ? "text-gray-400" : "text-text-interactive"
							}`}
						>
							<a target="_blank" rel="noopener noreferrer" href={buttonLink}>
								{buttonText}
							</a>
						</Button>
					</Col>
				) : (
					""
				)}
			</div>
		</div>
	)
}
Alert.displayName = "Alert"

export { Alert }
