import {
	Angry,
	KeyRound,
	LoaderCircleIcon,
	PiggyBankIcon,
	Puzzle,
	RectangleEllipsis,
	Search,
	Trash2,
} from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../../components/ui/alert"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { useAuth } from "../../../context/AuthContext"
import ArsCover from "./PaymentsCover"
import { StoreType } from "../../../services/users"
import SlidingCard from "../../../components/ui/sliding-card"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { Input } from "../../../components/ui/inputs/input"
import Row from "../../../components/ui/layouts/row"
import { Button } from "../../../components/ui/button"
import { PluginSuccessCover } from "../../../components/modals/plugin-success/plugin-instalation-animation"
import { Heading6 } from "../../../components/ui/texts/TextStyle"
import Spacer from "../../../components/ui/spacer"





export interface Integration {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: Integration[] = [
	{
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
]

export default function Transfer() {
	const navigate = useNavigate()
	const { stores, kyc } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState<
		[boolean, any]
	>([false, undefined])

	const [isCardOpen, setIsCardOpen] = useState(false)

	const handleOptionClick = () => {
		setIsCardOpen(true)
	}

	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_CVU_APP_ID === undefined) {
				return
			}

			const findTiendanubeStore = (appId: string) => {
				for (const store of stores) {
					if (store.store_type === StoreType.TIENDANUBE && store.store_apps) {
						try {
							const app = store.store_apps.find(
								(app: any) => app.app_id === appId
							)
							if (app) {
								return { store, app }
							}	
						} catch (error) {
							console.error(error)
						}
					}
				}
				return undefined
			}

			const tiendanube = findTiendanubeStore(
				process.env.REACT_APP_TIENDANUBE_CVU_APP_ID
			)

			if (tiendanube) {
				setIsTiendanubeInstalled([true, tiendanube])
			}
		}
	}, [stores])

	function handleUninstallTiendanube(store_url: string) {
		window.location.href = `https://${store_url}/admin/v2/apps`
	}

	return (
		<FeatureLayout
			coverImage={<ArsCover />}
			sectionHeaderProps={{
				title: "Transferencias automáticas",
				subtitle:
					"Aceptar transferencias nunca fue más fácil. Instalá la integración y mirá los pagos llegar.",
			}}
		>
			<Alert
				variant="link"
				icon={<RectangleEllipsis />}
				title={"Información"}
				description={
					"Podés consultar las funciones y comisiones de las transferencias en nuestra web."
				}
				buttonText="Consultar en la Web"
				onClick={() => (window.location.href = "http://talo.com.ar")}
			></Alert>
			{(!kyc.data?.kyc_status || kyc.data.kyc_status !== "approved") && (
				<Section footer="Para mas información podés entrar a nuestra web y conocer todas las funciones nuevas. ">
					<Alert
						variant="link"
						icon={<RectangleEllipsis />}
						title={"Formulario"}
						description={
							"Para arrancar necesitamos saber un poco mas de vos. Llená el formulario y aguardá que verifiquemos tu cuenta para continuar."
						}
						buttonText="Completar formulario"
						//TODO: agregar link al docs posta
						onClick={() => navigate("/onboarding")}
					></Alert>
				</Section>
			)}
			{isTiendanubeInstalled[0] ? (
				<>
					<Section
						title="Integraciones activas"
						footer="Para agregar nueva integraciones. Prueba desvinculando la activa. "
					>
						<Option
							title="Tiendanube + Talo Pay"
							description="Activa"
							icon={
								<img
									src="/dashboard/plugins/tiendanube.svg"
									className="size-full"
								/>
							}
							onClick={handleOptionClick}
							leading="div"
							div={
								<Trash2
									onClick={() => {
										handleUninstallTiendanube(
											isTiendanubeInstalled[1].store.store_url
										)
									}}
									className="size-icon"
								></Trash2>
							}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
						<Option
							icon={<PiggyBankIcon />}
							title={"Cambiar banco de respaldo"}
							description="En caso del que servicio no este disponible"
							leading="arrow"
							onClick={() =>
								navigate(
									`/settings/payment-methods/transfer/plugins/${process.env.REACT_APP_TIENDANUBE_CVU_APP_ID}`
								)
							}
						/>
					</Section>
					<Section title="Opciones avanzadas">
						<Option
							title="Credenciales"
							icon={<KeyRound />}
							leading="arrow"
							className="description:text-text-success"
							onClick={() => navigate("/settings/plugins/credentials")}
						/>
					</Section>
					<SlidingCard
						open={isCardOpen}
						onOpenChange={setIsCardOpen}
						side="center"
					>
						<CenteredBody className=" items-center">
							<SectionHeader
								title="¡Felicitaciones!"
								subtitle="Has completado la integración."
							/>
							<PluginSuccessCover />
							<Spacer axis="vertical" type="imageBottom" />
							<Alert
								icon={<Angry className="h-4 w-4" />}
								title="¿Todavia no funciona la integración?"
								description={
									<ul className="text-h6 flex flex-col list-outside text-text-muted list-disc mt-2 [&>*]:m-0 pl-4">
										<li>
											Asegurate de haber pegado, el texto con el código indicado
											en la{" "}
											<a
												className="text-text-interactive cursor-pointer hover:underline"
												onClick={() =>
													navigate(
														`/settings/payment-methods/transfer/plugins/${process.env.REACT_APP_TIENDANUBE_CVU_APP_ID}`
													)
												}
											>
												instalación.
											</a>
										</li>
										<li>
											Verificá haber seguido todos los pasos correctamente en la
											documentación
										</li>
										<li>
											Probá{" "}
											<a
												className="text-text-error cursor-pointer hover:underline"
												onClick={() => {
													handleUninstallTiendanube(
														isTiendanubeInstalled[1].store.store_url
													)
												}}
											>
												desvinculando
											</a>{" "}
											la integración y volviendo a instalarla.
										</li>
										<li>
											Comunicate con{" "}
											<a
												className="text-text-interactive cursor-pointer hover:underline"
												onClick={() =>
													window.open(
														"https://wa.me/541133711752/?text=Hola,%20tuve%20un%20problema%20con%20la%20instalación%20del%20plugin%20de%20transferencias.",
														"_blank"
													)
												}
											>
												soporte
											</a>
											.
										</li>
									</ul>
								}
								bulletList
							></Alert>
						</CenteredBody>
					</SlidingCard>
				</>
			) : (
				kyc.data?.kyc_status && (
					<Section title="Conectá tu cuenta">
						<Option
							title="Integraciones"
							description="Conectá tu tienda online"
							icon={<Puzzle />}
							leading="arrow"
							onClick={() =>
								navigate("/settings/payment-methods/transfer/plugins")
							}
						/>
					</Section>
				)
			)}
			{/* <Section
				title="Banco de respaldo"
				footer="Proporciona un CBU para recibir tus fondos directamente en el caso que nuestro servicio no este disponible."
			>
				<Option
					icon={<PiggyBankIcon />}
					title={"Banco de respaldo"}
					description="En caso del que servicio no este disponible"
					leading="arrow"
					onClick={handleOptionClick}
				/>
				<SlidingCard
					open={isCardOpen}
					onOpenChange={setIsCardOpen}
					side="center"
				>
					<SectionHeader
						title={"Banco de respaldo"}
						subtitle="Ingresá una cuenta bancaria de respaldo para recibir tus fondos directamente en el caso que nuestro servicio no este disponible. "
					/>
					<Section>
						<Row className="gap-betweenButtons">
							<Input
								id="address"
								placeholder="CVU, CBU, Alias"
								data-test="withdraw-address-input"
							></Input>
							<Button variant={"outline"}>
								<Search className="size-icon"></Search>
							</Button>
						</Row>
					</Section>
				</SlidingCard>
			</Section> */}
		</FeatureLayout>
	)
}
