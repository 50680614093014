import {
	TableTransaction,
	TableTransactionStatus,
} from "../../../tables/transactions-table/columns"
import {
	statusDescriptions,
	statusIcons,
	statusTranslations,
} from "../../../tables/transactions-table/columns/statusCell"
import { Button } from "../../../ui/button"
import Row from "../../../ui/layouts/row"
import { Body1, Heading5, Heading6 } from "../../../ui/texts/TextStyle"
import SlidingCard from "../../../ui/sliding-card"
import CenteredBody from "../../../ui/layouts/platform/body/CenteredBody"
import { Section } from "../../../ui/section"
import { SectionHeader } from "../../../ui/section-header"
import { Alert } from "../../../ui/alert"
import { Loader, Undo } from "lucide-react"
import { Chip } from "../../../ui/chip"
import { Option } from "../../../ui/options/option"
import Col from "../../../ui/layouts/column"
import {
	CancelationReason,
	ConfirmationReason,
	OrderActionType,
	useMutateOrderAction,
} from "../../../../hooks/mutateOrderAction"
import { useState, useEffect } from "react"
import {
	getOrderStatus,
	getStoreObject,
	setNewOrderStatus,
} from "../../../tables/transactions-table/data"
import { useAuth } from "../../../../context/AuthContext"
import { useCombinedPayments } from "../../../../hooks/usePayments"

// Add this interface near the top of the file
interface OrderStatus {
	type: "confirm" | "cancel"
	reason: ConfirmationReason | CancelationReason
}

const CANCEL_REASONS: { label: string; value: CancelationReason }[] = [
	{ label: "El cliente cambio de idea", value: "CUSTOMER" },
	{ label: "Pago rechazado", value: "DECLINED" },
	{ label: "Producto no disponible", value: "INVENTORY" },
	{ label: "Prueba", value: "STAFF" },
	{ label: "Pedido fraudulento", value: "FRAUD" },
	{ label: "Otros", value: "OTHER" },
]

const CONFIRM_REASONS: { label: string; value: ConfirmationReason }[] = [
	{ label: "El cliente pagó por otro medio", value: "EXTERNAL_PAYMENT_METHOD" },
	{
		label: "El cliente pagó al cvu de otro pago",
		value: "COMBINED_ORDERS_PAYMENT",
	},
	{
		label: "Pago insuficiente pero tolerable",
		value: "TOLERABLE_UNDERPAYMENT",
	},
	{ label: "Otros", value: "OTHER" },
]

interface PaymentStatusRowProps {
	status: TableTransactionStatus
	payment: TableTransaction
	onPaymentUpdate?: (updatedPayment: TableTransaction) => void
}

const PaymentStatusRow = ({
	status,
	payment: initialPayment,
	onPaymentUpdate,
}: PaymentStatusRowProps) => {
	const [restock, setRestock] = useState(false)
	const [notifyClient, setNotifyClient] = useState(false)
	const [selectedCancelationReason, setSelectedCancelationReason] =
		useState<CancelationReason | null>(null)
	const [selectedConfirmationReason, setSelectedConfirmationReason] =
		useState<ConfirmationReason | null>(null)
	const [isConfirmCardOpen, setIsConfirmCardOpen] = useState(false)
	const [isCancelCardOpen, setIsCancelCardOpen] = useState(false)

	const mutation = useMutateOrderAction(initialPayment.id)
	const { user } = useAuth()
	const payments = useCombinedPayments(
		user.id,
		user.roles.includes("pos") ? user.team_id : undefined
	)

	const resetStates = () => {
		setRestock(false)
		setNotifyClient(false)
		setSelectedCancelationReason(null)
		setSelectedConfirmationReason(null)
	}

	useEffect(() => {
		if (mutation.isSuccess) {
			setIsConfirmCardOpen(false)
			setIsCancelCardOpen(false)
			payments.refetch()
		}
	}, [mutation.isSuccess])

	const handleConfirmOrder = () => {
		if (!selectedConfirmationReason) {
			mutation.mutate({
				order_action: "confirm",
				confirm_payload: {
					reason: "OTHER", // fallback value to trigger error
				},
			})
			return
		}

		mutation.mutate({
			order_action: "confirm",
			confirm_payload: {
				reason: selectedConfirmationReason,
			},
		})

	}

	const handleCancelOrder = () => {
		if (!selectedCancelationReason) {
			mutation.mutate({
				order_action: "cancel",
				cancel_payload: {
					reason: "OTHER", // fallback value to trigger error
					notifyClient,
					restock,
					staffNote: "",
				},
			})
			return
		}

		mutation.mutate({
			order_action: "cancel",
			cancel_payload: {
				reason: selectedCancelationReason,
				notifyClient: notifyClient,
				restock: restock,
				staffNote: "",
			},
		})

	}

	// Track the current payment state
	const [currentPayment, setCurrentPayment] = useState(initialPayment)

	// Update orderStatus and hasStore when currentPayment changes
	const [orderStatus, setOrderStatus] = useState(() =>
		getOrderStatus(currentPayment)
	)
	const [hasStore, setHasStore] = useState(() => getStoreObject(currentPayment))

	// Log state changes
	useEffect(() => {
		console.log("Payment state changed:", {
			initialPayment,
			currentPayment,
			orderStatus,
			hasStore,
		})
	}, [initialPayment, currentPayment, orderStatus, hasStore])

	useEffect(() => {
		if (mutation.isError) {
			console.log(mutation.error)
		}
		if (mutation.isSuccess) {
			const newStatus = selectedConfirmationReason
				? {
						type: "confirm" as OrderActionType,
						reason: selectedConfirmationReason,
				  }
				: selectedCancelationReason
				? {
						type: "cancel" as OrderActionType,
						reason: selectedCancelationReason,
				  }
				: undefined

			if (newStatus) {
				const updatedPayment = setNewOrderStatus(currentPayment, newStatus)
				setCurrentPayment(updatedPayment)
				setOrderStatus(getOrderStatus(updatedPayment))
				setHasStore(getStoreObject(updatedPayment))
				onPaymentUpdate?.(updatedPayment)
				// Don't need to call payments.refetch() here since mutation will trigger it
			}
		}
	}, [
		mutation.isSuccess,
		selectedConfirmationReason,
		selectedCancelationReason,
		currentPayment,
	])

	// Update currentPayment when initialPayment changes
	useEffect(() => {
		console.log("Initial payment changed:", initialPayment)
		setCurrentPayment(initialPayment)
	}, [initialPayment])

	// Reset all state when payment ID changes
	useEffect(() => {
		console.log("Payment changed in status row:", initialPayment.id)
		setCurrentPayment(initialPayment)
		setOrderStatus(getOrderStatus(initialPayment))
		setHasStore(getStoreObject(initialPayment))
		resetStates()
	}, [initialPayment.id]) // Only reset when payment ID changes

	return (
		<div className="flex flex-col bg-background-subtle mb-6 border border-border p-md rounded-md gap-betweenComponents">
			<div
				className={`flex flex-row items-center text-text ${
					(status === "PROCESSED" ||
						status === "SENT" ||
						status === "processed" ||
						status === "pending") &&
					"-mb-3 "
				}`}
			>
				<>
					<div className="size-fit pr-betweenComponents">
						{statusIcons[status]}
					</div>
					<div className="flex flex-col">
						<Heading5 className="font-medium pb-[4px]">
							{statusTranslations[status]}
						</Heading5>

						<Heading6 className="text-text-muted font-medium">
							{statusDescriptions[status]}
						</Heading6>
					</div>
				</>
			</div>

			{orderStatus && orderStatus.type === "confirm" && (
				<div className="flex flex-col  px-9 gap-betweenComponents flex-wrap w-full">
					<Chip
						variant="secondary"
						size="sm"
						className="cursor-default dark:bg-blue-800/10 bg-blue-500/5 text-blue-500 border-blue-500/40 text-sm"
					>
						Orden confirmada manualmente
					</Chip>

					<div className="border-t border-border py-2">
						<Body1 className="text-text-muted font-medium pl-2">
							Nota:{" "}
							{
								CONFIRM_REASONS.find(
									(reason) => reason.value === orderStatus!.reason
								)?.label
							}
						</Body1>
					</div>
				</div>
			)}
			{orderStatus && orderStatus.type === "cancel" && (
				<div className="flex flex-col  px-9 gap-betweenComponents flex-wrap w-full">
					<Chip
						size="sm"
						className="cursor-default bg-red-500/10 text-red-500 border-red-500/40 text-sm"
					>
						Orden cancelada manualmente
					</Chip>

					<div className="border-t border-border py-2">
						<Body1 className="text-text-muted font-medium pl-2">
							Nota:{" "}
							{
								CANCEL_REASONS.find(
									(reason) => reason.value === orderStatus!.reason
								)?.label
							}
						</Body1>
					</div>
				</div>
			)}

			{status !== "SENT" && !orderStatus && hasStore && (
				<Row className="gap-betweenButtons w-full px-9 justify-start">
					{/* //-------------------- CONFIRMATION ---------------------------// */}

					{(status === "UNDERPAID" ||
						status === "EXPIRED" ||
						status === "PENDING") && (
						<SlidingCard
							side={"center"}
							trigger={
								<Button variant="outline" size="md">
									Confirmar orden
								</Button>
							}
							onOpenChange={(open) => {
								setIsConfirmCardOpen(open)
								if (!open) resetStates()
							}}
							open={isConfirmCardOpen}
						>
							<CenteredBody>
								<SectionHeader
									title="¿Estas seguro?"
									subtitle={
										<p>
											Estas por{" "}
											<b className="text-green-500">confirmar la orden</b> en
											talo y en tu tienda online.
										</p>
									}
								/>

								<Section title="Motivo de confirmación">
									<div className="flex flex-col gap-2">
										<div className="flex flex-wrap gap-betweenChips">
											{CONFIRM_REASONS.map((reason) => (
												<Chip
													key={reason.value}
													size="sm"
													onClick={() =>
														setSelectedConfirmationReason(reason.value)
													}
													className={`cursor-pointer transition-all duration-300 ${
														selectedConfirmationReason === reason.value
															? "bg-green-500/10 border-green-500 text-green-500 text-h6"
															: "hover:border-green-500/50"
													}`}
												>
													{reason.label}
												</Chip>
											))}
										</div>
										{!selectedConfirmationReason && mutation.isError && (
											<p className="text-sm text-green-500">
												Por favor, selecciona el motivo de la confirmación
											</p>
										)}
									</div>
								</Section>
								<Col className="gap-betweenFields">
									<Option
										className="bg-transparent border-none pl-0 py-0 min-h-1"
										title={
											<p className="text-h6 font-medium">
												Quiero reponer inventario
											</p>
										}
										trailing="switch"
										leading="div"
										switchChecked={restock}
										onCheckedChange={setRestock}
									/>
									<Option
										className="bg-transparent border-none pl-0 py-0 min-h-1"
										title={
											<p className="text-h6 font-medium">
												Quiero notificar al cliente
											</p>
										}
										trailing="switch"
										leading="div"
										switchChecked={notifyClient}
										onCheckedChange={setNotifyClient}
									/>
								</Col>
								<Row className="w-full gap-betweenButtons">
									<Button variant="outline" size="full">
										Regresar
									</Button>
									<Button
										size="full"
										onClick={handleConfirmOrder}
										disabled={mutation.isPending || !selectedConfirmationReason}
									>
										{mutation.isPending ? (
											<Loader className="animate animate-spin" />
										) : (
											"Confirmar orden"
										)}
									</Button>
								</Row>
								{mutation.isError && (
									<p className="text-text-error text-sm mt-2">
										{"Ha ocurrido un error al confirmar la orden"}
									</p>
								)}
							</CenteredBody>
						</SlidingCard>
					)}

					{/* //-------------------- CANCELATION ---------------------------// */}

					{(status === "UNDERPAID" ||
						status === "EXPIRED" ||
						status === "PENDING") &&
						hasStore && (
							<SlidingCard
								side={"center"}
								trigger={
									<Button variant="outline" size="md">
										Cancelar orden
									</Button>
								}
								onOpenChange={(open) => {
									setIsCancelCardOpen(open)
									if (!open) resetStates()
								}}
								open={isCancelCardOpen}
							>
								<CenteredBody>
									<SectionHeader
										title="¿Estas seguro?"
										subtitle={
											<p>
												Estas por{" "}
												<b className="text-destructive">cancelar la orden</b> en
												talo y en tu tienda online.
											</p>
										}
									/>
									<Section>
										<Alert
											icon={<Undo />}
											title={"No se hará el rembolso"}
											description={
												"No se rembolsará a tu cliente. Rembolsá el monto adecuado más adelante."
											}
										></Alert>
									</Section>
									<Section title="Motivo de cancelación">
										<div className="flex flex-col gap-2">
											<div className="flex flex-wrap gap-betweenChips">
												{CANCEL_REASONS.map((reason) => (
													<Chip
														key={reason.value}
														size="sm"
														onClick={() =>
															setSelectedCancelationReason(reason.value)
														}
														className={`cursor-pointer transition-all duration-300 ${
															selectedCancelationReason === reason.value
																? "bg-red-500/10 border-red-500 text-red-500"
																: "hover:border-red-500/50"
														}`}
													>
														{reason.label}
													</Chip>
												))}
											</div>
											{!selectedCancelationReason && mutation.isError && (
												<p className="text-sm text-red-500">
													Por favor, selecciona el motivo de la cancelación
												</p>
											)}
										</div>
									</Section>
									<Col className="gap-betweenFields">
										<Option
											className="bg-transparent border-none pl-0 py-0 min-h-1"
											title={
												<p className="text-h6 font-medium">
													Quiero reponer inventario
												</p>
											}
											trailing="switch"
											leading="div"
											switchChecked={restock}
											onCheckedChange={setRestock}
										/>
										<Option
											className="bg-transparent border-none pl-0 py-0 min-h-1"
											title={
												<p className="text-h6 font-medium">
													Quiero notificar al cliente
												</p>
											}
											trailing="switch"
											leading="div"
											switchChecked={notifyClient}
											onCheckedChange={setNotifyClient}
										/>
									</Col>
									<Row className="w-full gap-betweenButtons">
										<Button
											variant="outline"
											size="full"
											onClick={(open) => setIsCancelCardOpen(!open)}
										>
											Regresar
										</Button>
										<Button
											variant="destructive"
											size="full"
											onClick={handleCancelOrder}
											disabled={
												mutation.isPending || !selectedCancelationReason
											}
										>
											{mutation.isPending ? "Cancelando..." : "Cancelar orden"}
										</Button>
									</Row>
									{mutation.isError && (
										<p className="text-text-error text-sm mt-2">
											{"Ha ocurrido un error al cancelar la orden"}
										</p>
									)}
								</CenteredBody>
							</SlidingCard>
						)}
				</Row>
			)}
		</div>
	)
}

export default PaymentStatusRow
