import { ColumnDef, Row } from "@tanstack/react-table"
import { Store } from "../../../hooks/useFetchStores"
import { PaymentOption } from "../../../pages/charge/charge-wizard-types"
import { PaymentComission } from "../../modals/payment-modal/PaymentModal"
import { CreditedMoneyColumn } from "./columns/creditedMoneyColumn"
import {
	CurrencyImageCell,
	tableTransactionCurrencies,
} from "./columns/currencyImageCell"
import { DateAndTimeCell } from "./columns/dateAndTimeCell"
import { MoneyCell } from "./columns/MoneyCell"
import { OrderCell } from "./columns/OrderCell"
import { StatusColumn, tableTransactionStatus } from "./columns/statusCell"
import { StoreCell } from "./columns/StoreCell"

// Update the date filter function
const dateRangeFilter = (
	row: Row<TableTransaction>,
	id: string,
	value: { from: Date; to: Date }
) => {
	const dateStr = row.getValue<{ date: string; time: string }>(id).date
	const timeStr = row.getValue<{ date: string; time: string }>(id).time

	// Handle "Hoy" and "Ayer" cases
	if (dateStr.toLowerCase() === "hoy") {
		const today = new Date()
		return today >= value.from && today <= value.to
	}
	if (dateStr.toLowerCase() === "ayer") {
		const yesterday = new Date()
		yesterday.setDate(yesterday.getDate() - 1)
		return yesterday >= value.from && yesterday <= value.to
	}

	// Handle DD/MM/YY format
	const [day, month, year] = dateStr.split("/")
	const [hours, minutes] = timeStr.split(":")
	const isPM = timeStr.toLowerCase().includes("p. m.")

	const fullYear = parseInt("20" + year)
	const rowDate = new Date(fullYear, parseInt(month) - 1, parseInt(day))
	rowDate.setHours(
		isPM ? parseInt(hours) + 12 : parseInt(hours),
		parseInt(minutes),
		0,
		0
	)

	return rowDate >= value.from && rowDate <= value.to
}

export type Price = {
	amount: number | string
	currency: TableTransactionCurrency
}
export type TableTransactionStatus = (typeof tableTransactionStatus)[number]
export type TableTransactionCurrency =
	(typeof tableTransactionCurrencies)[number]

export type TableTransactionNetwork = "BSC" | "POLYGON" | "PIX" | "POLLUX"

export type TableSubTransaction = {
	network: TableTransactionNetwork
	currency: TableTransactionCurrency
	amount: number | string
	amountReadable: string
	credited: Price
	received: Price
	receivedInRequestedCurrency: Price
	creditedInRequestedCurrency: Price
	commission: PaymentComission
	transactionHash: string
	transactionId: string
	senderAddress: string
	senderIsPsp: boolean
	psp_id?: string
	bank_id?: string
	date: string
	time: string
	senderCuit?: string
	senderTitular?: string
}

export type TiendanubePaymentInfo = {
	order_id: number
	order_number: number
	client_email?: string
	store_id: string
}

export type WoocommercePaymentInfo = {
	order_id: number
	client_email?: string
	store_id: string
}

export type ShopifyPaymentInfo = {
	order_id: string
	order_name?: string
	store_id: string
}

export type TableTransaction = {
	id: string
	orderId?: string | number
	currency?: (typeof tableTransactionCurrencies)[number]
	status: (typeof tableTransactionStatus)[number]
	lastModifiedDateTime: {
		date: string
		time: string
	}
	creationDateTime: {
		date: string
		time: string
	}
	receivedInRequestedCurrency?: Price
	sender?: string
	sender_cuit?: string
	sender_titular?: string
	buyer?: string
	expected?: Price
	received?: Price
	credited?: Price
	creditedInRequestedCurrency?: Price
	price?: Price
	comission?: PaymentComission
	network?: TableTransactionNetwork
	address?: string
	alias?: string
	transactions?: TableSubTransaction[]
	transactionHash?: string
	tiendanube?: TiendanubePaymentInfo
	woocommerce?: WoocommercePaymentInfo
	shopify?: ShopifyPaymentInfo
	paymentUrl?: string
	psp_id?: string
	bank_id?: string
	expiration_timestamp?: {
		date: string
		time: string
	}
	payment_options?: PaymentOption[]
	recipient_account?: RecipientAccount | undefined
	store_id?: number | string
	store_name?: string
}

export type RecipientAccount = {
	account_type: string
	active_account: boolean
	address: string
	alias: string
	creation_timestamp: string
	cuit: string
	cvu_type: string
	entity_type: string
	last_modified_timestamp: string
	last_used_timestamp: string
	name: string
	psp_id: string
	bank_id: string
	user_id: string
}

export const getColumns = (
	stores: Store[] | undefined
): { [key: string]: ColumnDef<TableTransaction>[] } => ({
	xl: [
		StatusColumn(),
		OrderCell({
			accessorKey: "orderId",
			header: "Orden",
		}),
		...(stores && stores.length > 1
			? [
					StoreCell<TableTransaction>({
						accessorKey: "store_name",
						header: "Tienda",
						stores: stores,
					}),
			  ]
			: []),
		MoneyCell<TableTransaction>({
			accessorKey: "expected",
			header: "Solicitado",
		}),
		MoneyCell<TableTransaction>({
			accessorKey: "received",
			header: "Recibido",
		}),
		CurrencyImageCell<TableTransaction>(),
		DateAndTimeCell({
			accessorKey: "creationDateTime",
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
	],
	lg: [
		StatusColumn(),
		OrderCell({
			accessorKey: "orderId",
			header: "Orden",
		}),
		...(stores && stores.length > 1
			? [
					StoreCell<TableTransaction>({
						accessorKey: "store_name",
						header: "Tienda",
						stores: stores,
					}),
			  ]
			: []),
		MoneyCell({
			accessorKey: "received",
			header: "Recibido",
		}),
		// CurrencyImageCell(),
		DateAndTimeCell({
			accessorKey: "creationDateTime",
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
	],
	md: [
		StatusColumn(),
		...(stores && stores.length > 1
			? [
					StoreCell<TableTransaction>({
						accessorKey: "store_name",
						header: "Tienda",
						stores: stores,
					}),
			  ]
			: []),
		CreditedMoneyColumn({
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
	],
	sm: [
		StatusColumn(),
		CreditedMoneyColumn({
			enableDateFilter: true,
			filterFn: dateRangeFilter,
		}),
	],
})
