import {
	KeyRound,
	Puzzle,
	RectangleEllipsis,
	Trash,
	Trash2,
	UndoDot,
} from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../../components/ui/alert"
import { Button } from "../../../components/ui/button"
import Col from "../../../components/ui/layouts/column"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { SectionHeader } from "../../../components/ui/section-header"
import SlidingCard from "../../../components/ui/sliding-card"
import Spacer from "../../../components/ui/spacer"
import { useAuth } from "../../../context/AuthContext"
import {
	StoreType,
	updateUser as serviceUpdateUser,
} from "../../../services/users"
import { fiatCurrencies } from "../../../utils/currencies"
import { CryptoAllLogo } from "../../../utils/withdrawCurrencies"
import { CryptoCover } from "./PaymentsCover"

export interface Plugin {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: { [key in StoreType]: Plugin } = {
	tiendanube: {
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
	woocommerce: {
		title: "Woocommerce + Talo Pay",
		icon: (
			<img src="/dashboard/plugins/woocommerce.svg" className="size-full" />
		),
	},
	shopify: {
		title: "Shopify + Talo Pay",
		icon: <img src="/dashboard/plugins/shopify.svg" className="size-full" />,
	},
}

export default function Crypto() {
	const navigate = useNavigate()
	const { user, stores, updateUser } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState<
		[boolean, any]
	>([false, undefined])
	const [isTiendanubeLegacyInstalled, setIsTiendanubeLegacyInstalled] =
		useState<[boolean, any]>([false, undefined])
	const [isWooCommerceInstalled, setIsWooCommerceInstalled] = useState(false)

	const [isCardOpen, setIsCardOpen] = useState(false)
	const [cryptoPaymentsEnabled, setCryptoPaymentsEnabled] = useState(
		user?.crypto_payments_enabled !== false ? true : false
	)

	const handleSwitchChange = async (checked: boolean) => {
		setCryptoPaymentsEnabled(checked)
		const res = await serviceUpdateUser(user.id, {
			crypto_payments_enabled: checked,
		})
		if (res && res.status >= 200 && res.status < 300) {
			updateUser(res.data.data)
		}
	}

	const handleOptionClick = () => {
		setIsCardOpen(true)
	}

	function handleUninstallTiendanube(store_url: string) {
		window.location.href = `https://${store_url}/admin/v2/apps`
	}

	useEffect(() => {
		if (stores) {
			if (
				!process.env.REACT_APP_TIENDANUBE_APP_ID ||
				!process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID
			) {
				return
			}
			const findTiendanubeStore = (appId: string) => {
				for (const store of stores) {
					if (store.store_type === StoreType.TIENDANUBE && store.store_apps) {
						const app = store.store_apps.find(
							(app: any) => app.app_id === appId
						)
						if (app) {
							return { store, app }
						}
					}
				}
				return undefined
			}

			const tiendanubeHomoStore = findTiendanubeStore(
				process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID
			)
			const tiendanubeLegacyStore = findTiendanubeStore(
				process.env.REACT_APP_TIENDANUBE_APP_ID
			)

			if (tiendanubeHomoStore) {
				setIsTiendanubeInstalled([true, tiendanubeHomoStore])
			}

			if (tiendanubeLegacyStore) {
				setIsTiendanubeLegacyInstalled([true, tiendanubeLegacyStore])
			}

			const isWooCommerceStore = stores.some(
				(store: any) => store.store_type === StoreType.WOOCOMMERCE
			)

			if (isWooCommerceStore) {
				setIsWooCommerceInstalled(true)
			}
		}
	}, [stores])

	return (
		<FeatureLayout
			coverImage={<CryptoCover />}
			sectionHeaderProps={{
				title: "Pagos en criptomonedas",
				subtitle:
					"Aceptá criptomonedas desde cualquier billetera y expandite a nuevos clientes. Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pago y comenzar a cobrar a clientes de todo el mundo.",
			}}
		>
			<Section>
				<Option
					title="Criptomonedas"
					description=""
					icon={<CryptoAllLogo />}
					iconInside={false}
					leading="switch"
					switchChecked={cryptoPaymentsEnabled}
					onCheckedChange={handleSwitchChange}
				/>
				<Option
					title="Cotización dólar"
					description="Ajustá el tipo de cambio que más te sirva"
					icon={<img src={fiatCurrencies.USD.flagPath} alt="USD" />}
					iconInside={false}
					leading="arrow"
					onClick={() => navigate("/settings/payment-methods/pricing-profile")}
				/>
				<Alert
					variant="link"
					icon={<RectangleEllipsis />}
					title={"Recibir monedas digitales"}
					description={
						"El dinero se acreditará en criptomonedas. Podrás retirarla a cualquier billetera que soporte la red BSC o BNB Chain. Para más información visitar la documentación."
					}
					buttonText="Abrir documentación web"
					buttonLink="https://docs.talo.com.ar/withdrawals/introduction"
				/>
			</Section>

			{isTiendanubeInstalled[0] ||
			isTiendanubeLegacyInstalled[0] ||
			isWooCommerceInstalled ? (
				<Section
					title={
						<Col className="gap-betweenTexts inline">
							<p>Integraciones activas </p>

							<p className="text-text-muted text-h6">
								Crypto y BRL comparten la integración
							</p>
						</Col>
					}
					footer="Para agregar nueva integraciones. Probá desvinculando la activa."
				>
					{isTiendanubeLegacyInstalled[0] && (
						<>
							<Alert
								//le puse cualquier icon, ponele el que mas te guste @bautitravella
								variant={"warning"}
								icon={<UndoDot />}
								title={"Aplicación desactualizada"}
								description={
									"Esta integración esta obsoleta. Desinstalar y volver a instalar la nueva"
								}
							></Alert>
							<Option
								key={integrationsAvailable.tiendanube.title}
								title={integrationsAvailable.tiendanube.title}
								description="Activa"
								icon={integrationsAvailable.tiendanube.icon}
								leading="div"
								div={<Trash2 className="size-icon" />}
								onClick={handleOptionClick}
								iconInside={false}
								className="bg-background description:text-text-success"
							/>
							<SlidingCard
								open={isCardOpen}
								onOpenChange={setIsCardOpen}
								side="center"
							>
								<CenteredBody>
									<SectionHeader
										title="¿Estás seguro?"
										subtitle="Estás por ser redireccionado a tu panel de tiendanube para desinstalar la integración actual"
									/>
									<Spacer axis="vertical" type="belowSectionHeader" />
									<Button
										onClick={() => {
											handleUninstallTiendanube(
												isTiendanubeLegacyInstalled[1].store.store_url
											)
										}}
									>
										Desinstalar
									</Button>
								</CenteredBody>
							</SlidingCard>
						</>
					)}
					{isTiendanubeInstalled[0] && (
						<Option
							key={integrationsAvailable.tiendanube.title}
							title={integrationsAvailable.tiendanube.title}
							description="Activa"
							icon={integrationsAvailable.tiendanube.icon}
							leading="div"
							div={<Trash className="size-icon" />}
							onClick={() => {
								handleUninstallTiendanube(
									isTiendanubeInstalled[1].store.store_url
								)
							}}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
					)}
					{isWooCommerceInstalled && (
						<Option
							key={integrationsAvailable.woocommerce.title}
							title={integrationsAvailable.woocommerce.title}
							description="Activa"
							icon={integrationsAvailable.woocommerce.icon}
							leading="div"
							div={null}
							iconInside={false}
							className="bg-background description:text-text-success"
						/>
					)}
					<Option
						title="Credenciales"
						icon={<KeyRound />}
						leading="arrow"
						className="description:text-text-success"
						onClick={() => navigate("/settings/plugins/credentials")}
					/>
				</Section>
			) : (
				<Section title="Conectá tu cuenta">
					<Option
						title="Integraciones"
						description="Conectá tu tienda online"
						icon={<Puzzle />}
						leading="arrow"
						onClick={() => navigate("plugins")}
					/>
				</Section>
			)}
		</FeatureLayout>
	)
}
