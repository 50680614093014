import React from "react"

import useBreakpoint from "../../hooks/useBreakpoint"
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "./sheet"
import {
	Drawer,
	DrawerContent,
	DrawerDescription,
	DrawerHeader,
	DrawerTitle,
	DrawerTrigger,
} from "./drawer"
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "./dialog"
import CenteredBody from "./layouts/platform/body/CenteredBody"

interface SlidingCardProps {
	header?: React.ReactNode
	open?: boolean
	onOpenChange?: (open: boolean) => void
	side: "left" | "right" | "top" | "bottom" | "center"
	trigger?: React.ReactNode
	children: React.ReactNode
	animationDuration?: number; // Add optional animation duration
}

const SlidingCard: React.FC<SlidingCardProps> = ({
	header,
	open,
	onOpenChange,
	side,
	trigger,
	children,
	animationDuration = 300, // Default duration
}) => {
	const isSm = useBreakpoint("sm")

	// Force side to "bottom" if isSm is true
	const effectiveSide = isSm ? "bottom" : side

	const transitionStyles = `transition-all duration-${animationDuration}`

	switch (effectiveSide) {
		case "left":
		case "right":
		case "top":
			return (
				<Sheet open={open} onOpenChange={onOpenChange}>
					<SheetTrigger>{trigger}</SheetTrigger>
					<SheetContent 
						side={effectiveSide}
						className={`transition-transform duration-${animationDuration}`}
					>
						<SheetHeader>
							<SheetTitle />
							<SheetDescription />
							{header}
						</SheetHeader>
						{children}
					</SheetContent>
				</Sheet>
			)

		case "bottom":
			return (
				<Drawer
					open={open}
					onOpenChange={onOpenChange}
					direction="bottom"
					handleOnly={true}
					disablePreventScroll
				>
					<DrawerTrigger asChild>{trigger}</DrawerTrigger>
					<DrawerContent>
						<DrawerHeader>
							<DrawerTitle />
							<DrawerDescription />
							{header}
						</DrawerHeader>
						{children}
					</DrawerContent>
				</Drawer>
			)

		case "center":
			return (
				<Dialog 
					open={open} 
					onOpenChange={onOpenChange}
				>
					<DialogTrigger asChild>{trigger}</DialogTrigger>
					<DialogContent className={transitionStyles}>
						<DialogHeader>
							{header}
							<DialogTitle />
							<DialogDescription />
						</DialogHeader>
						<CenteredBody>{children}</CenteredBody>
					</DialogContent>
				</Dialog>
			)

		default:
			return null
	}
}

export default SlidingCard
