import { PaymentStatus, Price } from "@crypto-payment-gateway/models"
import {
	ArrowLeft,
	ArrowRight,
	CircleAlert,
	CircleCheck,
	LoaderCircle,
	Moon,
	Sun,
} from "lucide-react"
import { useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ConvertedAmount from "../../components/checkout/ConvertedAmount"
import ExpiredPayment from "../../components/checkout/ExpiredPayment"
import PaymentCounter from "../../components/checkout/PaymentCounter"
import PaymentDescription from "../../components/checkout/paymentDescription"
import PaymentWizard from "../../components/checkout/wizard"
import ConfirmationPending from "../../components/checkout/wizard/ConfirmationPending"
import PaymentErrorCard, {
	PaymentErrorCardProps,
} from "../../components/checkout/wizard/PaymentErrorCard"
import BodyBlock from "../../components/modals/payment-modal/body/BodyBlock"
import BodyItem from "../../components/modals/payment-modal/body/BodyItem"
import { Button } from "../../components/ui/button"
import Col from "../../components/ui/layouts/column"
import LandingLayout from "../../components/ui/layouts/landing/LandingLayout"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import Row from "../../components/ui/layouts/row"
import { SectionHeader } from "../../components/ui/section-header"
import { Heading6 } from "../../components/ui/texts/TextStyle"
import { useGlobalStore } from "../../hooks/useGlobalStore"
import { getPublicPayment } from "../../services/payments"
import logger from "../../utils/logger"
import { useMediaQuery } from "../../utils/useMediaQuery"
import { PaymentOption } from "../charge/charge-wizard-types"

export type UserInfo = {
	email: string
	name?: string
	img_url?: string
	fantasy_name?: string
}
export type Quote = {
	address: string
	amount: string
	amountReadable?: string
	currency: string
	network: string
}

export type PaymentStatusOptions = "PENDING" | "SUCCESS" | "EXPIRED" | "OVERPAID" | "UNDERPAID"

export type Payment = {
	[x: string]: any
	price: Price
	id: string
	payment_url: string
	redirect_url: string
	creation_timestamp: string
	expiration_timestamp: string
	user_info: UserInfo
	payment_status: PaymentStatusOptions
	transaction_fields: any
	quotes: Quote[]
	payment_options: PaymentOption[]
}

const CheckoutId = (props: any) => {
	const [isLoading, setIsLoading] = useState(true)
	const [isPaymentPending, setIsPaymentPending] = useState(false)
	const navigate = useNavigate()
	const params = useParams()
	const { paymentId } = params
	//logger.info("pageid=", paymentId)
	const [payment, setPayment] = useState<Payment>()
	const [intervalId, setIntervalId] = useState<any>()
	const is_payment_pending =
		payment && payment.payment_status === "PENDING" ? true : false
	const getPaymentFromApi = useCallback(async () => {
		if (!payment || is_payment_pending) {
			const paymentResult = await getPublicPayment(paymentId as string)
			logger.info("paymentResult=", paymentResult)
			if (
				payment &&
				paymentResult &&
				payment.payment_status === "PENDING" &&
				paymentResult.payment_status !== "PENDING" &&
				paymentResult.payment_status !== "EXPIRED" &&
				paymentResult.redirect_url &&
				paymentResult.redirect_url !== ""
			) {
				setTimeout(() => {
					window.open(paymentResult.redirect_url, "_self")
				}, 1000 * 10)
			}
			if (paymentResult && Object.keys(paymentResult).length !== 0) {
				setPayment(paymentResult)
			}
		} else {
			if (intervalId) {
				clearInterval(intervalId)
			}
		}
	}, [payment, intervalId, paymentId])
	const polling = () => {
		const intervalID = setInterval(getPaymentFromApi, 10 * 1000) //el ultimo *100 fui yo para codear mas comodo
		setIntervalId(intervalID)
	}

	useEffect(() => {
		//get charge from api
		const now = new Date()
		const expiration = new Date()
		expiration.setMinutes(now.getMinutes() + 9)
		getPaymentFromApi().then(() => setIsLoading(false))
		polling()
	}, [])

	const isSm = useMediaQuery(960)
	const [language, setLanguage] = useState<"spanish" | "portuguese">("spanish")
	const { setIsDarkTheme, isDarkTheme } = useGlobalStore()

	const payment_details: PaymentErrorCardProps = {
		payment_status: payment?.payment_status as PaymentStatus,
		payment_transaction: payment?.transaction_fields,
		quote: payment?.quotes?.find(
			(quote) => quote.currency === payment?.transaction_fields?.currency
		),
	}
	console.log(payment_details)
	return (
		<LandingLayout
			centerComponent={
				is_payment_pending && (
					<div>
						{language === "spanish" ? "Hacé tu pago" : "Faça seu pagamento"}{" "}
					</div>
				)
			}
			rightComponent={
				is_payment_pending ? (
					<PaymentCounter
						creation_timestamp={payment?.creation_timestamp}
						expiration_timestamp={payment?.expiration_timestamp}
					/>
				) : (
					<></>
				)
			}
		>
			<CenteredBody className="px-screenMobile">
				<div className="fixed bottom-0 right-0 p-4">
					<button
						onClick={() => {
							setIsDarkTheme(!isDarkTheme)
						}}
						className="bg-background-elevated hover:animate-horizontal-bounce text-icon rounded-full size-14 shadow-md flex items-center justify-center"
					>
						<Sun className="hidden dark:block" />
						<Moon className="dark:hidden" />
					</button>
				</div>
				<div className="mt-[20px]">
					{isPaymentPending && is_payment_pending ? (
						<ConfirmationPending />
					) : (
						<>
							{payment ? (
								<>
									<div className="fixed bottom-0 left-0 p-4">
										<Button
											id="Spanish"
											variant="ghost"
											onClick={() => setLanguage("spanish")}
										>
											ESPAÑOL
										</Button>
										<Button
											id="Portuges"
											variant="ghost"
											onClick={() => setLanguage("portuguese")}
										>
											PORTUGUÉS
										</Button>
									</div>

									{is_payment_pending ? (
										<div>
											{payment && (
												<PaymentDescription
													language={language}
													time={new Date(payment?.creation_timestamp)}
													email={
														payment?.user_info?.fantasy_name ??
														payment?.user_info?.email
													}
													motive={payment?.motive}
													price={payment?.price}
												/>
											)}
											<Col className={`w-full z-10 gap-betweenSections`}>
												{payment && (
													<PaymentWizard
														expiration_timestamp={payment.expiration_timestamp}
														language={language} // Pass the current language value
														quotes={payment.quotes}
														paymentId={paymentId!}
														price={payment.price}
														pendingHandler={() => setIsPaymentPending(true)}
														userInfo={payment?.user_info}
														paymentOptions={payment?.payment_options}
													/>
												)}
												<Row className="gap-betweenButtons items-center ">
													{payment.tiendanube && (
														<Button
															variant="outline"
															size={"full"}
															onClick={() => {
																navigate(-1)
															}}
															className="flex items-center bg-black rounded-sm py-5 my-5"
														>
															<ArrowLeft className="size-icon mr-2.5" />
															{language === "portuguese"
																? "Retornar ao Nuvemshop"
																: "Volver a Tiendanube"}
														</Button>
													)}

													<Button
														className="bg-transparent"
														variant="outline"
														size="full"
														onClick={() => setIsPaymentPending(true)}
													>
														Ya hice el pago
													</Button>
												</Row>
											</Col>
										</div>
									) : payment &&
									  payment.payment_status &&
									  payment.payment_status === "EXPIRED" ? (
										<ExpiredPayment language={language} />
									) : (
										<>
											<Col className="gap-24">
												{payment.payment_status === "UNDERPAID" ? (
													<Col className="gap-betweenTexts">
														<Row>
															<SectionHeader
																className="w-fit"
																title="Pago incompleto"
															/>
															<CircleAlert className="ml-betweenTexts text-icon-warning" />
														</Row>
														<Heading6 className="font-medium text-text-muted">
															El pago puede demorar unos minutos en reflejarse
															en la billetera indicada
														</Heading6>
													</Col>
												) : (
													<Col className="gap-betweenTexts">
														<Row>
															<SectionHeader
																className="w-fit"
																title="Pago completo"
															/>
															<CircleCheck className="ml-betweenTexts text-icon-success" />
														</Row>
														<Heading6 className="font-medium text-text-muted">
															El pago puede demorar unos minutos en reflejarse
															en la billetera indicada
														</Heading6>
													</Col>
												)}
												<Col className="gap-betweenComponents">
													<BodyBlock isFirst={true} title="Resumen">
														<BodyItem
															name="Dirección de destino"
															value={payment.transaction_fields.address}
															copy={true}
															overflow={true}
														/>
														<BodyItem
															name="Solicitado"
															value={
																<ConvertedAmount
																	amount={payment_details.quote?.amountReadable}
																	currency={payment_details.quote?.currency}
																	amountInPreferredCurrency={
																		payment.price.amount
																	}
																	preferredCurrency={payment.price.currency}
																/>
															}
														/>
														<BodyItem
															name="Acreditado"
															value={
																<ConvertedAmount
																	amount={
																		payment_details.payment_transaction
																			?.amountReadable
																	}
																	currency={payment.transaction_fields.currency}
																	amountInPreferredCurrency={
																		payment.transaction_fields.total_paid.amount
																	}
																	preferredCurrency={
																		payment.transaction_fields.total_paid
																			.currency
																	}
																/>
															}
														/>
													</BodyBlock>
													{payment.payment_status === "UNDERPAID" ||
													payment.payment_status === "OVERPAID" ? (
														<PaymentErrorCard {...payment_details} />
													) : (
														""
													)}
												</Col>

												{/* <div className="flex">
													<PaymentPriceGigantic
														price={payment?.transaction_fields}
														confirmed
													/>
												</div> */}
												{/* {payment &&
													payment.payment_status &&
													(payment.payment_status === "UNDERPAID" ||
														payment.payment_status === "OVERPAID") && (
														<div className="flex mr-4 mb-36">
															<PaymentErrorCard {...payment_details} />
														</div>
													)} */}
												<div className="flex">
													{payment &&
														payment.redirect_url &&
														payment.redirect_url !== "" && (
															<Button
																onClick={() => {
																	window.open(payment.redirect_url, "_self")
																}}
																className="flex items-center bg-black rounded-sm py-5"
															>
																{payment.tiendanube
																	? "Volver a Tiendanube"
																	: "Volver a la página original"}
																<ArrowRight className="size-icon ml-2.5" />
															</Button>
														)}
												</div>
											</Col>
										</>
									)}
								</>
							) : isLoading ? (
								<div className="flex w-full justify-center items-center">
									<LoaderCircle className="animate-spin h-1/4 w-1/4" />
								</div>
							) : (
								<>
									<div className="content-center justify-center w-full p-[3%] md:p-[40px]">
										<div className="m-auto w-[90%] md:w-[560px]">
											{isSm && <div className="my-2" />}
											<div className="flex">
												<text className="font-bold text-[18px] leading-[22px]">
													Oops, algo salió mal...
												</text>
											</div>
											<div className="my-1" />
											<div>
												<text className="font-light text-[16px] leading-[17px] md:leading-[26px]">
													No hemos encontrado ningún pago asociado a esta
													dirección
												</text>
												<text className="font-bold text-[16px] leading-[17px] md:leading-[26px]">
													. Verificá el enlace e intentalo de nuevo.
												</text>
											</div>
											<div className="my-2" />
											<div className="m-auto items-center justify-center flex">
												<img
													width="275px"
													alt="Talo"
													src="/status_assets/timeout_payment.svg"
												/>
											</div>
										</div>
									</div>
								</>
							)}
						</>
					)}
				</div>
			</CenteredBody>
		</LandingLayout>
	)
}

export default CheckoutId
