import { zodResolver } from "@hookform/resolvers/zod"
import { useMutation } from "@tanstack/react-query"
import { ArrowRight, Loader } from "lucide-react"
import { useEffect, useState } from "react"
import { Controller, FieldError, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import { Button } from "../../../components/ui/button"
import { Form } from "../../../components/ui/form"
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from "../../../components/ui/input-otp.tsx"
import AddressAutocomplete from "../../../components/ui/inputs/AutoCompletePlacesInput.tsx"
import InputErrorMessage from "../../../components/ui/inputs/InputErrorMessage.tsx"
import { Input } from "../../../components/ui/inputs/input"
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../../../components/ui/inputs/select"
import Col from "../../../components/ui/layouts/column.tsx"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import Row from "../../../components/ui/layouts/row.tsx"
import { SectionHeader } from "../../../components/ui/section-header"
import SlidingCard from "../../../components/ui/sliding-card.tsx"
import Spacer from "../../../components/ui/spacer.tsx"
import { Heading6 } from "../../../components/ui/texts/TextStyle.tsx"
import { useAuth } from "../../../context/AuthContext.tsx"
import { apiPrivate } from "../../../services/interceptors.ts"
import {
	Address,
	BasicInfoForm,
	OnboardingPersonalData,
	OnboardingPersonalSteps,
	OnboardingPersonalWizardData,
	PersonalDocumentType,
} from "./onboard-personal-wizard-types.ts"
import { useGetKyc } from "./useGetKyc.tsx"
import {
	AnalyticsContextState,
	useAnalytics,
} from "../../../lib/analytics/AnalyticsContext.ts"


const schema = z.object({
	first_name: z
		.string()
		.min(1, "Este campo es requerido")
		.max(50, "El nombre no puede superar los 50 caracteres")
		.regex(/^[a-zA-Z\s]+$/, "Solo puede contener caracteres alfabéticos"),
	last_name: z
		.string()
		.min(1, "Este campo es requerido")
		.max(50, "El apellido no puede superar los 50 caracteres")
		.regex(/^[a-zA-Z\s]+$/, "Solo puede contener caracteres alfabéticos"),
	document_type: z.enum(["CUIT"], {
		required_error: "Se requiere ingresar el CUIT",
	}),
	cuit: z
		.string()
		.length(11, "El CUIT debe contener exactamente 11 dígitos")
		.regex(/^\d+$/, "El CUIT solo debe contener números"),
	// phone: z
	// 	.string()
	// 	.regex(/^\d{8,12}$/, "El teléfono debe tener entre 8 y 12 dígitos")
	// 	.min(8, "Este no es un número de teléfono válido")
	// 	.max(12, "Este no es un número de teléfono válido"),
	// address: z
	// 	.string()
	// 	.min(1, "Esta dirección de envío es requerida")
	// 	.max(100, "La dirección no puede superar los 100 caracteres")
	// 	.regex(/[a-zA-Z]/, "La dirección debe contener letras")
	// 	.regex(/\d+/, "La dirección debe contener números"),
	address: z.object({
		country: z
			.string({ message: "El país no es valido" })
			.min(1, "El país no es valido"),
		street: z
			.string({ message: "La calle no es valida" })
			.min(1, "La calle no es valida"),
		number: z
			.number({ message: "El número de la calle no es valido" })
			.min(1, { message: "El número de la calle no es valido" }),
		location: z
			.string({ message: "La localidad no es valida" })
			.min(1, "La localidad no es valida"),
		state: z
			.string({ message: "La provincia no es valida" })
			.min(1, "La provincia no es valida"),
		postalCode: z
			.string({ message: "El código postal no es valido" })
			.min(3, { message: "El código postal no es valido" }),
	}),
	phone: z.object({
		number: z
			.string()
			.max(8, "El numero de teléfono no puede tener mas de 8 dígitos")
			.min(6, "El numero de teléfono debe tener un mínimo de 6 dígitos"),
		codeArea: z
			.string()
			.max(4, "El código de area no puede tener mas de 4 dígitos")
			.min(2, "El código de area debe tener un mínimo de 2 dígitos"),
		countryCode: z.string(),
	}),
})

export default function FirstForm({
	setWizardData,
	wizardData,
}: {
	setWizardData: (data: OnboardingPersonalWizardData) => void
	wizardData: OnboardingPersonalWizardData
}) {
	const { user, kyc } = useAuth()
	console.log(kyc.data)
	const [code, setCode] = useState<string>("")
	const [showAddressConfirmation, setShowAddressConfirmation] =
		useState<boolean>(false)
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false)
	const [showPhoneVerification, setShowPhoneVerification] =
		useState<boolean>(false)

	const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
	const navigate = useNavigate()
	const { refetch } = useGetKyc(user?.user_id)

	const form = useForm<z.infer<typeof schema>>({
		resolver: zodResolver(schema),

		defaultValues: {
			first_name: wizardData.data.first_name || "",
			last_name: wizardData.data.last_name || "",
			cuit: wizardData.data.cuit || "",
			document_type: wizardData.data.document_type || "CUIT",
			phone: { ...wizardData.data.phone, countryCode: "+54" } || "",
			address:
				{
					...wizardData.data.address,
				} || "",
		},
	})

	const mutation = useMutation({
		mutationFn: ({
			completeData,
			userId,
		}: {
			completeData: OnboardingPersonalData
			userId: string
		}) => {
			return apiPrivate.post(`/users/${userId}/kyc`, {
				...completeData,
			})
		},
		retry: 1,
	})

	const phoneMutation = useMutation({
		mutationFn: ({ userId, code }: { userId: string; code: string }) => {
			return apiPrivate.post(`/users/${userId}/verifications`, {
				verificationCode: code,
				verificationType: "phone",
			})
		},
	})

	async function onSubmit(data: BasicInfoForm) {
		//Si el parse sobre la data no es lo que espera el schema va a throwear un error
		const parsedData = schema.parse(data)

		setWizardData({
			...wizardData,
			data: { ...wizardData.data, ...parsedData },
		})
	}

	async function postVerification(code: string) {
		if (!user || !user.user_id) {
			return
		}
		phoneMutation
			.mutateAsync({
				userId: user.user_id as string,
				code: code,
			})
			.then(() => {
				setWizardData({
					...wizardData,
					step: OnboardingPersonalSteps.ToS,
				})
				refetch()
				trackEvent(`kyc_phone_verification_ended`, {
					event_type: `Enter valid phone verification code`,
					event_status: "active",
					origin: "onboarding",
				})
			})
			.catch((err) => {
				console.error(err)
			})
	}

	async function postForm(parsedData: BasicInfoForm) {
		//parsing once again just to be sure :)
		schema.parse(parsedData)

		if (!user || !user.user_id) {
			return
		}

		const completeData = {
			...parsedData,
			entity_type: wizardData.data.entity_type,
			uif_conditions: {
				obligated_subject: wizardData.data.uif_conditions.obligated_subject,
				exposed_person: wizardData.data.uif_conditions.exposed_person,
				fatca_subject: wizardData.data.uif_conditions.fatca_subject,
				ocde_subject: wizardData.data.uif_conditions.ocde_subject,
			},
		}

		await mutation
			.mutateAsync({
				completeData: completeData,
				userId: user.user_id as string,
			})
			.then(async () => {
				setWizardData({
					...wizardData,
					data: { ...wizardData.data, ...parsedData },
					completionPercentage: 70,
				})

				refetch()
				trackEvent(`kyc_form_submitted`, {
					event_type: `Confirm submit on KYC Modal`,
					event_status: "active",
					origin: "onboarding",
				})
				trackEvent(`kyc_phone_verification_started`, {
					event_type: `Waiting for phone verification`,
					event_status: "inactive",
					origin: "onboarding",
				})
				setShowConfirmation(false)
			})
			.catch((err) => {
				console.error(err)
				trackEvent(`kyc_form_error`, {
					event_type: `Confirm submit on KYC Modal failed`,
					event_status: "inactive",
					origin: "onboarding",
				})
			})
	}

	useEffect(() => {
		if (
			kyc &&
			kyc.data &&
			kyc.data.kyc_status === "basic_info" &&
			!kyc.data.contact?.phone?.verified
		) {
			setShowPhoneVerification(true)
		}
	}, [kyc.data])

	const { trackEvent } = useAnalytics() as AnalyticsContextState

	return (
		<CenteredBody>
			<SectionHeader
				title="Configurá tu cuenta"
				subtitle="Completá tus datos personales para comenzar a usar nuestra plataforma"
			/>
			<Form {...form}>
				<form className="grid grid-cols-1 gap-betweenFields">
					<div className="md:flex-row flex flex-col w-full gap-betweenFields">
						{/* First Name */}
						<div className="w-full">
							<Input
								{...form.register("first_name")}
								placeholder="Juan"
								label="Nombre"
								required
							/>

							{form.formState.errors.first_name && (
								<InputErrorMessage
									errorText={form.formState.errors.first_name?.message}
								/>
							)}
						</div>

						{/* Last Name */}
						<div className="w-full">
							<Input
								{...form.register("last_name")}
								label="Apellido"
								required
								placeholder="Perez"
								defaultValue={wizardData.data.last_name}
							/>
							{form.formState.errors.last_name && (
								<InputErrorMessage
									errorText={form.formState.errors.last_name.message}
								/>
							)}
						</div>
					</div>

					{/* Document Type and CUIT */}
					<div>
						<Input
							label="Documento"
							required
							placeholder="20432008768"
							type="leading"
							className="rounded-l-none border-l-0"
							{...form.register("cuit")}
							defaultValue={wizardData.data.cuit}
							leading={
								<Controller
									name="document_type"
									control={form.control}
									defaultValue={"CUIT" as PersonalDocumentType}
									render={({ field }) => (
										<Select
											onValueChange={field.onChange}
											value={field.value as PersonalDocumentType}
											defaultValue={"CUIT" as PersonalDocumentType}
										>
											<SelectTrigger
												defaultValue={"CUIT" as PersonalDocumentType}
												className="w-fit rounded-e-none border-e-0"
											>
												<SelectValue
													defaultValue={"CUIT" as PersonalDocumentType}
												>
													{"CUIT" as PersonalDocumentType}
												</SelectValue>
											</SelectTrigger>
											<SelectContent className="text-center justify-center">
												<SelectItem
													className="text-center justify-center"
													value={"CUIT" as PersonalDocumentType}
												>
													CUIT
												</SelectItem>
											</SelectContent>
										</Select>
									)}
								/>
							}
						/>
						{form.formState.errors.cuit && (
							<InputErrorMessage
								errorText={form.formState.errors.cuit.message}
							/>
						)}
					</div>

					{/* Phone Number */}
					<div>
						<Input
							label="Teléfono"
							required
							autoComplete="off2"
							placeholder="6973 4982"
							type="leading"
							{...form.register("phone.number")}
							className="rounded-l-none border-l-0 input-autofill-disable [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
							leading={
								<Row>
									<div className="flex h-lg rounded-xs px-sm py-xs border border-border-input bg-background-subtle text-h5 text-text font-medium ring-offset-background cursor-not-allowed rounded-e-none">
										<span
											{...form.register("phone.countryCode")}
											defaultValue={"+54"}
											className="text-center flex items-center justify-center"
										>
											{"+54"}
										</span>
									</div>
									<Input
										{...form.register("phone.codeArea")}
										autoComplete="off1"
										type="number"
										placeholder="011"
										className="flex input-autofill-disable w-16 h-lg px-sm py-xs border border-l-0 border-border-input bg-background-subtle text-h5 text-text font-medium ring-offset-background placeholder:text-muted-foreground justify-center items-center text-center rounded-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
									></Input>
								</Row>
							}
						/>
						{form.formState.errors.phone && (
							<InputErrorMessage
								errorText={
									<ul className="flex flex-col list-outside  list-disc ml-5">
										{Object.values(form.formState.errors.phone)
											.filter(
												(error): error is FieldError =>
													typeof error !== "string"
											)
											.map((error, index) => (
												<li key={index}>{error.message as string}</li>
											))}
									</ul>
								}
							/>
						)}
					</div>

					{/* Billing Address */}
					<Col className="gap-betweenFields">
						<SlidingCard
							side="center"
							open={showAddressConfirmation}
							onOpenChange={(open) => {
								setShowAddressConfirmation(open)
								setIsConfirmed(true)
							}}
						>
							<CenteredBody>
								<SectionHeader title="Ingresá tu dirección" />

								<div className="flex flex-col gap-betweenFields">
									<Row className="gap-betweenFields w-full">
										<div className="w-full h-fit">
											<Input
												required
												{...form.register("address.street")}
												label="Calle"
												className="w-full"
											/>
										</div>
										<div className="w-fit h-fit ">
											<Controller
												name="address.number"
												control={form.control}
												render={({ field }) => (
													<Input
														required
														{...field}
														onChange={(e) => {
															var num = parseInt(e.target.value)
															if (!Number.isNaN(num)) {
																field.onChange(num)
															} else {
																field.onChange("")
															}
														}}
														label="Numero"
														type="number"
														className="w-[125px]"
													/>
												)}
											/>
										</div>
									</Row>
									<Row className="gap-betweenFields w-full">
										<div className="w-full h-fit">
											<Input
												{...form.register("address.location")}
												label="Localidad"
												required
											/>
										</div>
										<div className="w-fit h-fit ">
											<Input
												required
												{...form.register("address.postalCode")}
												label="Código Postal"
												className="w-[125px]"
											/>
										</div>
									</Row>
									<Row className="gap-betweenFields justify-between w-full">
										<Input
											label="Provincia"
											required
											{...form.register("address.state")}
										/>
										<Input
											disabled={true}
											placeholder="Argentina"
											label="País"
										/>
									</Row>
								</div>
								<Spacer value="200px" axis="vertical" />
								<Button
									onClick={() => {
										setShowAddressConfirmation(false)
										setIsConfirmed(true)
										form.setValue("address", form.getValues().address)
									}}
								>
									Confirmar
								</Button>
							</CenteredBody>
						</SlidingCard>
						<Row className="w-full gap-betweenFields items-end">
							<div className="w-full">
								<Controller
									name="address"
									control={form.control}
									render={({ field }) => (
										<AddressAutocomplete
											onSelectAddress={(addressComponets: Address) => {
												field.onChange(addressComponets) // Update the field value
												setShowAddressConfirmation(true)
											}}
											value={field.value}
											isConfirmed={isConfirmed}
											setIsConfirmed={setIsConfirmed}
											setShowAddressConfirmation={setShowAddressConfirmation}
											// Pass the value from react-hook-form to keep it in sync
										/>
									)}
								/>
							</div>
						</Row>

						{form.formState.errors.address && (
							<InputErrorMessage
								errorText={
									<ul className="flex flex-col list-outside  list-disc ml-5">
										{Object.values(form.formState.errors.address)
											.filter(
												(error): error is FieldError =>
													typeof error !== "string"
											)
											.map((error, index) => (
												<li key={index}>{error.message as string}</li>
											))}
									</ul>
								}
							/>
						)}
					</Col>

					<Spacer value="300px" axis={"vertical"} />

					<Button
						type="submit"
						onClick={async (e) => {
							await form
								.handleSubmit(onSubmit)(e)
								.then(() => {
									form.formState.isSubmitSuccessful && setShowConfirmation(true)
								})
						}}
					>
						Finalizar
					</Button>

					<SlidingCard
						side={"center"}
						open={showConfirmation}
						onOpenChange={setShowConfirmation}
					>
						<CenteredBody>
							<SectionHeader
								title="¿Estás seguro que los datos proporcionados son correctos?"
								subtitle="Asegurate de que los datos proporcionados sean correctos ya que no se podrán modificar una vez enviados. "
							/>
							<Spacer value="200px" axis={"vertical"} />
							<Row className="gap-betweenButtons">
								<Button
									size="full"
									onClick={() => {
										setWizardData({
											...wizardData,
											step: OnboardingPersonalSteps.SpecialAgreements,
										})
									}}
								>
									{" "}
									Editar datos
								</Button>
								<Button
									variant="outline"
									size="full"
									onClick={() => {
										postForm(form.getValues())
									}}
								>
									{mutation.isPending ? (
										<Loader className={"animate-spin"} />
									) : (
										"Continuar"
									)}
								</Button>
							</Row>
							{mutation.isError ? (
								<InputErrorMessage
									errorText={
										"No se pudo completar la operación, por favor intenta nuevamente"
									}
								/>
							) : null}
						</CenteredBody>
					</SlidingCard>
					<SlidingCard
						side={"center"}
						open={showPhoneVerification}
						onOpenChange={setShowPhoneVerification}
					>
						<CenteredBody>
							<SectionHeader
								title="Verificá tu teléfono"
								subtitle={
									<p>
										Ingresá el código de 4 dígitos enviado por SMS al{" "}
										<b className="text-swatch-100">{`${kyc.data?.contact?.phone?.countryCode} ${kyc.data?.contact?.phone?.codeArea} ${kyc.data?.contact?.phone?.number}`}</b>
									</p>
								}
							/>

							<Spacer value="100px" axis={"vertical"} />

							<div className="flex justify-center items-center w-full">
								<InputOTP
									maxLength={4}
									onChange={(value) => setCode(value)}
									value={code}
								>
									<InputOTPGroup>
										<InputOTPSlot index={0} />
										<InputOTPSlot index={1} />
										<InputOTPSlot index={2} />
										<InputOTPSlot index={3} />
									</InputOTPGroup>
								</InputOTP>
							</div>

							<Spacer value="100px" axis={"vertical"} />
							{code.length === 4 && (
								<Row className=" w-full justify-center">
									<Button
										size="lg"
										onClick={() => {
											postVerification(code)
										}}
										disabled={code.length !== 4}
									>
										{phoneMutation.isPending ? (
											<Loader className={"animate-spin"} />
										) : (
											<ArrowRight />
										)}
									</Button>
								</Row>
							)}
							{code?.length !== 4 && (
								<Row className=" w-full justify-center items-center">
									<Heading6 className="text-text-muted">
										¿No te llegó el código?
									</Heading6>
									<Button
										onClick={() => {
											window.location.href =
												"https://wa.me/541133711752/?text=Hola,%20no%20me%20llego%20el%20codigo%20de%20verificación."
										}}
										variant="link"
									>
										Contactanos
									</Button>
								</Row>
							)}
							{phoneMutation.isError ? (
								<InputErrorMessage
									errorText={
										"No se pudo completar la operación, por favor intenta nuevamente"
									}
								/>
							) : null}
						</CenteredBody>
					</SlidingCard>

					{mutation.isError && (
						<p className="text-text-error">
							No se pudo completar la operación, por favor intentá nuevamente
						</p>
					)}
				</form>
			</Form>
		</CenteredBody>
	)
}
