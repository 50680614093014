import { Copy } from "lucide-react"
import { Button } from "../button"
import { useToast } from "../use-toast"

const CopyButton = ({
	textToCopy,
	withToast = true,
	size = 24,
	buttonSize,
	color = "primary",
	variant = "ghost",
	label, // new prop
	disabled,
	className,
}: {
	textToCopy: string
	withToast?: boolean
	size?: number
	color?: string
	buttonSize?:
		| "sm"
		| "md"
		| "lg"
		| "xl"
		| "flex"
		| "full"
		| "icon"
		| null
		| undefined
	variant?:
		| "ghost"
		| "link"
		| "default"
		| "destructive"
		| "outline"
		| "secondary"
		| null
		| undefined
	label?: boolean // new prop
	className?: string
	disabled?: boolean
}) => {
	const { toast } = useToast()
	const copyToClipboard = () => {
		navigator.clipboard.writeText(textToCopy)
		if (withToast) {
			toast({ title: "¡Copiado!" })
		}
	}
	return (
		<Button
			variant={variant}
			size={buttonSize}
			onClick={copyToClipboard}
			className={className}
			disabled={disabled}
		>
			{label ? "Copiar" : <Copy size={size} />}
		</Button>
	)
}	

export default CopyButton
