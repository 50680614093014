import { Suspense, lazy, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import {
	QRCryptoCard,
	QRCryptoItem,
} from "../../../components/app/qrs/QRCryptoCard"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../../components/ui/section-header"
import { Skeleton } from "../../../components/ui/skeleton"
import { useAuth } from "../../../context/AuthContext"
import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import {
	AnalyticsContextState,
	useAnalytics,
} from "../../../lib/analytics/AnalyticsContext"
import { deleteQrActivePayment, getUserQr } from "../../../services/qrs"
const NewQRCryptoCard = lazy(
	() => import("../../../components/app/qrs/NewQRCryptoCard")
)

const QRCryptoBody = () => {
	const [qrList, setQrList] = useState<any>(undefined)
	const [isLoading, setIsLoading] = useState(true)
	const { user } = useAuth()
	const breakpoint = useBreakpoint() as ScreenBreakpoint
	const navigate = useNavigate()
	const { trackEvent } = useAnalytics() as AnalyticsContextState

	useEffect(() => {
		const getUserQRAsync = async (user_id: string) => {
			try {
				const qrRes = await getUserQr(user_id)
				const qrList = qrRes
				if (qrRes.length === 0) {
					navigate("/qrs/new")
				}
				setQrList(qrList)
			} catch (err) {
				console.error(err)
			} finally {
				setIsLoading(false)
			}
		}
		getUserQRAsync(user.user_id)
	}, [user.user_id])

	const handleDeleteActivePayment = async (qr: QRCryptoItem) => {
		const paymentId = qr.active_payment.id
		const qrInfo = await deleteQrActivePayment(qr.id, paymentId)
		if (qrInfo && !qrInfo.active_payment) {
			trackEvent("cancel_qr_payment", {
				event_type: "cancel_qr_payment",
				user_id: user?.user_id,
				team_id: user?.team_id,
				qr_id: qr.id,
				payment_id: paymentId,
				payment_cancelled_at: new Date().toISOString(),
			})
		}
		window.location.reload()
	}

	const gridColumns = {
		sm: "grid-cols-1",
		md: "grid-cols-1",
		lg: "grid-cols-2",
		xl: "grid-cols-2",
	}
	return (
		<CenteredBody>
			<SectionHeader
				title="Administrá los QRs físicos de tu negocio"
				subtitle="Podrás administrar y cargar montos a los QR asociados a tu cuenta"
			/>
			{isLoading ? (
				<div className={`grid ${gridColumns[breakpoint]} gap-betweenCards`}>
					<Skeleton className="h-[130px] w-full" />
					<Skeleton className="h-[130px] w-full " />
					<Skeleton className="h-[130px] w-full " />
					<Skeleton className="h-[130px] w-full " />
					<Skeleton className="h-[130px] w-full " />
				</div>
			) : (
				<div className={`grid ${gridColumns[breakpoint]} gap-betweenCards`}>
					{qrList &&
						qrList.length > 0 &&
						qrList.map((qr: any) => (
							<QRCryptoCard
								key={qr.id}
								qr={qr}
								handleDeletePayment={() => {
									handleDeleteActivePayment(qr)
								}}
								handleNewPayment={() => {
									navigate(`/charge?type=qr&qr_id=${qr.id}`)
								}}
							/>
						))}
					<Suspense fallback={<Skeleton className="h-[130px] w-full" />}>
						<NewQRCryptoCard handleNewQRCrypto={() => navigate("/qrs/new")} />
					</Suspense>
				</div>
			)}
		</CenteredBody>
	)
}

export default QRCryptoBody
