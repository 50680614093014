import { publicMenuItems } from "../../../../pages/app/items"
import BottomNavigationBar from "../../menus/bottom-bar/BottomNavigationBar"
import Spacer from "../../spacer"
import Col from "../column"
import { mobilePlatformBody } from "../layout-sizes"
import MobilePlatformHeader from "./MobilePlatformHeader"
import SimpleMobileHeader from "./SimpleMobileHeader"
import { MobilePlatformProps } from "./platform-types"

const navigationBarItems = publicMenuItems.filter((item) => item.icon)
const MobilePlatformLayout = ({
	children,
	headerContent,
	activeTab,
	variant = "default",
	headerTitle,
}: MobilePlatformProps) => {
	return (
		<div className="h-[100svh]  overscroll-contain">
			{variant === "bigHeader" ? (
				<MobilePlatformHeader>{headerContent}</MobilePlatformHeader>
			) : (
				<SimpleMobileHeader title={headerTitle!} backLink="true" />
			)}
			<Spacer axis="vertical" type="belowSectionHeader"></Spacer>
			<Col className={`${mobilePlatformBody[variant].height} px-screenMobile`}>
				{children}
			</Col>
			<BottomNavigationBar items={navigationBarItems} activeTab={activeTab} />
		</div>
	)
}

export default MobilePlatformLayout
