import { ColumnDef, Row } from "@tanstack/react-table"
import { ArrowDownRight, ArrowUpLeft, Check, CircleX } from "lucide-react"
import PendingPaymentStatus from "../../../ui/status-icons/PendingPaymentStatus"
import PendingWithdrawStatus from "../../../ui/status-icons/PendingWithdrawStatus"
import RejectedWithdrawStatus from "../../../ui/status-icons/RejectedWithdrawStatus"
import { Heading6 } from "../../../ui/texts/TextStyle"

import { TableMovement } from "../data"

export const movementTranscationStatus = [
	"INBOUND",
	"OUTBOUND",
	"pending",
	"processed",
	"rejected",
	"PENDING",
	"PROCESSED",
	"REJECTED",
]

export type movementTranscationStatus =
	(typeof movementTranscationStatus)[number]

export function TypeColumn(): ColumnDef<TableMovement> {
	return {
		accessorKey: "status",
		header: () => (
			<div className="text-left text-h6 items-center flex flex-row gap-2">
				<Check className="size-icon" />
				Movimiento
			</div>
		),
		filterFn: (row: Row<TableMovement>, id: string, value: any) => {
			return value.includes(row.getValue(id))
		},
		cell: ({ row }: { row: Row<TableMovement> }) => {
			const type = row.getValue(
				"status"
			) as (typeof movementTranscationStatus)[number]

			return <TypeCell type={type} />
		},
	}
}

export const statusIcons: {
	[key: movementTranscationStatus]: JSX.Element
} = {
	SUCCESS: <ArrowDownRight className="w-7 h-7 stroke-[#1FC801]" />,
	INBOUND: <ArrowDownRight className="w-7 h-7 stroke-[#1FC801]" />,
	PROCESSED: <ArrowDownRight className="w-7 h-7 stroke-[#1FC801]" />,
	OVERPAID: <ArrowDownRight className="w-7 h-7 stroke-icon-warning" />,
	UNDERPAID: <ArrowDownRight className="w-7 h-7 stroke-icon-warning" />,
	UNMATCHED: <ArrowDownRight className="w-7 h-7 stroke-icon-warning" />,

	processed: <ArrowUpLeft className="w-7 h-7 stroke-icon-error" />,
	SENT: <ArrowUpLeft className="w-7 h-7 stroke-icon-error" />,

	OUTBOUND: <ArrowUpLeft className="w-7 h-7 stroke-icon-error" />,

	processing: <PendingWithdrawStatus className="w-7 h-7 stroke-icon-muted" />,
	pending: <PendingWithdrawStatus className="w-7 h-7" />,

	PENDING: <PendingPaymentStatus className="w-7 h-7" />,

	EXPIRED: <CircleX className="w-7 h-7  stroke-icon-muted" />,

	rejected: <RejectedWithdrawStatus />,
	reversed: <RejectedWithdrawStatus />,
}

export const statusTranslations: {
	[key: movementTranscationStatus]: string
} = {
	INBOUND: "Entrante",
	OUTBOUND: "Saliente",
	pending: "Saliente",
	processed: "Saliente",
	rejected: "Saliente",
	PENDING: "Entrante",
	PROCESSED: "Entrante",
	REJECTED: "Entrante",
	UNMATCHED: "Entrante",
}

export const statusDescriptions: {
	[key: movementTranscationStatus]: string
} = {
	INBOUND: "Se recibió el dinero correctamente",
	OUTBOUND: "El retiro se completó en su totalidad",
	pending: "El retiro está pendiente de confirmación",
	processed: "El retiro se completó en su totalidad",
	PROCESSED: "El retiro se completó en su totalidad",
	rejected: "Ocurrió un error en el retiro",
	PENDING: "Entrante",
	REJECTED: "Ocurrió un error en el retiro",
	UNMATCHED: "Se recibió el dinero, pero no identificamos la orden. ",
}

export function TypeCell({
	type,
}: {
	type: (typeof movementTranscationStatus)[number]
}) {
	return (
		<div className="flex items-center w-fit ">
			{statusIcons[type]}
			<Heading6 className="capitalize ml-2 font-medium font-h6 group-hover:translate-x-1 transition duration-400">
				{statusTranslations[type]}
			</Heading6>
		</div>
	)
}
