import { ReactNode } from "react"
import { Link } from "react-router-dom"
import { cn } from "../../../lib/utils"
import { Button } from "../button"
import Col from "../layouts/column"

export type LabelButtonProps = {
	label: string
	subtitle?: ReactNode
	onClick?: () => void
	icon: React.ReactNode
	className?: string
	size?: "sm" | "md" | "lg" | "xl" | "xxl" | "full" | "icon"
	disabled?: boolean
	variant:
		| "default"
		| "destructive"
		| "outline"
		| "secondary"
		| "ghost"
		| "link"
	link?: string
	url?: string
}

const LabelButton = ({
	label,
	onClick,
	icon,
	variant,
	subtitle,
	className,
	size,
	link,
	url,
	disabled = false,
	...props
}: LabelButtonProps) => {
	const sizes = {
		sm: "!size-s44",
		md: "!size-s80",
		lg: "!size-s100",
		xl: "!size-s120",
		xxl: "!size-s175",
		full: "!size-full",
		icon: "",
	}
	var body: ReactNode
	if (link) {
		body = (
			<Link to={link}>
				<LabelButtonStructure label={label} icon={icon} />
			</Link>
		)
	} else if (url) {
		body = (
			<a href={url} target="_blank" rel="noreferrer">
				<LabelButtonStructure label={label} icon={icon} />
			</a>
		)
	} else {
		body = (
			<LabelButtonStructure label={label} icon={icon} subtitle={subtitle} />
		)
	}
	return (
		<Button
			variant={variant}
			className={cn(
				size ? sizes[size] : "",
				className,
				"hover:border-background-invers hover:bg-background"
			)}
			disabled={disabled}
			{...props}
			onClick={onClick}
			asChild={link || url ? true : false}
		>
			{body}
		</Button>
	)
}

const LabelButtonStructure = ({
	label,
	icon,
	subtitle,
}: {
	label: string
	icon: ReactNode
	subtitle?: ReactNode
}) => {
	return (
		<Col className="gap-4 justify-center items-center p-2">
			<div className="group-hover:animate-[wiggle_1s_ease-in-out_infinite]">
				{icon}
			</div>
			<div className="flex flex-col gap-2">
				{label}
				{subtitle}
			</div>
		</Col>
	)
}

export default LabelButton
