import { ColumnDef, Row } from "@tanstack/react-table"
import TRow from "../../../ui/layouts/row"
import { Heading4, Heading5 } from "../../../ui/texts/TextStyle"
import { Price, TableTransaction } from "../columns"

export function CreditedMoneyColumn(props?: {
    enableDateFilter?: boolean,
    filterFn?: (row: Row<TableTransaction>, id: string, value: any) => boolean
}): ColumnDef<TableTransaction> {
    return {
        id: 'creationDateTime', // Change this to match what toolbar is looking for
        accessorFn: (row) => row.creationDateTime, // Direct access to creationDateTime
        header: () => <div className="text-end">Acreditado</div>,
        enableColumnFilter: props?.enableDateFilter,
        filterFn: props?.filterFn,
        cell: ({ row }: { row: Row<TableTransaction> }) => {
            const received = row.original.received as Price
            const dateTime = row.original.creationDateTime as {
                date: string
                time: string
            }
            if (received) {
                return (
                    <div className={"grid flex-col gap-1 justify-end w-full "}>
                        <TRow className=" gap-1 justify-end">
                            <Heading4 className="font-semibold text-end">
                                {received?.amount}
                            </Heading4>
                            <Heading5 className="font-light text-end">
                                {received?.currency}
                            </Heading5>
                        </TRow>

                        <div className="flex flex-row gap-betweenTexts text-b1 text-text-muted text-nowrap justify-end">
                            {dateTime?.date === "Hoy" ? (
                                <div>{dateTime?.time}</div>
                            ) : (
                                <div>{dateTime?.date}</div>
                            )}
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="h-4 w-full rounded-xs bg-background-elevated"></div>
                )
            }
        },
    }
}
