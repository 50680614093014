import { ReactNode } from "react"
import { useNavigate } from "react-router-dom"
import { Option } from "../../ui/options/option"

export type SettingsItem = {
	icon: ReactNode
	title: string
	description?: string
	subleading?: string
	link: string
	iconInside?: boolean
	disabled?: boolean
	analytics?: () => void
}

export const SettingsItemRow = ({
	icon,
	title,
	description,
	subleading,
	link,
	iconInside = true,
	disabled,
	analytics,
}: SettingsItem) => {
	const navigate = useNavigate()
	return (
		<Option
			title={title}
			description={description}
			subleading={subleading}
			leading="arrow"
			icon={icon}
			iconInside={iconInside}
			disabled={disabled}
			onClick={() => {
				if (link.startsWith("http")) {
					window.open(link, "_blank")
				} else {
					navigate(link)
				}
				if (analytics) {
					analytics()
				}
			}}
		/>
	)
}
