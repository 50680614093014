import { ColumnDef, Row } from "@tanstack/react-table"
import { TableTransaction } from "../columns"
import { UserIcon, Landmark } from "lucide-react"
import { TableMovement } from "../data"
import {
	findDisplayNameForBankInJson,
	findImgForBankInJson,
} from "../../../../lib/utils"
import bank_list from "../../../../lib/psp_bank_id.json"

export function SenderOrRecieverCell<
	T extends TableTransaction | TableMovement
>({ header }: { header: string }): ColumnDef<T> {
	return {
		id: "Titular",
		size: 270,
		enableHiding: true,
		enableResizing: true,
		accessorFn: (row) => {
			const status = row.status as string
			return status === "INBOUND"
				? row.sender_titular
				: row.recipient_account?.name
		},
		header: () => (
			<div className="text-left text-h6 items-center flex flex-row gap-2">
				<UserIcon className="size-icon" />
				{header}
			</div>
		),
		cell: ({ row }: { row: Row<T> }) => {
			try {
				const status = row.original.status as string
				const titular =
					status === "INBOUND"
						? (row.original.sender_titular as string)
						: (row.original.recipient_account?.name as string)

				const bankOrPspId =
					status === "INBOUND"
						? row.original.psp_id || row.original.bank_id
						: row.original.recipient_account?.psp_id ||
						  row.original.recipient_account?.bank_id

				if (!titular || titular === "") {
					return (
						<div className="justify-end h-4 w-full rounded-xs bg-background-elevated"></div>
					)
				} else {
					if (!bankOrPspId) {
						return (
							<div className="flex items-center w-full gap-2">
								<div className="text-h6 font-medium w-[75%] overflow-hidden overflow-ellipsis text-nowrap">
									{titular}
								</div>
							</div>
						)
					}

					const imgSrc = findImgForBankInJson(bankOrPspId, bank_list)
					const displayName = findDisplayNameForBankInJson(
						bankOrPspId,
						bank_list
					)

					return (
						<div className="flex items-center w-full gap-2">
							<div className="text-h6 font-medium w-[75%] overflow-hidden overflow-ellipsis text-nowrap">
								{titular}
							</div>
							{bankOrPspId && (
								<div className="w-[25%] flex justify-end">
									{imgSrc ? (
										<img
											className="size-icon rounded-full"
											src={imgSrc}
											alt={displayName || "Bank icon"}
											onError={(e) => {
												e.currentTarget.style.display = "none"
											}}
										/>
									) : (
										<Landmark className="size-icon text-gray-400" />
									)}
								</div>
							)}
						</div>
					)
				}
			} catch (error) {
				return null
			}
		},
	}
}
