import {
	CancelationReason,
	ConfirmationReason,
	OrderActionType,
} from "../../../hooks/mutateOrderAction"

import { formatEther } from "ethers"
import { Store } from "../../../hooks/useFetchStores"
import {
	getPaymentAddress,
	getPaymentAddressArs,
	getPaymentAlias,
	getPaymentComission,
	getPaymentCommissionArs,
	getPaymentCreditedAmount,
	getPaymentCreditedAmountArs,
	getPaymentCurrency,
	getPaymentExpectedAmount,
	getPaymentNetwork,
	getPaymentReceivedAmount,
	getPaymentReceivedAmountArs,
	getPaymentReceivedAmountInRequestedCurrency,
	getPaymentSenderAddress,
	getPaymentSenderCuit,
	getPaymentSenderTitular,
	getPspOrBankId,
	getWithdrawnAmount,
	getWithdrawnAmountArs,
} from "../../../lib/utils"
import { Payment } from "../../../pages/checkout/id"
import { formatDate } from "../../../utils/format"
import { formatPriceAmount } from "../../../utils/numbers"
import { TableTransaction } from "./columns"

export type TableMovementType = "INBOUND" | "OUTBOUND"

export const tableMovementStatus = ["PROCESSED", "PENDING", "REJECTED"]

// Modify the TableMovement type to be more specific
export type TableMovement = Omit<
	TableTransaction,
	| "transactions"
	| "status"
	| "tiendanube"
	| "woocommerce"
	| "shopify"
	| "paymentUrl"
	| "expiration_timestamp"
	| "id"
> & {
	id?: string
	type: TableMovementType
	senderAddress?: string
	senderCuit?: string
	status: (typeof tableMovementStatus)[number]
	payment_id?: string
	amount: string
}

export function mapCombinedPaymentsToTableTransaction({
	payments,
	transactions,
	stores,
}: {
	payments: Payment[] | undefined
	transactions: any
	stores: Store[]
}): TableTransaction[] {
	if (transactions && payments) {
		// Remove the separation of pending and other payments
		const validPayments = payments

		const txList = transactions || []
		const outboundTransactions = txList.filter(
			(transaction: any) =>
				transaction.transactionType === "OUTBOUND" ||
				transaction.type === "OUTBOUND" ||
				transaction.transactionType === "SENT" ||
				transaction.type === "SENT"
		)

		// Merge all payments and sort by date
		const allTransactions = [...validPayments, ...outboundTransactions].sort(
			(a, b) => {
				return (
					new Date(b.creation_timestamp).getTime() -
					new Date(a.creation_timestamp).getTime()
				)
			}
		)

		var tableTransactions = allTransactions.map((payment) =>
			mapToTableTransaction(payment, stores)
		)
		return tableTransactions
	}
	return []
}

function isTransferPayment(payment: any): boolean {
	return (
		(payment.payment_options && payment.payment_options.includes("transfer")) ||
		(payment.currency &&
			payment.network &&
			payment.currency === "ARS" &&
			payment.network === "POLLUX")
	)
}

export function mapToTableSubTransaction(transaction: any): any {
	const percent = transaction.commission / 1.21
	const commision_amount =
		transaction.commission_amount ??
		parseFloat(transaction.amountReadable) * (percent / 100)
	const credited_amount = transaction.amountReadable - commision_amount
	return {
		network: transaction.network,
		currency: transaction.currency,
		amount: transaction.amount,
		amountReadable: transaction.amountReadable,
		received: {
			amount: transaction.amountReadable,
			currency: transaction.currency,
		},
		credited: {
			amount: credited_amount,
			currency: transaction.currency,
		},
		commission: {
			comission: {
				amount: commision_amount,
				currency: transaction.currency,
			},
			percent: percent,
		},
		transactionHash: transaction.transaction_hash,
		transactionId: transaction.transaction_id,
		senderAddress: transaction.sender_address,
		date: formatDate(new Date(transaction.creation_timestamp)),
		time: new Date(transaction.creation_timestamp)
			.toLocaleTimeString("es-AR", {
				hour: "2-digit",
				minute: "2-digit",
				hour12: true,
			})
			.replace("a. m.", "AM")
			.replace("p. m.", "PM"),
	}
}

export function mapToTableSubTransactionArs(transaction: any): any {
	let pspOrBank = getPspOrBankId(transaction.sender_address)
	const percent =
		((parseFloat(transaction.commission_amount) /
			parseFloat(transaction.amount)) *
			100) /
		1.21
	return {
		network: transaction.network,
		currency: transaction.currency,
		amount: transaction.amount,
		amountReadable: transaction.amountReadable,
		received: {
			amount: transaction.amount,
			currency: transaction.currency,
		},
		credited: {
			amount: transaction.credited_amount,
			currency: transaction.currency,
		},
		commission: {
			comission: {
				amount: transaction.commission_amount,
				currency: transaction.currency,
			},
			comissionInRequestedCurrency: {
				amount: transaction.commission_amount,
				currency: transaction.currency,
			},
			percent: percent,
		},
		transactionId: transaction.transaction_id,
		senderAddress: transaction.sender_address,
		senderCuit: transaction.transaction_data?.PROCESSED?.senderCuit ?? "-",
		sender_titular:
			transaction.transaction_data?.PROCESSED?.senderTitular ?? "-",
		senderIsPsp: pspOrBank[1] === "psp_id" ? true : false,
		psp_id: pspOrBank[1] === "psp_id" ? pspOrBank[0] : undefined,
		bank_id: pspOrBank[1] === "bank_id" ? pspOrBank[0] : undefined,
		date: formatDate(new Date(transaction.creation_timestamp)),
		time: new Date(transaction.creation_timestamp)
			.toLocaleTimeString("es-AR", {
				hour: "2-digit",
				minute: "2-digit",
				hour12: true,
			})
			.replace("a. m.", "AM")
			.replace("p. m.", "PM"),
	}
}

function getStoreDetails(
	payment: any,
	stores: Store[]
): { store_id: string | number | undefined; store_name: string | undefined } {
	try {
		const store_id = getStoreId(payment)
		const store = stores.find(
			(store) => String(store.store_id) === String(store_id)
		)
		return {
			store_id,
			store_name: store ? store.store_name : undefined,
		}
	} catch (error) {
		console.error("Error getting store details:", error)
		return {
			store_id: undefined,
			store_name: undefined,
		}
	}
}

export function storeContainsAction(data: any) {
	if (data.confirm_payload) {
		return {
			type: "confirm" as OrderActionType,
			reason: data.confirm_payload.reason as ConfirmationReason,
		}
	}

	if (data.cancel_payload) {
		return {
			type: "cancel" as OrderActionType,
			reason: data.cancel_payload.reason as CancelationReason,
		}
	}

	return undefined
}

export function getOrderStatus(
	payment: TableTransaction | undefined
):
	| { type: OrderActionType; reason: ConfirmationReason | CancelationReason }
	| undefined {
	var store = getStoreObject(payment)
	if (store?.store_data) {
		var action = storeContainsAction(store.store_data)
		if (action) {
			return action
		}
	}
	return undefined
}

export function mapToTableTransactionArs(
	payment: any,
	stores: Store[]
): TableTransaction {
	try {
		const lastModifiedDateTime = getFormattedDateTime(
			payment.last_modified_timestamp
		)
		const creationDateTime = getFormattedDateTime(payment.creation_timestamp)

		if (payment.payment_status) {
			const received = getPaymentReceivedAmountArs(payment)
			const receivedInRequestedCurrency = getPaymentReceivedAmountArs(payment)
			const comission = getPaymentCommissionArs(payment)
			const credited = getPaymentCreditedAmountArs(payment)
			const creditedInRequestedCurrency = getPaymentCreditedAmountArs(payment)
			return {
				id: payment.id,
				lastModifiedDateTime: lastModifiedDateTime,
				creationDateTime: creationDateTime,
				orderId:
					(
						payment.tiendanube?.order_number ??
						payment.tiendanube?.order_id ??
						payment.woocommerce?.order_id ??
						payment?.shopify?.order_name ??
						payment?.shopify?.order_id
					)?.toString() ?? "-",
				currency: getPaymentCurrency(payment) ?? "-",
				expected: payment.price,
				received: received,
				receivedInRequestedCurrency: receivedInRequestedCurrency,
				credited: credited,
				creditedInRequestedCurrency: creditedInRequestedCurrency,
				status: payment.payment_status ?? "-",
				price: payment.price,
				comission: comission,
				network: getPaymentNetwork(payment),
				address: getPaymentAddressArs(payment),
				sender: getPaymentSenderAddress(payment),
				sender_cuit: getPaymentSenderCuit(payment),
				sender_titular: getPaymentSenderTitular(payment),
				alias: getPaymentAlias(payment),
				transactions: payment.transactions?.map(mapToTableSubTransactionArs),
				payment_options: payment.payment_options ?? undefined,
				tiendanube: payment.tiendanube,
				woocommerce: payment.woocommerce,
				shopify: payment.shopify,
				paymentUrl: payment.payment_url,
				psp_id: payment.psp_id ?? undefined,
				bank_id: payment.bank_id ?? undefined,
				expiration_timestamp: {
					date: formatDate(new Date(payment.expiration_timestamp)),
					time: new Date(payment.expiration_timestamp)
						.toLocaleTimeString("es-AR", {
							hour: "2-digit",
							minute: "2-digit",
							hour12: true,
						})
						.replace("a. m.", "AM")
						.replace("p. m.", "PM"),
				},
				...getStoreDetails(payment, stores),
			}
		} else {
			const withdrawnAmount = getWithdrawnAmountArs(payment)
			if (payment.amount) {
				return {
					id: payment.transaction_id ?? "-",
					lastModifiedDateTime: lastModifiedDateTime,
					creationDateTime: creationDateTime,
					orderId: "-",
					currency: payment.currency ?? "-",
					network: payment.network,
					address: payment.address,
					credited: withdrawnAmount,
					received: withdrawnAmount,
					expected: withdrawnAmount,
					status: payment?.transaction_status?.toLowerCase() ?? "SENT",
					transactionHash: payment.transaction_hash,
					psp_id: payment?.recipient_account?.psp_id ?? undefined,
					bank_id: payment?.recipient_account?.bank_id ?? undefined,
					recipient_account: payment?.recipient_account ?? undefined,
					...getStoreDetails(payment, stores),
				}
			} else {
				console.error(payment)
				throw new Error("Invalid transaction")
			}
		}
	} catch (err) {
		console.error("Error in mapToTableTransactionArs", err)
		return payment
	}
}
export function mapToTableTransaction(
	payment: any,
	stores: Store[]
): TableTransaction {
	if (isTransferPayment(payment)) {
		return mapToTableTransactionArs(payment, stores)
	} else {
		return mapToTableTransactionCrypto(payment, stores)
	}
}

export function mapToTableTransactionCrypto(
	payment: any,
	stores: Store[]
): TableTransaction {
	const lastModifiedDateTime = getFormattedDateTime(
		payment.last_modified_timestamp
	)
	const creationDateTime = getFormattedDateTime(payment.creation_timestamp)

	if (payment.payment_status) {
		const quote = payment.quotes!.find(
			(q: any) =>
				q.currency === payment.transaction_fields?.currency &&
				q.network === payment.transaction_fields?.network
		)
		const received = getPaymentReceivedAmount(payment)
		const receivedInRequestedCurrency = quote
			? getPaymentReceivedAmountInRequestedCurrency(payment, quote)
			: undefined
		const comission = quote ? getPaymentComission(payment, quote) : undefined
		const credited = getPaymentCreditedAmount(received, comission?.comission)
		const creditedInRequestedCurrency = getPaymentCreditedAmount(
			receivedInRequestedCurrency,
			comission?.comissionInRequestedCurrency
		)
		return {
			id: payment.id,
			lastModifiedDateTime: lastModifiedDateTime,
			creationDateTime: creationDateTime,
			orderId:
				(
					payment.tiendanube?.order_id ??
					payment.woocommerce?.order_id ??
					payment.customer_email
				)?.toString() ?? "-",
			currency: getPaymentCurrency(payment) ?? "-",
			expected: getPaymentExpectedAmount(payment, quote),
			received: received,
			receivedInRequestedCurrency: receivedInRequestedCurrency,
			credited: credited,
			creditedInRequestedCurrency: creditedInRequestedCurrency,
			status: payment.payment_status ?? "-",
			price: payment.price,
			comission: comission,
			network: getPaymentNetwork(payment),
			address: getPaymentAddress(payment),
			transactions: payment.transactions?.map(mapToTableSubTransaction),
			transactionHash: payment.transaction_fields?.transaction_hash,
			tiendanube: payment.tiendanube,
			woocommerce: payment.woocommerce,
			paymentUrl: payment.payment_url,
			payment_options: payment.payment_options ?? undefined,
			...getStoreDetails(payment, stores),
		}
	} else {
		const withdrawnAmount = getWithdrawnAmount(payment)
		if (payment.amount) {
			return {
				id: payment.transaction_id ?? "-",
				lastModifiedDateTime: lastModifiedDateTime,
				creationDateTime: creationDateTime,
				orderId: "-",
				currency: payment.currency ?? "-",
				network: payment.network,
				address: payment.address,
				credited: withdrawnAmount,
				received: withdrawnAmount,
				expected: withdrawnAmount,
				status: "SENT",
				transactionHash: payment.transaction_hash,
				...getStoreDetails(payment, stores),
			}
		} else {
			console.error(payment)
			throw new Error("Invalid transaction")
		}
	}
}

// Update the getFormattedDateTime function
function getFormattedDateTime(timestamp: string) {
	try {
		const dateTime = new Date(timestamp)
		return {
			date: formatDate(dateTime),
			time: dateTime
				.toLocaleTimeString("es-AR", {
					hour: "2-digit",
					minute: "2-digit",
					hour12: true,
				})
				.replace("a. m.", "AM")
				.replace("p. m.", "PM"),
		}
	} catch (error) {
		console.error("Error formatting datetime:", error)
		return {
			date: formatDate(new Date()),
			time: new Date()
				.toLocaleTimeString("es-AR", {
					hour: "2-digit",
					minute: "2-digit",
					hour12: true,
				})
				.replace("a. m.", "AM")
				.replace("p. m.", "PM"),
		}
	}
}

function getStoreId(payment: any): string | number | undefined {
	switch (true) {
		case !!payment.tiendanube:
			return payment.tiendanube.store_id
		case !!payment.woocommerce:
			return payment.woocommerce.store_id
		case !!payment.shopify:
			return payment.shopify.store_id
		default:
			// Search all nested objects for store_id
			for (const key in payment) {
				if (
					payment[key] &&
					typeof payment[key] === "object" &&
					payment[key].store_id
				) {
					return payment[key].store_id
				}
			}
			return undefined
	}
}

export function getStoreObject(payment: any):
	| {
			store_id?: string | number
			store_data?:
				| typeof payment.tiendanube
				| typeof payment.woocommerce
				| typeof payment.shopify
	  }
	| undefined {
	if (payment.tiendanube) {
		return {
			store_id: payment.tiendanube.store_id,
			store_data: payment.tiendanube,
		}
	}
	if (payment.woocommerce) {
		return {
			store_id: payment.woocommerce.store_id,
			store_data: payment.woocommerce,
		}
	}
	if (payment.shopify) {
		return {
			store_id: payment.shopify.store_id,
			store_data: payment.shopify,
		}
	}

	// Search all nested objects for store_id
	for (const key in payment) {
		if (
			payment[key] &&
			typeof payment[key] === "object" &&
			payment[key].store_id
		) {
			return {
				store_id: payment[key].store_id,
				store_data: payment[key],
			}
		}
	}
	return undefined
}

function getTransactionAmount(transaction: any): number {
	// Check if it's a transfer transaction (ARS/POLLUX)
	if (transaction.currency === "ARS" && transaction.network === "POLLUX") {
		// For transfer transactions
		if (transaction.transactionType === "INBOUND") {
			// Use gross_amount for inbound transfers if available
			return Math.abs(Number(transaction.gross_amount ?? transaction.amount))
		} else {
			// For outbound transfers, use the absolute amount
			return Math.abs(Number(transaction.amount))
		}
	} else {
		// For crypto transactions
		// Use the same logic as in mapToTableTransactionCrypto
		const amount = transaction.amount
		if (transaction.transactionType === "OUTBOUND") {
			return Math.abs(Number(amount))
		}
		return Number(amount)
	}
}

function convertToInboundOrOutboundStatus(
	status: string,
	type: TableMovementType
): TableMovement["status"] {
	if (type === "INBOUND") {
		return status.toUpperCase()
	} else {
		return status.toLowerCase()
	}
}

function mapTransactionsToMovements(
	tx: any,
	payments: Payment[],
	stores: Store[]
): TableMovement | undefined {
	var credited
	var sender
	var sender_cuit
	var sender_titular
	var amount

	//Si es crypto la parseamos distinto
	if (!isTransferPayment(tx)) {
		try {
			amount = formatPriceAmount(formatEther(tx.amount), undefined, tx.currency)
			credited = {
				amount: amount ?? 0,
				currency: tx.currency,
			}
		} catch (error) {
			console.error()
		}
	} else if (tx.transaction_data && !tx.transaction_data.PROCESSED) {
		credited = { amount: tx.amount, currency: tx.currency }
		sender_cuit = undefined
	} else {
		amount = tx.gross_amount
		credited = {
			amount: tx.amount,
			currency: tx.currency,
		}
		sender_cuit = tx.transaction_data.PROCESSED.senderCuit
		sender = tx.transaction_data.PROCESSED.sender
		sender_titular = tx.transaction_data.PROCESSED.senderTitular
	}

	try {
		var type = getTransactionType(tx) ?? "INBOUND"

		var status = tx.transaction_status
		if (status) {
			status = convertToInboundOrOutboundStatus(status, type)
		} else if (type === "OUTBOUND") {
			status = "processed"
		} else {
			status = "PROCESSED"
		}

		//Si se proceso y no tiene payment_id es porque no se asigno a ninguna orden
		if (status === "PROCESSED" && !tx.payment_id) {
			status = "UNMATCHED"
		}

		var orderId = "-"

		return {
			id: tx.id ?? undefined,
			type,
			status: status,
			currency: tx.currency || "ARS",
			network: tx.network || "POLLUX",
			creationDateTime: getFormattedDateTime(tx.creation_timestamp),
			lastModifiedDateTime: getFormattedDateTime(tx.last_modified_timestamp),
			orderId: tx.orderId || orderId,
			senderAddress: tx.sender_address,
			amount: amount,
			received: { amount: amount, currency: tx.currency },
			//esto se lo pongo porque el accesorKey en la table es expected pero en realidad deberia ser amount
			expected: credited,
			credited,
			comission: {
				comission: {
					amount: tx.commission_amount,
					currency: tx.currency,
				},
				percent: tx.commission / 1.21,
			},
			creditedInRequestedCurrency: credited,
			address: tx.address || tx.recipient_address,
			transactionHash: tx.transaction_hash,
			senderCuit: sender_cuit,
			sender: sender,
			sender_titular: sender_titular,
			sender_cuit,
			payment_options: tx.payment_options,
			payment_id: tx.payment_id,
		}
	} catch (error) {
		console.error("Error mapping transaction:", error)
		console.error("Problematic transaction:", JSON.stringify(tx, null, 2))
		return undefined
	}
}

export function mapMovementsDataToTableMovements({
	payments,
	transactions,
	stores,
}: {
	payments: Payment[] | undefined
	transactions: any[]
	stores: Store[]
}): TableMovement[] {
	if (!payments || !transactions) {
		return []
	}

	const tableTransactions = mapCombinedPaymentsToTableTransaction({
		payments,
		transactions,
		stores,
	})

	const noIdTransactions =
		transactions
			?.filter(
				(tx) =>
					!tx.payment_id &&
					getTransactionType(tx) !== "OUTBOUND" &&
					tx.transaction_status === "PROCESSED"
			)
			.map((tx) => mapTransactionsToMovements(tx, payments, stores))
			.filter(
				(movement): movement is TableMovement => movement !== undefined
			) || []

	// Combine and transform all transactions before sorting
	const allMovements = [
		...noIdTransactions,
		...tableTransactions
			.filter((tx) => tx.status !== "EXPIRED" && tx.status !== "PENDING")
			.flatMap(splitTransactionIntoMovements),
	]
	// Sort all movements together by creationDateTime
	var res = allMovements.sort(
		(a, b) =>
			new Date(
				b.creationDateTime.date + " " + b.creationDateTime.time
			).getTime() -
			new Date(
				a.creationDateTime.date + " " + a.creationDateTime.time
			).getTime()
	)

	return res
}

// In the splitTransactionIntoMovements function, modify the creationDateTime assignment
function splitTransactionIntoMovements(
	transaction: TableTransaction
): TableMovement[] {
	if (!transaction.transactions || transaction.transactions.length === 0) {
		const type: TableMovementType = getTransactionType(transaction)
		const status = convertStatusToMovements(transaction.status)
		const currency =
			transaction.currency && transaction.currency !== "-"
				? transaction.currency
				: "ARS"
		return [
			{
				...transaction,
				amount: transaction.received!.amount.toString(),
				type: type,
				senderAddress: transaction.sender || undefined,
				sender_titular: transaction.sender_titular || undefined, // Keep sender_titular from parent transaction
				status,
				currency,
				network: transaction.network || "POLLUX",
				receivedInRequestedCurrency:
					transaction.receivedInRequestedCurrency || undefined,
				credited: transaction.credited || undefined,
				creditedInRequestedCurrency:
					transaction.creditedInRequestedCurrency || undefined,
				received: transaction.received || undefined,
				expected: transaction.expected || undefined,
				comission: transaction.comission || undefined,
				sender: transaction.sender || undefined,
				payment_options: transaction.payment_options || undefined,
			},
		]
	}

	// Map each sub-transaction to a TableMovement
	return transaction.transactions.map((subTx) => {
		const type: TableMovementType = getTransactionType(subTx)
		const status = convertStatusToMovements(transaction.status)
		const currency =
			subTx.currency && subTx.currency !== "-" ? subTx.currency : "ARS"

		return {
			...subTx,
			amount: (subTx.network === "POLLUX"
				? subTx.amount
				: subTx.amountReadable) as string,
			id: `${transaction.id}-${
				subTx.transactionId || subTx.transactionHash?.slice(0, 8)
			}`,
			type,
			lastModifiedDateTime: {
				date: subTx.date,
				time: subTx.time.replace("a. m.", "AM").replace("p. m.", "PM"),
			},
			creationDateTime: {
				date: subTx.date,
				time: subTx.time.replace("a. m.", "AM").replace("p. m.", "PM"),
			},
			orderId: transaction.orderId || "-",
			currency,
			network: subTx.network || transaction.network || "POLLUX",
			status,
			received: subTx.received,
			credited: subTx.credited,
			receivedInRequestedCurrency: subTx.receivedInRequestedCurrency,
			creditedInRequestedCurrency: subTx.creditedInRequestedCurrency,
			expected: transaction.expected,
			store_id: transaction.store_id,
			store_name: transaction.store_name,
			sender: subTx.senderAddress || transaction.sender,
			sender_titular: subTx.senderTitular || transaction.sender_titular,
			senderCuit: subTx.senderCuit || transaction.sender_cuit,
			recipient_titular: transaction.recipient_account?.name,
			transactionHash: subTx.transactionHash,
			address: transaction.address,
			price: transaction.price,
			comission: subTx.commission,
			buyer: transaction.buyer,
			payment_options: transaction.payment_options,
		}
	})
}

function getTransactionType(transaction: any) {
	if (transaction.transactionType) {
		return transaction.transactionType
	} else if (transaction.transaction_type) {
		return transaction.transaction_type
	} else if (transaction.type) {
		return transaction.type
	} else {
		return undefined
	}
}

function convertStatusToMovements(status: string): TableMovement["status"] {
	switch (status) {
		// Uppercase inbound transactions
		case "SUCCESS":
		case "UNDERPAID":
		case "OVERPAID":
		case "INBOUND":
			return "INBOUND"

		// Lowercase pending (outbound pending)
		case "pending":
		case "processing":
			return "pending"

		// Uppercase pending (inbound pending)
		case "PENDING":
		case "PROCESSING":
			return "PENDING"

		// Lowercase processed (outbound completed)
		case "processed":
		case "SENT":
		case "outbound":
			return "processed"

		// Uppercase processed (inbound completed)
		case "PROCESSED":
		case "SENT":
		case "OUTBOUND":
			return "PROCESSED"

		// Lowercase rejected (outbound rejected)
		case "rejected":
		case "reversed":
			return "rejected"

		// Uppercase rejected (inbound rejected)
		case "REJECTED":
		case "REVERSED":
			return "REJECTED"

		default:
			console.warn(`Unknown status: ${status}`)
			return "pending"
	}
}

export function setNewOrderStatus(
	payment: TableTransaction,
	status: {
		type: OrderActionType
		reason: ConfirmationReason | CancelationReason
	}
): TableTransaction {
	const store = getStoreObject(payment)
	if (!store?.store_data) {
		return payment
	}

	const updatedPayment = { ...payment }
	const payload =
		status.type === "confirm"
			? { confirm_payload: { reason: status.reason } }
			: { cancel_payload: { reason: status.reason } }

	if (updatedPayment.tiendanube) {
		updatedPayment.tiendanube = { ...updatedPayment.tiendanube, ...payload }
	} else if (updatedPayment.woocommerce) {
		updatedPayment.woocommerce = { ...updatedPayment.woocommerce, ...payload }
	} else if (updatedPayment.shopify) {
		updatedPayment.shopify = { ...updatedPayment.shopify, ...payload }
	}

	return updatedPayment
}
