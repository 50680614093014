import { RectangleEllipsis } from "lucide-react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../../components/ui/alert"
import { Button } from "../../../components/ui/button"
import FeatureLayout from "../../../components/ui/layouts/platform/FeatureLayout"
import CenteredBody from "../../../components/ui/layouts/platform/body/CenteredBody"
import { useAuth } from "../../../context/AuthContext"
import { SettingsSectionItem } from "../../settings/items"
import { CryptoCover } from "../../settings/payment-methods/PaymentsCover"
import PluginsSection from "./PluginSection"

import Col from "../../../components/ui/layouts/column"
import { Option } from "../../../components/ui/options/option"
import { Section } from "../../../components/ui/section"
import { redirectToEndOfOnboarding } from "../../../lib/utils"
import {
	StoreType,
	updateUser as serviceUpdateUser,
} from "../../../services/users"
import { CryptoAllLogo } from "../../../utils/withdrawCurrencies"

export interface Plugin {
	title: string
	icon: React.ReactNode
}

const integrationsAvailable: { [key in StoreType]: Plugin } = {
	tiendanube: {
		title: "Tiendanube + Talo Pay",
		icon: <img src="/dashboard/plugins/tiendanube.svg" className="size-full" />,
	},
	woocommerce: {
		title: "Woocommerce + Talo Pay",
		icon: (
			<img src="/dashboard/plugins/woocommerce.svg" className="size-full" />
		),
	},
	shopify: {
		title: "Shopify + Talo Pay",
		icon: <img src="/dashboard/plugins/shopify.svg" className="size-full" />,
	},
}

const CryptoBody = ({
	subSections,
}: {
	subSections: SettingsSectionItem[]
}) => {
	const navigate = useNavigate()
	const { user, stores, updateUser } = useAuth()
	const [isTiendanubeInstalled, setIsTiendanubeInstalled] = useState(false)
	const [isWooCommerceInstalled, setIsWooCommerceInstalled] = useState(false)
	const [cryptoPaymentsEnabled, setCryptoPaymentsEnabled] = useState(
		user?.crypto_payments_enabled !== false ? true : false
	)
	const handleSwitchChange = async (checked: boolean) => {
		setCryptoPaymentsEnabled(checked)
		const res = await serviceUpdateUser(user.id, {
			crypto_payments_enabled: checked,
		})
		if (res && res.status >= 200 && res.status < 300) {
			updateUser(res.data.data)
		}
	}
	useEffect(() => {
		if (stores) {
			if (process.env.REACT_APP_TIENDANUBE_APP_ID === undefined) {
				return
			}

			const isTiendanubeStore = stores.some(
				(store: any) =>
					store.store_type === StoreType.TIENDANUBE &&
					store.store_apps.find(
						(app: any) =>
							app.app_id === process.env.REACT_APP_TIENDANUBE_APP_ID ||
							app.app_id === process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID
					)
			)

			if (isTiendanubeStore) {
				setIsTiendanubeInstalled(true)
			}

			const isWooCommerceStore = stores.some(
				(store: any) => store.store_type === StoreType.WOOCOMMERCE
			)

			if (isWooCommerceStore) {
				setIsWooCommerceInstalled(true)
			}
		}
	}, [stores])

	return (
		<CenteredBody bottom>
			<FeatureLayout
				coverImage={<CryptoCover />}
				sectionHeaderProps={{
					title: "Pagos en criptomonedas",
					subtitle:
						"Acepta criptomonedas desde cualquier billetera y expande te a nuevos clientes. Conectá tu cuenta de Talo a tu tienda online para agregar el botón de pagos y aceptar pagos de todo el mundo.",
				}}
			>
				<Option
					title="Criptomonedas"
					description=""
					icon={<CryptoAllLogo />}
					iconInside={false}
					leading="switch"
					switchChecked={cryptoPaymentsEnabled}
					onCheckedChange={handleSwitchChange}
				/>
				<Alert
					variant="link"
					icon={<RectangleEllipsis />}
					title={"Recibir monedas digitales"}
					description={
						"El dinero se acreditara en monedas digitales. Esta se podrá retirar a cualquier billetera en la red de BSC. Para mas información visitar la documentación."
					}
					buttonText="Abrir documentación web"
					buttonLink="https://docs.talo.com.ar/withdrawals/introduction"
				/>
				{!isTiendanubeInstalled && !isWooCommerceInstalled ? (
					<PluginsSection subSections={subSections} />
				) : null}
				{isTiendanubeInstalled || isWooCommerceInstalled ? (
					<>
						<Section
							title={
								<Col className="gap-betweenTexts inline">
									<p>Integraciones activas </p>

									<p className="text-text-muted text-h6">
										Crypto y BRL comparten la integración
									</p>
								</Col>
							}
							footer="Para agregar nueva integraciones. Probá desvinculando la activa."
						>
							{isTiendanubeInstalled && (
								<Option
									key={integrationsAvailable.tiendanube.title}
									title={integrationsAvailable.tiendanube.title}
									description="Activa"
									icon={integrationsAvailable.tiendanube.icon}
									leading="div"
									div={null}
									iconInside={false}
									className="bg-background description:text-text-success"
								/>
							)}
							{isWooCommerceInstalled && (
								<Option
									key={integrationsAvailable.woocommerce.title}
									title={integrationsAvailable.woocommerce.title}
									description="Activa"
									icon={integrationsAvailable.woocommerce.icon}
									leading="div"
									div={null}
									iconInside={false}
									className="bg-background description:text-text-success"
								/>
							)}
						</Section>
						<Button
							onClick={() => {
								redirectToEndOfOnboarding(navigate, user)
							}}
						>
							Finalizar
						</Button>
					</>
				) : null}
			</FeatureLayout>
		</CenteredBody>
	)
}

export default CryptoBody
