import { useState } from "react"
import { useFlags } from "../../../context/FlagsContext"
import { Quote } from "../../../pages/checkout/id"
import { currencies } from "../../../utils/currencies"
import { networks } from "../../../utils/networks"
import { WalletButton } from "../../../utils/wallets"
import CenteredBody from "../../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../../ui/section-header"
import { useToast } from "../../ui/use-toast"
import CryptoModalBottomSheet from "./CryptoModalBottomSheet"
import PaymentQuoteCard from "./PaymentQuoteCard"
import QRModalMobile from "./QRModalMobile"
import PaymentCurrency from "./paymentCurrency"
import PaymentWallet from "./paymentWallet"

const CryptoPayment = ({
	quote,
	quotes,
	wallet,
	handlers,
}: {
	quote: Quote | undefined
	quotes: Quote[]
	wallet: WalletButton | undefined
	handlers: {
		handleSelectCurrency: (currency: string) => void
		handleGoBack: () => void
		handleWalletClick: (wallet: WalletButton) => void
		pendingHandler: () => void
	}
}) => {
	const [isModalOpen, setIsModalOpen] = useState(true)
	const [step, setStep] = useState(0)
	const [qrVisible, setQrVisible] = useState<boolean>(false)
	const { getFlag } = useFlags()
	const { toast } = useToast()

	const handleQRModal = () => {
		setQrVisible(true)
	}

	const handleSelectCurrency = (currency: string) => {
		handlers.handleSelectCurrency(currency)
		setStep(1)
		setIsModalOpen(false)
	}

	const handleGoBack = () => {
		handlers.handleGoBack()
		if (step > 0) {
			setStep(step - 1)
		}
	}
	const handleWalletClick = (wallet: WalletButton) => {
		handlers.handleWalletClick(wallet)
		setStep(2)
		setIsModalOpen(false)
	}

	const selectCurrency = (
		<PaymentCurrency
			chargeCurrencies={quotes.map((item: Quote) => item.currency)}
			handleSelectCurrency={handleSelectCurrency}
		/>
	)

	const selectWallet = (
		<PaymentWallet
			handleWalletClick={handleWalletClick}
			handleGoBack={handleGoBack}
		/>
	)

	var currentStep
	switch (step) {
		case 0:
			currentStep = selectCurrency
			break
		case 1:
			if (getFlag("choose_wallet_step").asBoolean() || true) {
				currentStep = selectWallet
			} else {
				currentStep = <></>
			}
			break
		// case 2:
		// 	currentStep = paymentInfo
		// 	break
		default:
			currentStep = <></>
			break
	}

	return (
		<CenteredBody>
			<CryptoModalBottomSheet
				body={currentStep}
				isModalOpen={isModalOpen}
				onClose={() => setIsModalOpen(false)}
			/>
			{quote && (
				<QRModalMobile
					isModalOpen={qrVisible}
					onClose={() => setQrVisible(false)}
					address={quote!.address}
				/>
			)}

			<div className="flex flex-col gap-7 mt-4">
				<SectionHeader
					title={"Datos del pago"}
					subtitle="Ingresá estos datos en tu billetera de preferencia."
				></SectionHeader>

				<div className="w-full">
					<div className="grid grid-cols-2 gap-2">
						<div className="flex col-span-1">
							<PaymentQuoteCard
								headline="Moneda"
								size="lg"
								status="enabled"
								item={
									quote
										? {
												logo: `/currencies/${quote.currency.toLowerCase()}-icon.svg`,
												title: currencies[quote.currency].name,
										  }
										: undefined
								}
								updateHandler={() => {
									setStep(0)
									setIsModalOpen(true)
								}}
							/>
						</div>
						<div className=" flex col-span-1">
							<PaymentQuoteCard
								warning="Mandarlo por la red equivocada podrá resultar en la pérdida del
							dinero."
								headline="Red"
								size="lg"
								status="enabled"
								item={
									quote
										? {
												logo: networks[quote.network].logoPath,
												title: networks[quote.network].name,
										  }
										: undefined
								}
							/>
						</div>
						<div className="flex col-span-1">
							<PaymentQuoteCard
								headline="Monto"
								size="sm"
								status="enabled"
								item={{
									title: quote
										? `${quote?.amountReadable}${quote?.currency}`
										: "Seleccionar moneda",
								}}
								copyHandler={() => {
									navigator.clipboard.writeText(quote!.amountReadable!)
									toast({ title: "¡Copiado!" })
								}}
							/>
						</div>
						<div className="flex col-span-1">
							<PaymentQuoteCard
								headline="Dirección"
								size="sm"
								status="enabled"
								item={{ title: quote?.address || "" }}
								copyHandler={() => {
									navigator.clipboard.writeText(quote!.address!)
									toast({ title: "¡Copiado!" })
								}}
							/>
						</div>
					</div>
				</div>

				{quote && (
					<div className="mt-8 flex">
						También podés pagar apuntando desde la cámara del móvil al{" "}
						<button
							className="underline font-bold text-text bg-transparent mx-1 hover:cursor-pointer p-0 m-0  block justify-start border-none" //underline  "
							onClick={handleQRModal}
						>
							código QR
						</button>
					</div>
				)}
			</div>
		</CenteredBody>
	)
}

export default CryptoPayment
