import { CodeXml } from "lucide-react"
import { Route } from "react-router-dom"
import ProtectedRoute from "../../components/routes/ProtectedRoute"
import PlatformHeader from "../../components/ui/layouts/platform/PlatformHeader"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { PrivateRole, PublicRole } from "../../lib/roles"
import CryptoBody from "../onboarding/payment-methods/CryptoBody"
import PixBody from "../onboarding/payment-methods/PixBody"
import TransferBody from "../onboarding/payment-methods/TransferBody"
import {
	SettingsSectionItem,
	mapSettingsItemToRouteItem,
} from "../settings/items"
import ShopifyBody from "../settings/plugins/ShopifyBody"
import TiendanubeBody from "../settings/plugins/TiendanubeCryptoBody"
import TiendanubeCvuBody from "../settings/plugins/TiendanubeCvuBody"
import WoocommerceBody from "../settings/plugins/WoocommerceBody"
import { shopifySubsections } from "../settings/plugins/items"
import OnboardingBody from "./OnboardingBody"
import PaymentMethodsBody from "./PaymentMethodsBody"
import PaymentMethodsWrapper from "./PaymentMethodsWrapper"
import VerifyingBody from "./Success"
import BusinessBody from "./business/BusinessBody"
import ApiBodyWrapper from "./payment-methods/ApiBodyWrapper"
import PersonalWizard from "./personal/PersonalWizard"

export type OnboardingItem = {
	itemLink: string
	itemName: string
	minimumPublicRole: PublicRole
	minimumPrivateRole: PrivateRole
	bodyComponent: JSX.Element
	smBodyComponent: JSX.Element
	subSections?: OnboardingItem[]
	hasBackButton?: boolean
	featureFlag?: string
}

const onBoardingSubSections: OnboardingItem[] = [
	{
		itemLink: "/onboarding/personal",
		itemName: "Personal",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <PersonalWizard />,
		smBodyComponent: <PersonalWizard />,
	},
	{
		itemLink: "/onboarding/business",
		itemName: "Empresa",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <BusinessBody />,
		smBodyComponent: <BusinessBody />,
	},
	{
		itemLink: "/onboarding/success",
		itemName: "Success",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <VerifyingBody />,
		smBodyComponent: <VerifyingBody />,
	},
]

const onBoardingItems: OnboardingItem[] = [
	{
		itemName: "Cuenta",
		itemLink: "/onboarding",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <OnboardingBody />,
		smBodyComponent: <OnboardingBody />,
		subSections: onBoardingSubSections,
	},
]

const transferPluginSubSections: SettingsSectionItem[] = [
	{
		title: "Integraciones disponibles",
		description: "",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/onboarding/payment-methods/transfer/plugins/${process.env.REACT_APP_TIENDANUBE_CVU_APP_ID}`,
				bodyComponent: <TiendanubeCvuBody />,
				smBodyComponent: <TiendanubeCvuBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				paymentMethod: "transfer",
			},
			{
				title: "Shopify",
				icon: <img src="/dashboard/plugins/shopify.svg" alt="shopify" />,
				link: "/onboarding/payment-methods/transfer/plugins/shopify",
				bodyComponent: <ShopifyBody />,
				smBodyComponent: <ShopifyBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				featureFlag: "show_sh_cvu",
				subItems: shopifySubsections("onboarding"),
				paymentMethod: "transfer",
			},
			{
				title: "API",
				icon: <CodeXml />,
				link: "/onboarding/payment-methods/transfer/plugins/api",
				bodyComponent: <ApiBodyWrapper />,
				smBodyComponent: <ApiBodyWrapper />,
				iconInside: true,
				minimumPublicRole: PublicRole.OWNER,
				paymentMethod: "transfer",
			},
		],
	},
]

const cryptoPluginSubSections: SettingsSectionItem[] = [
	{
		title: "Integraciones Disponibles",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			// {
			// 	title: "Tiendanube",
			// 	icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
			// 	link: `/onboarding/payment-methods/crypto/plugins/${process.env.REACT_APP_TIENDANUBE_APP_ID}`,
			// 	bodyComponent: (
			// 		<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
			// 	),
			// 	smBodyComponent: (
			// 		<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
			// 	),
			// 	iconInside: false,
			// 	minimumPublicRole: PublicRole.OWNER,
			// 	featureFlag: "show_tn_legacy",
			// },
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/onboarding/payment-methods/crypto/plugins/${process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				smBodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				featureFlag: "show_tn_homo",
				paymentMethod: "crypto",
			},
			{
				title: "WooCommerce",
				icon: (
					<img src="/dashboard/plugins/woocommerce.svg" alt="woocommerce" />
				),
				link: "/onboarding/payment-methods/crypto/plugins/woocomerce",
				bodyComponent: <WoocommerceBody />,
				smBodyComponent: <WoocommerceBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				paymentMethod: "crypto",
			},
			{
				title: "API",
				icon: <CodeXml />,
				link: "/onboarding/payment-methods/crypto/plugins/api",
				bodyComponent: <ApiBodyWrapper />,
				smBodyComponent: <ApiBodyWrapper />,
				iconInside: true,
				minimumPublicRole: PublicRole.OWNER,
				paymentMethod: "crypto",
			},
		],
	},
]

const pixPluginSubSections: SettingsSectionItem[] = [
	{
		title: "Integraciones disponibles",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		subItems: [
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/onboarding/payment-methods/pix/plugins/${process.env.REACT_APP_TIENDANUBE_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
				),
				smBodyComponent: (
					<TiendanubeBody appId={process.env.REACT_APP_TIENDANUBE_APP_ID} />
				),
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				featureFlag: "show_tn_legacy",
			},
			{
				title: "Tiendanube",
				icon: <img src="/dashboard/plugins/tiendanube.svg" alt="tiendanube" />,
				link: `/onboarding/payment-methods/pix/plugins/${process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}`,
				bodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				smBodyComponent: (
					<TiendanubeBody
						appId={process.env.REACT_APP_TIENDANUBE_HOMO_APP_ID}
					/>
				),
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				featureFlag: "show_tn_homo",
				paymentMethod: "pix",
			},
			{
				title: "WooCommerce",
				icon: (
					<img src="/dashboard/plugins/woocommerce.svg" alt="woocommerce" />
				),
				link: "/onboarding/payment-methods/pix/plugins/woocomerce",
				bodyComponent: <WoocommerceBody />,
				smBodyComponent: <WoocommerceBody />,
				iconInside: false,
				minimumPublicRole: PublicRole.OWNER,
				paymentMethod: "pix",
			},
			{
				title: "API",
				icon: <CodeXml />,
				link: "/onboarding/payment-methods/pix/plugins/api",
				bodyComponent: <ApiBodyWrapper />,
				smBodyComponent: <ApiBodyWrapper />,
				iconInside: true,
				minimumPublicRole: PublicRole.OWNER,
				paymentMethod: "pix",
			},
		],
	},
]

const onBoardingPaymentMethodsSubSections: OnboardingItem[] = [
	{
		itemLink: "/onboarding/payment-methods/transfer",
		itemName: "Transferencias",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <TransferBody subSections={transferPluginSubSections} />,
		smBodyComponent: <TransferBody subSections={transferPluginSubSections} />,
	},
	{
		itemLink: "/onboarding/payment-methods/pix",
		itemName: "Reales",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <PixBody subSections={pixPluginSubSections} />,
		smBodyComponent: <PixBody subSections={pixPluginSubSections} />,
	},
	{
		itemLink: "/onboarding/payment-methods/crypto",
		itemName: "Crypto",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <CryptoBody subSections={cryptoPluginSubSections} />,
		smBodyComponent: <CryptoBody subSections={cryptoPluginSubSections} />,
	},
]

const mappedTransferPluginSubSections = transferPluginSubSections.flatMap(
	(item) => item.subItems.map(mapSettingsItemToRouteItem)
)
const mappedTransferPluginSubSubSections = transferPluginSubSections
	.flatMap((item) => item.subItems)
	.flatMap((subItem) => subItem.subItems?.map(mapSettingsItemToRouteItem) ?? [])

const mappedCryptoPluginSubSections = cryptoPluginSubSections.flatMap((item) =>
	item.subItems.map(mapSettingsItemToRouteItem)
)

const mappedPixPluginSubSections = pixPluginSubSections.flatMap((item) =>
	item.subItems.map(mapSettingsItemToRouteItem)
)

// const paymentMethodIntegrations: SettingsRouteItem[] = [
// 	{
// 		itemName: "Integraciones",
// 		itemLink: "/onboarding/payment-methods/transfer/plugins",
// 		minimumPublicRole: PublicRole.OWNER,
// 		minimumPrivateRole: PrivateRole.JEDI,
// 		bodyComponent: <PluginsBody subSections={transferPluginSubSections} />,
// 		smBodyComponent: <PluginsBody subSections={transferPluginSubSections} />,
// 	},
// 	{
// 		itemName: "Integraciones",
// 		itemLink: "/onboarding/payment-methods/pix/plugins",
// 		minimumPublicRole: PublicRole.OWNER,
// 		minimumPrivateRole: PrivateRole.JEDI,
// 		bodyComponent: <PluginsBody subSections={pixPluginSubSections} />,
// 		smBodyComponent: <PluginsBody subSections={pixPluginSubSections} />,
// 	},
// 	{
// 		itemName: "Integraciones",
// 		itemLink: "/onboarding/payment-methods/crypto/plugins",
// 		minimumPublicRole: PublicRole.OWNER,
// 		minimumPrivateRole: PrivateRole.JEDI,
// 		bodyComponent: <PluginsBody subSections={cryptoPluginSubSections} />,
// 		smBodyComponent: <PluginsBody subSections={cryptoPluginSubSections} />,
// 	},
// ]

const paymentMethodIntegrationsRoutes = mappedCryptoPluginSubSections
	.concat(
		...mappedTransferPluginSubSections,
		...mappedPixPluginSubSections,
		...mappedTransferPluginSubSubSections
	)
	.map((item) => (
		<Route
			key={item.itemLink}
			path={item.itemLink}
			element={
				<ProtectedRoute
					requiresKyc={false}
					minimumPrivateRole={item.minimumPrivateRole}
					minimumPublicRole={item.minimumPublicRole}
				>
					<PlatformHeader
						title={item.itemName}
						variant="secondary"
					></PlatformHeader>
					<CenteredBody bottom className="px-screenMobile pt-4">
						<PaymentMethodsWrapper progressPercentage={20}>
							{item.bodyComponent}
						</PaymentMethodsWrapper>
					</CenteredBody>
				</ProtectedRoute>
			}
		/>
	))

export const OnboardingItems = onBoardingItems.concat(onBoardingSubSections)

export const onboardingRoutes = OnboardingItems.map((item) => (
	<Route
		key={item.itemLink}
		path={item.itemLink}
		element={
			<ProtectedRoute
				requiresVerification={true}
				requiresKyc={false}
				minimumPrivateRole={item.minimumPrivateRole}
				minimumPublicRole={item.minimumPublicRole}
			>
				{item.bodyComponent}
			</ProtectedRoute>
		}
	/>
))

const onBoardingPaymentMethodsItems: OnboardingItem[] = [
	{
		itemName: "Metodos de Pago",
		itemLink: "/onboarding/payment-methods",
		minimumPublicRole: PublicRole.OWNER,
		minimumPrivateRole: PrivateRole.JEDI,
		bodyComponent: <PaymentMethodsBody />,
		smBodyComponent: <PaymentMethodsBody />,
		subSections: onBoardingPaymentMethodsSubSections,
		hasBackButton: false,
	},
]

export const allOnboardingPaymentMethodsItems =
	onBoardingPaymentMethodsItems.concat(onBoardingPaymentMethodsSubSections)

export const onBoardingPaymentMethodsRoutes =
	allOnboardingPaymentMethodsItems.map((item) => (
		<Route
			key={item.itemLink}
			path={item.itemLink}
			element={
				<ProtectedRoute
					requiresKyc={false}
					minimumPrivateRole={item.minimumPrivateRole}
					minimumPublicRole={item.minimumPublicRole}
				>
					<PlatformHeader
						title={item.itemName}
						variant="secondary"
						backButton={item.hasBackButton}
					/>
					<CenteredBody bottom className="px-screenMobile pt-4">
						<PaymentMethodsWrapper progressPercentage={2}>
							{item.bodyComponent}
						</PaymentMethodsWrapper>
					</CenteredBody>
				</ProtectedRoute>
			}
		/>
	))

export const allOnboardingRoutes = onboardingRoutes
	.concat(onBoardingPaymentMethodsRoutes)
	.concat(paymentMethodIntegrationsRoutes)
