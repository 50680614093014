import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useInterval } from "../../hooks/useInterval"
import { Charge, ChargeType } from "../../pages/charge/charge-wizard-types"
import { getPayment } from "../../services/payments"
import PaymentModal from "../modals/payment-modal/PaymentModal"
import { mapToTableTransaction } from "../tables/transactions-table/data"
import { QRItem } from "../ui/inputs/QRSelector"
import ShareLinkChargeBody from "./ShareLinkChargeBody"
import ShareQRChargeBody from "./ShareQRChargeBody"
const PAYMENT_POLLING_INTERVAL_SECONDS = 10
function getModalStateFromStatus(status: string) {
	console.log(status)
	switch (status) {
		case "SUCCESS":
		case "EXPIRED":
		case "OVERPAID":
		case "UNDERPAID":
			console.log("RETORNA TRUE")
			return true
		case "PENDING":
		default:
			return false
	}
}
const ShareChargeBody = ({
	type,
	payment,
	qr,
	onCancel,
	onPaymentStatusChange,
	loading,
}: {
	type: ChargeType
	payment: Charge
	qr?: QRItem
	onCancel: () => void
	onPaymentStatusChange: (charge: Charge) => void
	loading?: boolean
}) => {
	const { stores } = useAuth()
	const [isModalOpen, setIsModalOpen] = useState(
		getModalStateFromStatus(payment.payment_status)
	)
	const navigate = useNavigate()
	const { fetchBalance } = useAuth()

	const handleGoBack = () => {
		fetchBalance()
		setIsModalOpen(false)
		navigate("/")
	}

	useInterval(
		async () => {
			const res = await getPayment(payment.id)
			if (res && res.payment_status !== "PENDING") {
				onPaymentStatusChange(res as unknown as Charge)
			}
		},
		getModalStateFromStatus(payment.payment_status)
			? null
			: PAYMENT_POLLING_INTERVAL_SECONDS * 1000
	)
	useEffect(() => {
		setIsModalOpen(getModalStateFromStatus(payment.payment_status))
	}, [payment.payment_status])
	var body
	switch (type) {
		case ChargeType.QR:
			body = (
				<ShareQRChargeBody
					payment={payment}
					onCancel={onCancel}
					qr={qr}
					loading={loading}
				/>
			)
			break
		case ChargeType.LINK:
			body = <ShareLinkChargeBody payment={payment} onCancel={onCancel} />
			break
	}

	return (
		<>
			<PaymentModal
				open={isModalOpen}
				payment={mapToTableTransaction(payment, stores)}
				onClose={() => handleGoBack()}
			/>
			{body}
		</>
	)
}

export default ShareChargeBody
