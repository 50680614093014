import {
	ColumnFiltersState,
	getCoreRowModel,
	getFilteredRowModel,
	Row,
	Table,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table"
import { memo, useEffect, useMemo, useRef, useState } from "react"
import { useAuth } from "../../../context/AuthContext"
import useBreakpoint, { ScreenBreakpoint } from "../../../hooks/useBreakpoint"
import { useCombinedPayments } from "../../../hooks/usePayments"
import PaymentModal from "../../modals/payment-modal/PaymentModal"
import TransactionModal from "../../modals/transaction-modal/TransactionModal"
import { DataTable } from "../../ui/data-table/data-table"
import DataTableToolbar from "../../ui/data-table/data-table-toolbar"
import { getColumns, TableTransaction } from "./columns"
import { tableTransactionCurrencies } from "./columns/currencyImageCell"
import { getMovementsColumns } from "./columns/movementsColumns"
import { tableTransactionStatus } from "./columns/statusCell"
import {
	mapCombinedPaymentsToTableTransaction,
	mapMovementsDataToTableMovements,
	TableMovement,
} from "./data"

export const ROW_HEIGHT = 70
export const rowHeightClassName = "h-[70px]"
export type TableView = "orders" | "movements"

// Memoized table components
interface TableViewProps<T> {
	isLoading: boolean
	viewOptions: boolean
	bordered: boolean
	items: T[]
	visibleRowCount: number
	columns: any[]
	onRowSelected: (row: T) => void
	columnVisibility: Record<string, boolean>
}

// Define TableView as a generic functional component without memo
function TableView<T>({
	isLoading,
	viewOptions,
	bordered,
	items,
	visibleRowCount,
	columns,
	onRowSelected,
	columnVisibility, // Add this prop
}: TableViewProps<T>) {
	return (
		<DataTable
			loading={isLoading}
			viewOptions={viewOptions}
			rowHeightClass={rowHeightClassName}
			columns={columns}
			data={items.slice(0, visibleRowCount)}
			variant={bordered ? "bordered" : "default"}
			onRowSelected={onRowSelected}
			columnVisibility={columnVisibility} // Pass it to DataTable
		/>
	)
}

// Create typed components for each data type and wrap them with memo
const TableTransactionView = memo(
	(props: TableViewProps<TableTransaction>) => <TableView {...props} />,
	(prevProps, nextProps) =>
		prevProps.items === nextProps.items &&
		prevProps.visibleRowCount === nextProps.visibleRowCount &&
		prevProps.columnVisibility === nextProps.columnVisibility
)

const TableMovementView = memo(
	(props: TableViewProps<TableMovement>) => <TableView {...props} />,
	(prevProps, nextProps) =>
		prevProps.items === nextProps.items &&
		prevProps.visibleRowCount === nextProps.visibleRowCount &&
		prevProps.columnVisibility === nextProps.columnVisibility
)

const TxTable = ({
	showFilters = false,
	scrollable = false,
	viewOptions = false,
	bordered = false,
	defaultView = "orders",
	onCloseModal,
	transaction_id,
	payment_id,
}: {
	viewOptions?: boolean
	showFilters?: boolean
	scrollable?: boolean
	bordered?: boolean
	defaultView?: TableView
	onCloseModal?: () => void
	transaction_id?: string
	payment_id?: string
}) => {
	const tableContainerRef = useRef<HTMLDivElement>(null)
	const { user, stores } = useAuth()
	const [visibleRowCount, setVisibleRowCount] = useState(0)
	const [selectedPayment, setSelectedPayment] = useState<
		TableTransaction | TableMovement | null
	>(null)
	const [modalOpen, setModalOpen] = useState(!!transaction_id)
	const [tableView, setTableView] = useState<TableView>(defaultView)
	const {
		data: combinedPayments,
		isLoading,
		dataUpdatedAt,
	} = useCombinedPayments(
		user.id,
		user.roles.includes("pos") ? user.team_id : undefined
	)

	const [storeFilter, setStoreFilter] = useState<string[]>([])
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
		store_name: false,
	})

	// Add a Map to track optimistic updates by payment ID
	const [updatedPayments, setUpdatedPayments] = useState<
		Map<string, TableTransaction>
	>(new Map())

	// Modify the payments memo to include optimistic updates
	const payments = useMemo(() => {
		const basePayments = mapCombinedPaymentsToTableTransaction({
			payments: combinedPayments?.payments,
			transactions: combinedPayments?.transactions,
			stores,
		})

		// Apply any optimistic updates from the Map
		return basePayments.map(
			(payment) => updatedPayments.get(payment.id) || payment
		)
	}, [combinedPayments, stores, updatedPayments])

	// Handle updates per payment ID
	const handlePaymentUpdate = (updatedPayment: TableTransaction) => {
		console.log("Payment updated:", updatedPayment.id, updatedPayment)
		setUpdatedPayments((prev) => {
			const next = new Map(prev)
			next.set(updatedPayment.id, updatedPayment)
			return next
		})
	}

	// Clear updates when refetching data
	useEffect(() => {
		if (!isLoading) {
			setUpdatedPayments(new Map())
		}
	}, [dataUpdatedAt])

	// Handle modal state
	const [isClosing, setIsClosing] = useState(false)

	const handleModalOpen = (payment: TableTransaction) => {
		setSelectedPayment(payment)
		setModalOpen(true)
	}

	const handleModalClose = () => {
		setIsClosing(true)
		// Wait for animation to complete before fully closing
		setTimeout(() => {
			setIsClosing(false)
			setModalOpen(false)
			setSelectedPayment(null)
			onCloseModal?.()
		}, 300) // Match this with your animation duration
	}

	// Excluyo Todas las OUTBOUND de Transactions
	const filteredPayments = useMemo(
		() =>
			payments.filter(
				(payment) =>
					!["SENT", "OUTBOUND", "processed", "pending", "rejected"].includes(
						payment.status
					)
			),
		[payments]
	)

	const movementsData = useMemo(
		() =>
			mapMovementsDataToTableMovements({
				payments: combinedPayments?.payments,
				transactions: combinedPayments?.transactions,
				stores,
			}),
		[combinedPayments, stores]
	)

	const [filteredOrders, setFilteredOrders] =
		useState<TableTransaction[]>(payments)
	const [filteredMovements, setFilteredMovements] =
		useState<TableMovement[]>(movementsData)

	useEffect(() => {
		if (payments && transaction_id) {
			const selected = payments.find(
				(item: any) => `TX#${transaction_id}` === item.id
			)
			if (selected) {
				setSelectedPayment(selected)
				setModalOpen(true)
			}
		}
		if (payments && payment_id) {
			const selected = payments.find((item: any) => payment_id === item.id)
			if (selected) {
				setSelectedPayment(selected)
				setModalOpen(true)
			}
		}
	}, [payments, transaction_id, payment_id])

	useEffect(() => {
		const calculateVisibleRows = () => {
			if (!scrollable && tableContainerRef.current) {
				const containerHeight = tableContainerRef.current.clientHeight
				console.log(`not scrollable. containerHeight:${containerHeight}`)
				const visibleRows = Math.floor(containerHeight / ROW_HEIGHT)
				if (visibleRows > 1) {
					setVisibleRowCount(visibleRows - 1)
				}
				console.log(visibleRowCount)
			} else if (scrollable) {
				const containerHeight = tableContainerRef?.current?.clientHeight
				console.log(`scrollable. containerHeight:${containerHeight}`)
				setVisibleRowCount(payments?.length ?? 0)
			}
		}

		calculateVisibleRows()
		window.addEventListener("resize", calculateVisibleRows)
		return () => window.removeEventListener("resize", calculateVisibleRows)
	}, [payments, tableContainerRef.current?.clientHeight, scrollable])

	const breakpoint = useBreakpoint()

	const ordersTableOptions = useMemo(
		() => ({
			data: filteredPayments ?? [],
			columns: getColumns(stores)[breakpoint as ScreenBreakpoint],
			getCoreRowModel: getCoreRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			state: {
				columnFilters,
			},
			onColumnFiltersChange: setColumnFilters,
			enableFilters: true,
			manualFiltering: false,
		}),
		[filteredPayments, stores, breakpoint, columnFilters]
	)

	const movementsTableOptions = useMemo(
		() => ({
			data: movementsData,
			columns: getMovementsColumns(stores)[breakpoint as ScreenBreakpoint],
			getCoreRowModel: getCoreRowModel(),
			getFilteredRowModel: getFilteredRowModel(),
			state: {
				globalFilter: storeFilter,
				columnFilters,
				columnVisibility,
			},
			onColumnFiltersChange: setColumnFilters,
			onColumnVisibilityChange: setColumnVisibility,
			globalFilterFn: (
				row: Row<TableMovement>,
				columnId: string,
				filterValue: string[]
			) => {
				const storeId = String(row.getValue("store_name"))
				// Invert the selection: exclude stores in filterValue
				return !filterValue.includes(storeId)
			},
		}),
		[
			movementsData,
			stores,
			breakpoint,
			storeFilter,
			columnFilters,
			columnVisibility,
		]
	)

	// Create tables using the memoized options
	const ordersTable = useReactTable(ordersTableOptions)
	const movementsTable = useReactTable(movementsTableOptions)

	const activeTable = useMemo(
		() => (tableView === "orders" ? ordersTable : movementsTable),
		[tableView, ordersTable, movementsTable]
	)

	const activeFilters = useMemo(
		() =>
			tableView === "orders"
				? [
						{
							column: "status",
							title: "Estado",
							options: tableTransactionStatus.map((status) => ({
								value: status,
								label: status,
							})),
						},
						{
							column: "currency",
							title: "Moneda",
							options: tableTransactionCurrencies.map((curr) => ({
								value: curr,
								label: curr,
							})),
						},
				  ]
				: [],
		[tableView]
	)

	useEffect(() => {
		setFilteredOrders(
			ordersTable.getFilteredRowModel().rows.map((row) => row.original)
		)
	}, [columnFilters, ordersTable, filteredPayments])

	useEffect(() => {
		setFilteredMovements(
			movementsTable.getFilteredRowModel().rows.map((row) => row.original)
		)
	}, [columnFilters, movementsTable, movementsData])

	return (
		<div
			className="h-full flex flex-col [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]"
			ref={tableContainerRef}
		>
			{(selectedPayment || isClosing) && tableView === "orders" && (
				<PaymentModal
					key={selectedPayment?.id} // Add key to force new instance
					open={modalOpen && !isClosing}
					onClose={handleModalClose}
					payment={selectedPayment as TableTransaction}
					onPaymentUpdate={handlePaymentUpdate}
				/>
			)}
			{(selectedPayment || isClosing) && tableView === "movements" && (
				<TransactionModal
					open={modalOpen && !isClosing}
					onClose={handleModalClose}
					payment={selectedPayment as TableMovement}
				/>
			)}
			{viewOptions && (
				<DataTableToolbar<TableTransaction | TableMovement>
					table={activeTable as Table<TableTransaction | TableMovement>}
					searchInput={
						tableView === "orders" && showFilters
							? {
									placeholder: "Id de orden...",
									columnName: "orderId",
							  }
							: undefined
					}
					viewOptions={viewOptions}
					badgeFilters={showFilters ? activeFilters : []}
					storeFilter={storeFilter}
					view={tableView}
					setStoreFilter={setStoreFilter}
					setTableView={setTableView}
					transactions={filteredOrders}
					movements={filteredMovements}
					onColumnFiltersChange={setColumnFilters}
				/>
			)}

			{tableView === "orders" && (
				<TableTransactionView
					key={`orders-${JSON.stringify(columnFilters)}-${JSON.stringify(
						storeFilter
					)}`}
					isLoading={isLoading}
					viewOptions={viewOptions}
					bordered={bordered}
					items={filteredOrders}
					visibleRowCount={visibleRowCount}
					columns={getColumns(stores)[breakpoint as ScreenBreakpoint]}
					onRowSelected={(row) => {
						setSelectedPayment(row)
						setModalOpen(true)
					}}
					columnVisibility={ordersTable.getState().columnVisibility}
				/>
			)}
			{tableView === "movements" && (
				<TableMovementView
					key={`movements-${JSON.stringify(columnFilters)}-${JSON.stringify(
						storeFilter
					)}`}
					isLoading={isLoading}
					viewOptions={viewOptions}
					bordered={bordered}
					items={filteredMovements}
					visibleRowCount={visibleRowCount}
					columns={getMovementsColumns(stores)[breakpoint as ScreenBreakpoint]}
					onRowSelected={(row) => {
						setSelectedPayment(row)
						setModalOpen(true)
					}}
					columnVisibility={movementsTable.getState().columnVisibility}
				/>
			)}
		</div>
	)
}

export default TxTable
