import { ColumnDef } from "@tanstack/react-table"
import { formatPriceAmount } from "../../../../utils/numbers"
import { Price, TableTransaction } from "../columns"
import { TableMovement } from "../data"
import { CircleDot, CircleDotDashed } from "lucide-react"

export function MoneyCell<T extends TableTransaction | TableMovement>({
	accessorKey,
	header,
}: {
	accessorKey: string
	header: string
}): ColumnDef<T> {
	return {
		accessorKey: accessorKey,
		header: () => (
			<div className="text-right justify-end text-h6 items-center flex flex-row gap-2 w-full">
				{header === "Solicitado" ? (
					<CircleDotDashed className="size-icon" />
				) : (
					<CircleDot className="size-icon" />
				)}
				{header}
			</div>
		),
		cell: ({ row }) => {
			const expected = row.getValue(accessorKey) as Price | number
			const currency = row.original.currency
			if (expected && typeof expected === "object" && "amount" in expected) {
				return (
					<div className="justify-end text-right text-h6 w-full">
						<span className="font-semibold">
							{formatPriceAmount(expected.amount, undefined, expected.currency)}
						</span>{" "}
						<span className="font-normal md:hidden ">{expected.currency}</span>
					</div>
				)
			} else if (expected) {
				return (
					<div className="justify-end text-right text-h6 w-full">
						<span className="font-semibold">
							{formatPriceAmount(expected, undefined, currency)}
						</span>{" "}
						<span className="font-normal md:hidden ">{currency}</span>
					</div>
				)
			} else {
				return (
					<div className="justify-end  h-4 w-full rounded-xs bg-background-elevated"></div>
				)
			}
		},
	}
}
