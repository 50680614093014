import { ExternalLink, LogOut, Settings2, User } from "lucide-react"
import { useAuth } from "../../../../context/AuthContext"
import useBreakpoint from "../../../../hooks/useBreakpoint"
import { Avatar, AvatarFallback, AvatarImage } from "../../avatar"
import { Button } from "../../button"
import { Body1, Heading5, Heading6 } from "../../texts/TextStyle"

import { Link } from "react-router-dom"
import { PublicRole, getMaxRole } from "../../../../lib/roles"
import {
	Command,
	CommandGroup,
	CommandItem,
	CommandList,
	CommandSeparator,
} from "../../command"
import Col from "../../layouts/column"
import { Popover, PopoverContent, PopoverTrigger } from "../../popover"
import { Separator } from "../../separator"
import { Switch } from "../../switch"
import UserAccountRow from "./UserAccountRow"
import { useGlobalStore } from "../../../../hooks/useGlobalStore"

const sectionLabelClassName = "text-text-placeholder font-medium"
const groupClassName = "p-1"
const itemClassName = "p-betweenChips"
const headerClassName = "px-2 py-1.5"
const renderAvatarSVG = () => (
	<svg
		viewBox="0 0 950 1080"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className="size-10 bg-white"
	>
		<defs>
			<filter
				id="filter"
				x="-20%"
				y="-20%"
				width="140%"
				height="140%"
				filterUnits="objectBoundingBox"
				primitiveUnits="userSpaceOnUse"
				color-interpolation-filters="linearRGB"
			>
				<feMorphology
					operator="dilate"
					radius="20 20"
					in="SourceAlpha"
					result="morphology"
				/>
				<feFlood flood-color="#ffffff" flood-opacity="1" result="flood" />
				<feComposite
					in="flood"
					in2="morphology"
					operator="in"
					result="composite"
				/>
				<feMerge result="merge">
					<feMergeNode in="composite" result="mergeNode" />
					<feMergeNode in="SourceGraphic" result="mergeNode1" />
				</feMerge>
			</filter>
		</defs>
		<g id="notion-avatar" filter="url(#filter)">
			<g id="notion-avatar-face" fill="#ffffff">
				<title>Face/ 0</title>
				<g
					id="Face/-0"
					stroke="none"
					stroke-width="1"
					fill-rule="evenodd"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<path
						d="M532,379 C664.54834,379 772,486.45166 772,619 C772,751.54834 664.54834,859 532,859 C435.13263,859 351.668898,801.612202 313.752775,718.980575 L313.322946,718.989282 L312,719 C267.81722,719 232,683.18278 232,639 C232,599.134956 261.158843,566.080325 299.312086,560.00055 C325.599297,455.979213 419.809919,379 532,379 Z M295.858895,624.545187 L304.141105,655.454813"
						id="Path"
						stroke="#000000"
						stroke-width="24"
					/>
				</g>
			</g>
			<g id="notion-avatar-nose">
				<title>Nose/ 7</title>
				<g
					id="Nose/-7"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<path
						d="M649,600 C669,606.666667 679,620 679,640 C679,660 669,673.333333 649,680"
						id="Path"
						stroke="#000000"
						stroke-width="16"
					/>
				</g>
			</g>
			<g id="notion-avatar-mouth">
				<title>Mouth/ 2</title>
				<g
					id="Mouth/-2"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<path
						d="M572,769 C585.775809,774.333333 599.109142,777 612,777 C624.890858,777 638.224191,774.333333 652,769"
						id="Path"
						stroke="#000000"
						stroke-width="16"
					/>
				</g>
			</g>
			<g id="notion-avatar-eyes">
				<title>Eyes/ 7</title>
				<g
					id="Eyes/-7"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
				>
					<path
						d="M570,516 C578.836556,516 586,526.745166 586,540 C586,553.254834 578.836556,564 570,564 C561.163444,564 554,553.254834 554,540 C554,526.745166 561.163444,516 570,516 Z M708,516 C716.836556,516 724,526.745166 724,540 C724,553.254834 716.836556,564 708,564 C699.163444,564 692,553.254834 692,540 C692,526.745166 699.163444,516 708,516 Z M568,527 C564.686292,527 562,529.686292 562,533 C562,536.313708 564.686292,539 568,539 C571.313708,539 574,536.313708 574,533 C574,529.686292 571.313708,527 568,527 Z M706,527 C702.686292,527 700,529.686292 700,533 C700,536.313708 702.686292,539 706,539 C709.313708,539 712,536.313708 712,533 C712,529.686292 709.313708,527 706,527 Z"
						id="Combined-Shape"
						fill="#000000"
					/>
				</g>
			</g>
			<g id="notion-avatar-eyebrows">
				<title>Eyebrows/ 0</title>
				<g
					id="Eyebrows/-0"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
					stroke-linecap="round"
					stroke-linejoin="round"
				>
					<g
						id="Group"
						transform="translate(525.000000, 492.000000)"
						stroke="#000000"
						stroke-width="16"
					>
						<path
							d="M0,16 C12.8888889,5.33333333 27.8888889,0 45,0 C62.1111111,0 77.1111111,5.33333333 90,16"
							id="Path"
						/>
						<path
							d="M138,16 C150.888889,5.33333333 165.888889,0 183,0 C200.111111,0 215.111111,5.33333333 228,16"
							id="Path"
						/>
					</g>
				</g>
			</g>
			<g id="notion-avatar-glasses">
				<title>Glasses/ 0</title>
				<g
					id="Glasses/-0"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
				/>
			</g>
			<g id="notion-avatar-hair">
				<g id="Hairstyle/ 50">
					<path
						id="Path"
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M426.002 229C503.002 222 539.359 228.22 582.002 263C595.099 257.68 669.002 242 732.002 323C795.002 404 778.755 491.067 767.002 540C764.235 551.516 752.002 499 698.002 458C644.002 417 582.002 389 582.002 389C582.002 389 492.002 462 480.002 526C478.356 534.775 498.002 400 498.002 400C498.002 400 474.473 456.878 470.002 478C461.746 516.997 467.94 523.932 452.002 553C436.064 582.068 398.904 601.544 379.002 625L376.719 620.336L374.832 616.519C366.738 600.231 361.128 590.058 358.002 586C352.545 578.918 333.322 563.08 300.002 567C277.335 569.667 229.002 608 242.002 653C247.838 673.204 261.234 689.023 282.189 700.458C292.349 706.003 318.002 714 318.002 714C318.002 714 307.002 716 299.002 716C291.002 716 281.002 713 281.002 713C281.002 713 232.002 771 176.002 766C120.002 761 107.002 703 126.002 666C145.002 629 220.424 645.247 219.002 640C172.122 646.624 162.819 622.4 126.002 617C167.993 609.839 181.265 627.383 219.002 617C218.549 614.903 176.949 589.737 148.002 586C180.091 580.029 184.391 596.95 213.002 589C203.216 527.783 211.515 462.93 242.002 389C282.002 292 349.002 236 426.002 229Z"
						fill="black"
						stroke="black"
						stroke-width="12"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
			</g>
			<g id="notion-avatar-accessories">
				<g id="Accessories/ 14">
					<g id="Group">
						<path
							id="Path"
							d="M287.695 658.293C276.76 647.358 276.76 629.63 287.695 618.695C298.63 607.76 316.358 607.76 327.293 618.695C337.267 628.669 338.143 644.297 329.921 655.264L362.083 687.426C366.925 692.268 366.925 700.119 362.083 704.962C357.24 709.804 349.389 709.804 344.547 704.962L306.04 666.456C299.371 666.111 292.792 663.389 287.695 658.293Z"
							fill="white"
							stroke="black"
							stroke-width="8"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
						<rect
							id="Rectangle"
							width="8"
							height="16"
							rx="4"
							transform="matrix(-0.707107 0.707107 0.707107 0.707107 295.615 623.221)"
							fill="black"
						/>
					</g>
				</g>
			</g>
			<g id="notion-avatar-details">
				<title>Details/ 0</title>
				<g
					id="Details/-0"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
				/>
			</g>
			<g id="notion-avatar-beard">
				<title>Beard/ 0</title>
				<g
					id="Beard/-0"
					stroke="none"
					stroke-width="1"
					fill="none"
					fill-rule="evenodd"
				/>
			</g>
		</g>
	</svg>
)

const UserDropdownMenu = () => {
	const { user, logout, stores } = useAuth()
	const isMobile = useBreakpoint("sm")
	const { setIsDarkTheme, isDarkTheme } = useGlobalStore()
	const isPos =
		getMaxRole(user?.roles ?? user?.talo_role_list ?? []) === PublicRole.POS
	return (
		<Popover>
			<PopoverTrigger asChild>
				{renderTrigger(
					{
						email: user.email,
						photoURL: user.photoURL,
						displayName: user.displayName,
					},
					isMobile as boolean
				)}
			</PopoverTrigger>
			<PopoverContent className="w-[260px] !px-1 py-2 rounded-md" align="end">
				<Command>
					<CommandList>
						<Col
							className={groupClassName}
							key="personal"
							// heading={}
						>
							<div className={headerClassName}>
								<UserDropdownGroupHeader title="Cuenta personal" />
							</div>
							{/* <CommandItem className="w-full p-0"> */}
							<div className="w-full">
								<UserAccountRow
									displayName={user.displayName ?? user.email}
									photoUrl={user.photoURL}
									selected
								/>
							</div>
							{/* </CommandItem> */}
						</Col>
						{stores && stores.length > 0 && !isPos && (
							<Col
								className={groupClassName}
								key="brand"
								// heading={<UserDropdownGroupHeader title="Marca" />}
							>
								{" "}
								<div className={headerClassName}>
									<UserDropdownGroupHeader title="Marca" />
								</div>
								{stores.map((store: any) => (
									// <CommandItem className="w-full p-0" key={store.store_id}>
									<div className="w-full" key={store.store_id}>
										<UserAccountRow
											displayName={store.store_name}
											selected={false}
											photoUrl={store.photoURL}
										/>
									</div>
									// </CommandItem>
								))}
								{/* Esto lo dejo comentado para cuando tengamos implementado agregar datos de marca UPDATE
								{stores.length <= 0 && (
									<CommandItem className="w-full p-0" key="new-store">
										<NewStoreRow />
									</CommandItem>
								)} */}
							</Col>
						)}
						<Separator
							orientation="horizontal"
							className="-mx-1 h-px bg-border"
						/>

						<CommandGroup>
							{!isPos && (
								<CommandItem
									className={`justify-between text-text-muted font-medium ${itemClassName}`}
									value="settings"
									asChild
								>
									<Link to="/settings">
										<Heading6>Ajustes</Heading6>
										<Settings2 size={16} />
									</Link>
								</CommandItem>
							)}
							<CommandItem
								className={`justify-between text-text-muted font-medium ${itemClassName}`}
								value="darkmode"
							>
								<Heading6>Darkmode</Heading6>
								<Switch
									onClick={() => {
										setIsDarkTheme(!isDarkTheme)

										document.documentElement.classList.toggle("dark")
									}}
								/>
							</CommandItem>
						</CommandGroup>
						<CommandSeparator />
						<CommandGroup>
							<CommandItem className="text-text-warning font-medium ">
								<Link
									to="https://sandbox.talo.com.ar/login"
									className=" flex flex-row justify-between w-full items-center"
								>
									<Heading6>Modo Sandbox</Heading6>
									<ExternalLink size={16} />
								</Link>
							</CommandItem>
						</CommandGroup>
						<CommandSeparator />
						<CommandGroup>
							<CommandItem
								className={`justify-between text-text-error font-medium ${itemClassName}`}
								value="logout"
								onSelect={(op: any) => logout()}
							>
								<Heading6>Cerrar sesión</Heading6>
								<LogOut size={16} />
							</CommandItem>
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	)
}

const UserDropdownGroupHeader = ({ title }: { title: string }) => {
	return <Body1 className={sectionLabelClassName}>{title}</Body1>
}

function renderTrigger(
	{
		email,
		photoURL,
		displayName,
	}: {
		email: string
		photoURL?: string
		displayName?: string
	},
	isMobile: boolean
) {
	if (isMobile) {
		return (
			<Avatar
				className={`rounded-sm border  border-border hover:z-40 hover:scale-110 transition-all duration-500 `}
			>
				{photoURL ? (
					<AvatarImage src={photoURL} />
				) : (
					<AvatarFallback>
						{displayName
							? displayName.slice(0, 2).toUpperCase()
							: renderAvatarSVG()}
					</AvatarFallback>
				)}
			</Avatar>
		)
	} else {
		return (
			<Button
				variant="outline"
				role="combobox"
				size="xl"
				className={
					"w-[200px] !h-[64px] justify-between rounded-md border-2 group"
				}
			>
				<Heading5 className="font-semibold grow overflow-ellipsis overflow-hidden pr-betweenCards">
					{email}
				</Heading5>
				<Avatar
					className={`rounded-sm border  border-border hover:z-40 hover:scale-110 transition-all duration-500 `}
				>
					{photoURL ? (
						<AvatarImage src={photoURL} />
					) : (
						<AvatarFallback>
							{displayName
								? displayName.slice(0, 2).toUpperCase()
								: renderAvatarSVG()}
						</AvatarFallback>
					)}
				</Avatar>
			</Button>
		)
	}
}

export default UserDropdownMenu
