import { findDisplayNameForBankInJson } from "../../../../lib/utils"
import { formatPriceAmount } from "../../../../utils/numbers"
import { TableSubTransaction } from "../../../tables/transactions-table/columns"
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from "../../../ui/accordion"
import { Separator } from "../../../ui/separator"
import bank_list from "../../../../lib/psp_bank_id.json"
import BodyItem from "./BodyItem"

const TransactionsAccordion = ({
	transactions,
	variant,
}: {
	transactions: TableSubTransaction[]
	variant?: "transfer" | "crypto"
}) => {
	switch (variant) {
		case "transfer":
			return (
				<Accordion
					type="single"
					collapsible
					className="border border-border-onModal rounded-sm px-betweenComponents"
				>
					{transactions?.map((transaction: TableSubTransaction) => (
						<AccordionItem value={transaction.transactionId}>
							<AccordionTrigger>
								{formatPriceAmount(
									transaction.amount,
									undefined,
									transaction.currency
								)}{" "}
								{transaction.currency}
							</AccordionTrigger>
							<AccordionContent>
								<BodyItem
									name="CUIT"
									value={transaction.senderCuit}
									copy={true}
									overflow={true}
								/>
								<BodyItem
									name={transaction.senderIsPsp ? "CVU" : "CBU"}
									value={transaction.senderAddress}
									copy={true}
									overflow={true}
								/>
								{(transaction.bank_id || transaction.psp_id) && (
									<BodyItem
										name="Banco"
										value={
											transaction.psp_id
												? findDisplayNameForBankInJson(
														transaction.psp_id,
														bank_list
												  )
												: transaction.bank_id
												? findDisplayNameForBankInJson(
														transaction.bank_id,
														bank_list
												  )
												: ""
										}
										copy={true}
										overflow={true}
									/>
								)}
								<Separator
									orientation="horizontal"
									className="bg-border my-2"
								/>
								<BodyItem name="Fecha" value={transaction.date} />
								<BodyItem name="Hora" value={transaction.time} />
							</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>
			)
		case "crypto":
			return (
				<Accordion
					type="single"
					collapsible
					className="border border-border-onModal rounded-sm px-betweenComponents"
				>
					{transactions?.map((transaction: TableSubTransaction) => (
						<AccordionItem value={transaction.transactionId}>
							<AccordionTrigger>
								{formatPriceAmount(transaction.amountReadable)}{" "}
								{transaction.currency}
							</AccordionTrigger>
							<AccordionContent>
								<BodyItem
									name="Hash de transacción"
									value={transaction.transactionHash}
									copy={true}
									overflow={true}
								/>
								<BodyItem
									name="Dirección de origen"
									value={transaction.senderAddress}
									copy={true}
									overflow={true}
								/>
								<BodyItem name="Fecha" value={transaction.date} />
								<BodyItem name="Hora" value={transaction.time} />
							</AccordionContent>
						</AccordionItem>
					))}
				</Accordion>
			)
	}
}

export default TransactionsAccordion
