import { ColumnFiltersState, Table } from "@tanstack/react-table"
import {
	ArrowLeftRight,
	CalendarDays,
	Check,
	ChevronDown,
	EyeOff,
	FileDown,
	Filter,
	Search,
	ShoppingCart,
	TableProperties,
	Trash,
	X,
} from "lucide-react"
import { useEffect, useState } from "react"
import { DateRange } from "react-day-picker"
import { useAuth } from "../../../context/AuthContext"
import { useCombinedPayments } from "../../../hooks/usePayments"
import {
	exportPaymentsInDateRange,
	translateTransactionStatus,
} from "../../../utils/csv-utils"
import { TableTransaction } from "../../tables/transactions-table/columns"
import {
	mapCombinedPaymentsToTableTransaction,
	TableMovement,
} from "../../tables/transactions-table/data"
import { Alert } from "../alert"
import { Avatar, AvatarFallback, AvatarImage, AvatarOverlay } from "../avatar"
import { Button } from "../button"
import { DateRangePicker } from "../date-range-picker"
import { Label } from "../label"
import Col from "../layouts/column"
import CenteredBody from "../layouts/platform/body/CenteredBody"
import Row from "../layouts/row"
import { Popover, PopoverContent, PopoverTrigger } from "../popover"
import { Section } from "../section"
import { SectionHeader } from "../section-header"
import { Separator } from "../separator"
import { Skeleton } from "../skeleton"
import SlidingCard from "../sliding-card"
import { DataTableViewOptions } from "./data-table-view-options"
// Adjust the import path accordingly

import { cn } from "../../../lib/utils"
import { statusIcons } from "../../tables/transactions-table/columns/statusCell"
import { TableView } from "../../tables/transactions-table/table"
import {
	TableTabs,
	TableTabsContent,
	TableTabsList,
	TableTabsTrigger,
} from "../table-tabs"
import { Heading6 } from "../texts/TextStyle"

// Create a type union for the possible table data types
export type TableData = TableTransaction | TableMovement

const TRANSACTION_STATES = [
	"PENDING",
	"SUCCESS",
	"EXPIRED",
	"OVERPAID",
	"UNDERPAID",
] as const

const MOVEMENT_STATES = [
	"processed",
	"INBOUND",
	"pending",
	"rejected",
	"UNMATCHED",
] as const

// Update the interface definition with proper generic constraints
export interface DataTableToolbarProps<TData extends TableData> {
	table: Table<TData>
	searchInput?: {
		placeholder: string
		columnName: string
	}
	viewOptions?: boolean
	badgeFilters?: {
		column: string
		title: string
		options: {
			value: string
			label: string
		}[]
	}[]
	storeFilter: string[]
	view: TableView
	setStoreFilter: (filter: string[]) => void
	setTableView: (view: TableView) => void
	onColumnFiltersChange: (filters: ColumnFiltersState) => void
	onStoreFilterChange?: (stores: string[]) => void
	transactions: TableTransaction[] // Add this prop
	movements: TableMovement[] // Add this prop
}

const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

// Update the component definition to use the TableData type constraint
export default function DataTableToolbar<TData extends TableData>({
	table,
	searchInput,
	viewOptions,
	badgeFilters,
	storeFilter,
	view,
	setStoreFilter,
	setTableView,
	onColumnFiltersChange,
	onStoreFilterChange,
	transactions,
	movements,
}: DataTableToolbarProps<TData>) {
	const isFiltered = table.getState().columnFilters.length > 0
	const { user, stores } = useAuth()
	const { data: combinedPayments, isLoading } = useCombinedPayments(
		user.id,
		user.roles.includes("pos") ? user.team_id : undefined
	)

	const [filterExpand, setFilterExpand] = useState(false)
	const [dateRange, setDateRange] = useState<DateRange | undefined>()
	const [isExporting, setIsExporting] = useState(false)
	const [searchExpand, setSearchExpand] = useState(false)
	const [searchValue, setSearchValue] = useState("")

	const handleExport = async () => {
		if (!dateRange?.from) return
		setIsExporting(true)
		try {
			if (view === "orders") {
				await exportPaymentsInDateRange(transactions, dateRange, "orders")
			} else {
				await exportPaymentsInDateRange(movements, dateRange, "movements")
			}
		} catch (error) {
			console.error("Error exporting data:", error)
		} finally {
			setIsExporting(false)
		}
	}

	const handleClick = (storeId: string) => {
		if (storeFilter.includes(storeId)) {
			setStoreFilter(storeFilter.filter((id) => id !== storeId))
		} else {
			setStoreFilter([...storeFilter, storeId])
		}
	}

	const handleFilterExpand = () => {
		setFilterExpand(!filterExpand)
	}

	const handleSearch = (value: string) => {
		setSearchValue(value)
		table.getColumn("orderId")?.setFilterValue(value)
	}

	const handleSearchExpand = () => {
		setSearchExpand(!searchExpand)
	}
	const [selectedStates, setSelectedStates] = useState<string[]>([])

	const handleStateSelect = (state: string) => {
		let newStates: string[]
		if (selectedStates.includes(state)) {
			newStates = selectedStates.filter((s) => s !== state)
		} else {
			newStates = [...selectedStates, state]
		}
		setSelectedStates(newStates)

		switch (view) {
			case "orders":
				var column = table.getColumn("status")
				if (column) {
					column.setFilterValue(newStates.length ? newStates : undefined)
				}
				break
			case "movements":
				var column = table.getColumn("status")
				if (column) {
					column.setFilterValue(newStates.length ? newStates : undefined)
				}
				break
		}
	}

	const [filterDateRange, setFilterDateRange] = useState<
		DateRange | undefined
	>()

	// Add new handler for date range filter
	const handleDateRangeSelect = (range: DateRange | undefined) => {
		setFilterDateRange(range)

		if (!range?.from) {
			table.getColumn("creationDateTime")?.setFilterValue(undefined)
			return
		}

		const to = range.to || range.from
		const fromDate = new Date(range.from)
		fromDate.setHours(0, 0, 0, 0)

		const toDate = new Date(to)
		toDate.setHours(23, 59, 59, 999)

		table.getColumn("creationDateTime")?.setFilterValue({
			from: fromDate,
			to: toDate,
		})
	}

	// Add this reset handler
	const handleReset = () => {
		table.resetColumnFilters()
		setSelectedStates([]) // Reset Estado filter state
		setFilterDateRange(undefined) // Reset Fecha filter state
	}

	const handleCloseSearch = () => {
		setSearchExpand(false)
		setSearchValue("")
		table.getColumn("orderId")?.setFilterValue("")
	}

	//update the filtered stores when the storeFilter changes
	useEffect(() => {
		const column = table.getColumn("store_name")
		if (column) {
			column.setFilterValue(storeFilter.length ? storeFilter : undefined)
		}
	}, [storeFilter])

	return (
		<Col>
			<div className="flex  items-center justify-between relative ">
				<div className="flex h-fit w-fit z-20">
					<TableTabs defaultValue="Ordenes">
						<TableTabsList>
							<TableTabsTrigger
								value="Ordenes"
								onClick={() => {
									setTableView("orders")
									handleReset()
								}}
							>
								<ShoppingCart className="size-icon" />
								<Heading6 className="hidden sm:flex">Ordenes</Heading6>
							</TableTabsTrigger>
							<TableTabsTrigger
								value="Movimientos"
								onClick={() => {
									setTableView("movements")
									handleReset()
								}}
							>
								<ArrowLeftRight className="size-icon" />
								<Heading6 className="hidden sm:flex">Movimientos</Heading6>
							</TableTabsTrigger>
						</TableTabsList>
						<TableTabsContent value="Ordenes">
							<div></div>
						</TableTabsContent>
						<TableTabsContent value="Movimientos">
							<div></div>
						</TableTabsContent>
					</TableTabs>
				</div>
				<div className="flex flex-row items-center py-2 relative">
					<Button
						variant="ghost"
						size="md"
						className={`${
							isFiltered ? "text-text-interactive" : "text-text-muted"
						} gap-2`}
						onClick={handleFilterExpand}
					>
						<Filter className="size-icon" />
						<Heading6 className="hidden xl:flex">Filtros</Heading6>
					</Button>
					{viewOptions && <DataTableViewOptions table={table} />}
					<SlidingCard
						side={"center"}
						trigger={
							<Button
								aria-label="Toggle columns"
								variant="ghost"
								size="md"
								className="ml-auto py-2 lg:py-3 text-text-muted"
							>
								<FileDown className="mr-2 size-icon" />
								<Heading6 className="hidden xl:flex">Extracto</Heading6>
							</Button>
						}
					>
						<CenteredBody>
							<SectionHeader
								title={view === "orders" ? "Órdenes" : "Movimientos"}
								subtitle={
									"Descargá los movimientos de tu cuenta en el período solicitado."
								}
							/>
							<Col className="gap-2 w-fit">
								<Label>Rango de tiempo</Label>
								<DateRangePicker
									onUpdate={(values) => setDateRange(values.range)}
									align="start"
									locale="es-ar"
									showCompare={false}
								/>
							</Col>
							<Section>
								{isLoading ? (
									<Skeleton className="h-32 w-full" />
								) : (
									<Alert
										className={`${
											!dateRange?.from || isExporting ? "opacity-20" : ""
										}`}
										variant="link"
										icon={<TableProperties />}
										title={"Resumen de cuenta"}
										description={
											"Descargate el resumen de cuenta en formato tabla, esto incluye: solicitado, recibido, comisión, moneda, fecha y número de orden"
										}
										buttonText={
											isExporting ? "Exportando..." : "Descargar .XLS"
										}
										buttonDisabled={!dateRange?.from || isExporting}
										onClickHandler={() => {
											handleExport()
										}}
									/>
								)}
							</Section>
						</CenteredBody>
					</SlidingCard>
					<div className=" flex items-center">
						<Button
							variant="ghost"
							size="md"
							onClick={() => setSearchExpand(!searchExpand)}
							className={cn(
								"hover:bg-background group transition-transform duration-300",
								searchExpand && "translate-x-2 text-swatch-100"
							)}
						>
							<Search className="size-icon" />
						</Button>
						<div
							className={cn(
								"absolute h-full right-0 w-0 overflow-hidden transition-all duration-300 flex items-center",
								searchExpand && "w-full"
							)}
						>
							<input
								className="w-full h-12 px-3 -mb-[6px] bg-background border-b border-background-invers focus:outline-none text-h6 z-30"
								placeholder="Buscar por ID de orden..."
								value={searchValue}
								onChange={(e) => handleSearch(e.target.value)}
								autoFocus
							/>
							{searchExpand && (
								<X
									className="absolute right-2 size-4 cursor-pointer text-muted-foreground hover:text-foreground z-40"
									onClick={handleCloseSearch}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
			<Separator className="bg-border -mt-[2px] z-10" />
			{filterExpand && (
				<div className="flex  items-center justify-between py-3 animate-slide-down transition-all ">
					<Row className="gap-betweenChips h-10  items-center">
						{/* {badgeFilters &&
							badgeFilters.map((filter) => {
								const column = table.getColumn(filter.column)
								return column ? (
									<DataTableFacetedFilter
										key={filter.title}
										column={column as Column<TData, unknown>}
										title={filter.title}
										options={filter.options}
									/>
								) : null
							})} */}
						<Popover>
							<PopoverTrigger asChild>
								<div
									className={`flex flex-row gap-1 w-fit lg:max-w-36  xl:w-fit h-fit  font-normal items-center justify-center border truncate text-left overflow-clip ${
										selectedStates.length > 0
											? "border-text-interactive text-text-interactive bg-blue-500/10"
											: "border-border text-text-muted bg-transparent hover:bg-background-subtle"
									}  cursor-pointer   px-3 py-1 rounded-full text-h6`}
								>
									<Check className="size-4" />
									Estado:{" "}
									{selectedStates.length > 0 && `(${selectedStates.length})`}
									<ChevronDown className="size-icon" />
								</div>
							</PopoverTrigger>
							<PopoverContent className="w-44 rounded-md" align="center">
								<div className="flex flex-col gap-4 px-xs py-sm">
									{view === "orders"
										? TRANSACTION_STATES.map((state) => (
												<div
													key={state}
													className={`flex flex-row items-center justify-between rounded-sm cursor-pointer hover:translate-x-1 transition-all duration-500`}
													onClick={() => handleStateSelect(state)}
												>
													<Row className="gap-2 items-center">
														<div className="[&>svg]:size-icon ">
															{statusIcons[state]}
														</div>

														<div
															className={`text-h6 text-text-muted hover:text-text transition-all duration-300 ${
																selectedStates.includes(state) &&
																"text-text-interactive"
															}`}
														>
															{capitalizeFirstLetter(
																translateTransactionStatus(state)
															)}
														</div>
													</Row>

													<div className="grid items-center justify-center w-4 h-4 ">
														{selectedStates.includes(state) && (
															<Check className="h-3 w-3" />
														)}
													</div>
												</div>
										  ))
										: MOVEMENT_STATES.map((state) => (
												<div
													key={state}
													className={`flex flex-row items-center justify-between rounded-sm cursor-pointer hover:translate-x-1 transition-all duration-500`}
													onClick={() => handleStateSelect(state)}
												>
													<Row className="gap-2 items-center">
														<div className="[&>svg]:size-icon ">
															{statusIcons[state]}
														</div>

														<div
															className={`text-h6 text-text-muted hover:text-text transition-all duration-300 ${
																selectedStates.includes(state) &&
																"text-text-interactive"
															}`}
														>
															{capitalizeFirstLetter(
																translateTransactionStatus(state)
															)}
														</div>
													</Row>

													<div className="grid items-center justify-center w-4 h-4 ">
														{selectedStates.includes(state) && (
															<Check className="h-3 w-3" />
														)}
													</div>
												</div>
										  ))}
									<Separator className="bg-border w-full" />
									<a
										className="flex flex-row  text-text-muted justify-start items-center hover:text-text gap-2 cursor-pointer w-fit "
										onClick={handleReset}
									>
										{" "}
										<Trash className="size-icon" />
										<Heading6>Eliminar filtro</Heading6>
									</a>
								</div>
							</PopoverContent>
						</Popover>
						<DateRangePicker
							className="bg-transparent border-none p-0 max-h-4"
							onUpdate={(values) => handleDateRangeSelect(values.range)}
							initialDateFrom={filterDateRange?.from}
							initialDateTo={filterDateRange?.to}
							align="start"
							locale="es-ar"
							showCompare={false}
							trigger={
								<div
									className={`flex flex-row gap-1 w-fit lg:w-36  xl:w-fit  h-fit  font-normal items-center justify-start border truncate text-left overflow-clip  ${
										filterDateRange?.from
											? "border-text-interactive text-text-interactive bg-blue-500/10"
											: "border-border text-text-muted bg-transparent hover:bg-background-subtle"
									}  cursor-pointer   px-3 py-1 rounded-full text-h6`}
								>
									<CalendarDays className="size-4" />
									{filterDateRange?.from
										? `Fecha: ${filterDateRange.from.toLocaleDateString(
												"es-AR"
										  )}-${filterDateRange.to?.toLocaleDateString("es-AR")}`
										: "Seleccionar fecha"}
									<ChevronDown className="size-icon" />
								</div>
							}
						/>
						{/* <Popover>
							<PopoverTrigger>
								<div
									className={`flex flex-row gap-1 w-fit h-fit  font-normal items-center justify-center border ${
										filterDateRange?.from
											? "border-text-interactive text-text-interactive bg-blue-500/20"
											: "border-border text-text-muted bg-transparent"
									}  cursor-pointer   px-3 py-1 rounded-full text-h6`}
								>
									<CalendarDays className="size-4" />
									{filterDateRange?.from
										? `Fecha (${filterDateRange.from.toLocaleDateString(
												"es-AR"
										  )})`
										: "Seleccionar fecha"}
									<ChevronDown className="size-icon" />
									
								</div>
							</PopoverTrigger>
							<PopoverContent className="w-auto p-0" align="start">
								<div className="p-3"></div>
							</PopoverContent>
						</Popover> */}
					</Row>
					<Row className="justify-center items-center gap-3">
						{isFiltered && (
							<Button
								variant="ghost"
								size="md"
								className="text-text-muted hover:text-text gap-2 hover:bg-background"
								onClick={handleReset}
							>
								{" "}
								<Heading6 className="hidden xl:flex">Borrar filtros</Heading6>
								<X className="size-icon" />
							</Button>
						)}
						{stores &&
							stores.length >= 2 &&
							stores.map((store) => (
								<Avatar
									key={store.store_id}
									onClick={() => handleClick(String(store.store_name))}
									className={`size-8 border-2 -ml-4 border-border  hover:z-40 hover:scale-110 transition-all duration-500 ${
										storeFilter.includes(String(store.store_name)) &&
										"dark:border-white/60"
									}`}
								>
									{storeFilter.includes(String(store.store_name)) && (
										<AvatarOverlay className="bg-background">
											<EyeOff className="size-icon" />
										</AvatarOverlay>
									)}
									{store.logo_url ? (
										<AvatarImage src={store.logo_url} />
									) : (
										<AvatarFallback className="text-b1 bg-background">
											{store.store_name
												? store.store_name.slice(0, 2).toUpperCase()
												: "??"}
										</AvatarFallback>
									)}
								</Avatar>
							))}
					</Row>
				</div>
			)}
		</Col>
	)
}
