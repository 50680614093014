import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu"
import { type Table } from "@tanstack/react-table"

import { Button } from "../button"
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
} from "../dropdown-menu"
import { Columns, Eye } from "lucide-react"
import { Heading6 } from "../texts/TextStyle"

interface DataTableViewOptionsProps<TData> {
	table: Table<TData>
}

export function DataTableViewOptions<TData>({
	table,
}: DataTableViewOptionsProps<TData>) {
	const renderColumnHeader = (header: any, column: any) => {
		if (typeof header === "function") {
			return header(column)
		} else {
			return header
		}
	}
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button
					aria-label="Toggle columns"
					variant="ghost"
					size="md"
					className="ml-auto h-8 text-text-muted"
				>
					<Columns className="mr-2 size-icon" />
					<Heading6 className="hidden xl:flex">Columnas</Heading6>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align="end" className="w-fit p-sm">
				{table
					.getAllColumns()
					.filter(
						(column) =>
							typeof column.accessorFn !== "undefined" && column.getCanHide()
					)
					.map((column) => {
						return (
							<DropdownMenuCheckboxItem
								key={column.id}
								className="capitalize"
								checked={column.getIsVisible()}
								onCheckedChange={(value) => {
									column.toggleVisibility(!!value)
									// Force table update
									table.setColumnVisibility(prev => ({
										...prev,
										[column.id]: !!value
									}))
								}}
							>
								{renderColumnHeader(column.columnDef.header, column)}
							</DropdownMenuCheckboxItem>
						)
					})}
			</DropdownMenuContent>
		</DropdownMenu>
	)
}
