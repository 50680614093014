import { useNavigate } from "react-router-dom"
import { useAuth } from "../../context/AuthContext"
import { useLayoutEffect } from "react"
import Row from "../../components/ui/layouts/row"
import { Progress } from "../../components/ui/progress"

export default function PaymentMethodsWrapper({
	children,
	progressPercentage,
}: {
	children: React.ReactNode
	progressPercentage: number
}) {
	const { kyc, user } = useAuth()
	const navigate = useNavigate()

	useLayoutEffect(() => {
		const kycEpoch = new Date(2024, 7, 21)
		const userCreationDate = new Date(user.creation_timestamp)

		if (
			!(kyc.isLoading || kyc.isFetching) &&
			!kyc.data?.kyc_status &&
			userCreationDate > kycEpoch
		) {
			navigate("/onboarding")
		}
	}, [
		kyc.isLoading,
		kyc.isFetching,
		kyc.data?.kyc_status,
		user.creation_timestamp,
		kyc.status,
	])

	return (
		<>
			<Row className="gap-betweenTexts">
				<div className="h-2 w-24 bg-background-invers rounded-full" />
				<Progress variant="secondary" value={progressPercentage} />
			</Row>
			{children}
		</>
	)
}
