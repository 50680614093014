import { Clock } from "lucide-react"
import React, { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Alert } from "../../components/ui/alert"
import { Button } from "../../components/ui/button"
import Col from "../../components/ui/layouts/column"
import PlatformHeader from "../../components/ui/layouts/platform/PlatformHeader"
import CenteredBody from "../../components/ui/layouts/platform/body/CenteredBody"
import { Progress } from "../../components/ui/progress"
import { SectionHeader } from "../../components/ui/section-header"
import Spacer from "../../components/ui/spacer"
import { useAuth } from "../../context/AuthContext"
import { useGlobalStore } from "../../hooks/useGlobalStore"
import LoadingProcessCover, {
	ApprovedProcessCover,
	RejectedProcessCover,
} from "./VerifyingProcessCover"
import { use } from "chai"
const VerifyingBody: FC = () => {
	const { user, kyc } = useAuth()
	const [progress, setProgress] = useState(0)
	const navigate = useNavigate()
	const { globalStore, setGlobalStore } = useGlobalStore()

	// const { data: kycData } = useGetKyc(user.pollux_account_id)

	useEffect(() => {
		const interval = setInterval(() => {
			setProgress((prevProgress) => {
				// Calculate the next progress value
				// This example uses a simple non-linear increment that slows down as progress increases
				const increment = Math.max(90 - prevProgress, 1)
				const nextProgress = prevProgress + increment * 0.01 // Adjust the multiplier for speed control

				// If progress is 100 or more, clear the interval
				if (nextProgress >= 100) {
					clearInterval(interval)
					return 100
				}
				// if (kycData?.kyc_status === "approved" && user.pollux_account_id) {
				// 	setProgress(100)
				// 	currentStatus = "approved"
				// 	clearInterval(interval)
				// }

				return nextProgress
			})
		}, 200) // Adjust the interval time as needed

		return () => {
			clearInterval(interval)
		}
	}, [])

	const statusAttributes: {
		[key in "rejected" | "metamap" | "approved"]: {
			alertTitle: string
			alertDescription: string | React.ReactNode
			title: string
			subtitle: string
			cover: React.ReactNode
			footer?: React.ReactNode
		}
	} = {
		rejected: {
			title: "Hubo un problema verificando tus datos",
			subtitle: "Contactate con nuestro equipo, para que te digan como seguir.",
			alertTitle: "¿Porque puede ser?",
			alertDescription: (
				<>
					<li>Puede que, necesitemos algún documento adicional.</li>
					<li>Puede que, algún dato no coincida.</li>
					<li>Solo te queramos conocer.</li>
				</>
			),
			cover: <RejectedProcessCover />,
			footer: (
				<a
					href="https://wa.me/541133711752/?text=Hola,%20tuve%20un%20problema%20con%20la%20verificación%20de%20cuenta."
					target="_blank"
					rel="noreferrer"
				>
					<Button size="full" variant={"outline"}>
						Contactate
					</Button>
				</a>
			),
		},
		metamap: {
			title: "Ya casi...",
			subtitle:
				"Falta poco para arrancar. Solos nos falta verificar tus datos.",
			alertTitle: "Verificando tus datos...",
			alertDescription:
				"Este proceso puede tardar hasta 4 horas. Te avisaremos cuando la verificación esté completa y estés listo para comenzar a cobrar con Talo.",
			cover: <LoadingProcessCover />,
			footer: <Progress value={progress}></Progress>,
		},
		approved: {
			title: "¡Bienvenido a Talo!",
			subtitle:
				"Hemos verificado tus datos personales. ¡Ya podés comenzar a cobrar!",
			alertTitle: "Approved",
			alertDescription: "Descripción para aprobado",
			cover: <ApprovedProcessCover />,
			footer: (
				<Button
					onClick={() => {
						// Navigate to the next step

						if (globalStore && globalStore.deepLink) {
							navigate(globalStore.deepLink, { replace: true })
						} else {
							navigate("/")
						}
					}}
				>
					Siguiente
				</Button>
			),
		},
	}

	// Determine the current status
	let currentStatus: "rejected" | "metamap" | "approved"

	if (kyc.data?.kyc_status === "approved" && user.pollux_account_id) {
		currentStatus = "approved"
	} else if (
		kyc.data?.kyc_status === "rejected" ||
		kyc.data?.kyc_status === "pending"
	) {
		currentStatus = "rejected"
	} else {
		currentStatus = "metamap"
	}
	// Get the attributes for the current status, default to an empty object if status is not found
	const { title, alertTitle, alertDescription, subtitle, footer, cover } =
		statusAttributes[currentStatus] ?? {}

	useEffect(() => {
		const intervalId = setInterval(() => {
			kyc.refetch()
		}, 1000 * 60 * 1) // Adjust the interval time (in milliseconds) as needed

		return () => clearInterval(intervalId) // Cleanup the interval on component unmount
	}, [])

	return (
		<>
			<PlatformHeader title="Verificación" variant="secondary"></PlatformHeader>
			<CenteredBody className="justify-between h-full pb-32 px-screenMobile">
				<Col className="gap-betweenSections">
					<Col className="gap-betweenSections">
						<SectionHeader title={title} subtitle={subtitle} />
						{currentStatus === "approved" ? (
							""
						) : (
							<Alert
								title={alertTitle}
								description={alertDescription}
								icon={<Clock className="animate-pulse" />}
								bulletList={currentStatus === "rejected" ? true : false}
							/>
						)}
					</Col>
					<div className="flex justify-center mt-20 bg-background-taloLogo">
						{cover}
					</div>
				</Col>
				<Spacer axis="vertical" type="betweenSections"></Spacer>
				<Col className="gap-betweenComponents ">
					{footer}
					<Button
						variant="ghost"
						className="text-text-interactive"
						onClick={() => navigate("/onboarding/payment-methods")}
					>
						Agregar más métodos de pago
					</Button>
				</Col>
			</CenteredBody>
		</>
	)
}

export default VerifyingBody

// export function ProgressDemo({ progress }: { progress: number }) {
// 	return progress >= 100 ? null : <Progress value={progress} />
// }
