import { QRCodeSVG } from "qrcode.react"
import { Charge } from "../../pages/charge/charge-wizard-types"
import { Button } from "../ui/button"
import { Input } from "../ui/inputs/input"
import Col from "../ui/layouts/column"
import CenteredBody from "../ui/layouts/platform/body/CenteredBody"
import { SectionHeader } from "../ui/section-header"
import TimerCard from "./TimerCard"
import { formatPriceAmount } from "../../utils/numbers"
import { Section } from "../ui/section"
import Row from "../ui/layouts/row"

const LinkChargeBody = ({
	payment,
	motive,
	onCancel,
}: {
	payment: Charge
	motive?: string
	onCancel: () => void
}) => {
	const wppMessageText = `Hola! Te paso el link para que puedas abonar tu compra. Saludos! 🚀\n${payment.payment_url}`
	return (
		<CenteredBody>
			<SectionHeader
				title="Compartí el link o QR"
				subtitle="Ya podés compartir el link o QR para recibir el pago de tu cliente"
			/>
			<TimerCard
				creation_timestamp={payment.creation_timestamp}
				expiration_timestamp={payment.expiration_timestamp}
			/>
			<div className="flex justify-center items-center  w-full">
				<QRCodeSVG
					className="justify-center p-sm bg-white rounded-sm"
					level="M"
					value={payment.payment_url}
					size={250}
				/>
			</div>

			<div className="flex flex-col gap-betweenFields">
				<Input
					id="monto"
					placeholder={formatPriceAmount(
						payment.price.amount,
						undefined,
						payment.price.currency
					)}
					disabled
					label="Monto"
					data-test="payment-link-share-amount-input"
				/>
				{motive && (
					<Input id="motivo" placeholder={motive} disabled label="Motivo" />
				)}
				<Input
					readOnly
					label="Link"
					value={payment.payment_url}
					type="copy"
					data-test="payment-link-share-url-input"
				/>
			</div>

			<Row className="gap-betweenButtons">
				<Button variant="outline" size="full" onClick={onCancel}>
					Volver al inicio
				</Button>
				<Button variant="secondary" size="full" asChild>
					<a
						href={`https://wa.me/?text=${encodeURI(wppMessageText)}`}
						target="_blank"
						rel="noreferrer"
					>
						Compartir
					</a>
				</Button>
			</Row>
		</CenteredBody>
	)
}

export default LinkChargeBody
