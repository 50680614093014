import {
	ArrowRightLeft,
	LogOut,
	Menu,
	QrCode,
	ShoppingBag,
	Users,
} from "lucide-react"
import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { useAuth } from "../../../../context/AuthContext"
import { PublicRole, getMaxRole } from "../../../../lib/roles"
import { Button } from "../../button"
import LabelButton from "../../buttons/LabelButton"
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from "../../dropdown-menu"
import { statusClasses } from "./BottomBarMenuItem"
import { Body1, Heading6 } from "../../texts/TextStyle"

const BottomBarDropdown = () => {

	const [open, setOpen] = useState(false)
	const { user, logout } = useAuth()
	const labelButtonClass =
		"bg-[#343434] text-white gap-4 rounded-md p-md flex flex-col justify-center items-center w-full"
	const isPos = getMaxRole(user.roles ?? user.talo_role_list) === PublicRole.POS
	const navigate = useNavigate()

	return (
		<DropdownMenu onOpenChange={(open) => setOpen(open)}>
			<DropdownMenuTrigger asChild>
				<div
					className={
						"w-[56px] h-[56px] p-xs flex items-center justify-center " +
						statusClasses[open ? "active" : "inactive"]
					}
				>
					<Menu />
				</div>
			</DropdownMenuTrigger>
			<DropdownMenuContent
				sideOffset={24}
				side="top"
				className="max-h-[392px] p-sm  w-[calc(var(--radix-popper-available-width)-2*theme(spacing.screenMobile))] mx-screenMobile rounded-lg border-border-navbar bg-[#1C1C1C] border-8"
			>
				<DropdownMenuGroup className="gap-2 flex flex-row">
					<Button
						size="full"
						className="bg-neutral-800 text-negative-200 rounded-md"
						onClick={(op: any) => logout()}
					>
						Cerrar sesión
					</Button>
					<Button
						size="full"
						className=" bg-neutral-800 text-emerald-200 rounded-md"
						asChild
					>
						<a href="https://wa.me/541133711752/?text=Hola,%20tuve%20un%20problema%20con%20la%20plataforma.">
							Asistencia
						</a>
					</Button>
				</DropdownMenuGroup>
				<DropdownMenuSeparator className="bg-neutral-700 mx-screenMobile my-4" />
				{/* <DropdownMenuGroup className="flex flex-row gap-betweenComponents pb-betweenComponents">
					{!isPos && (
						<Button className="basis-1/2 bg-stone-800 text-amber-300" asChild>
							<Link to="/settings/payment-methods">Integraciones</Link>
						</Button>
					)}
					<Button className="basis-1/2 bg-neutral-800 text-emerald-200" asChild>
						<a href="https://wa.me/541133711752/?text=Hola,%20tuve%20un%20problema%20con%20la%20plataforma.">
							Asistencia
						</a>
					</Button>
				</DropdownMenuGroup> */}
				<DropdownMenuGroup className="flex flex-row gap-2 my-2 ">
					<div
						className={labelButtonClass}
						onClick={() => navigate("/payments")}
					>
						<ArrowRightLeft className="text-white size-7" />
						<Body1 className="text-text-muted text-center">Pagos</Body1>
					</div>

					{!isPos && (
						<div
							className={labelButtonClass}
							onClick={() => navigate("/settings/payment-methods")}
						>
							<ShoppingBag className="text-white size-7" />
							<Body1 className="text-text-muted text-center">Checkout</Body1>
						</div>
					)}
				</DropdownMenuGroup>
				<DropdownMenuGroup className="flex flex-row gap-2 my-2">
					<div className={labelButtonClass} onClick={() => navigate("/qrs")}>
						<QrCode className="text-white size-7" />
						<Body1 className="text-text-muted text-center">QR`s</Body1>
					</div>

					{!isPos && (
						<div
							className={labelButtonClass}
							onClick={() => navigate("/teams")}
						>
							<Users className="text-white size-7" />
							<Body1 className="text-text-muted text-center">Equipos</Body1>
						</div>
					)}
				</DropdownMenuGroup>
			</DropdownMenuContent>
		</DropdownMenu>
	)
}

export default BottomBarDropdown
