import { createContext, useContext } from "react"

export type AnalyticsContextState = {
	setUser: (id: string) => void
	trackEvent: (name: string, data: Record<string, any>) => void
	setUserProperty: (propertyName: string, propertyValue: string) => void
}

export const AnalyticsContext = createContext<
	AnalyticsContextState | undefined
>(undefined)

export const useAnalytics = () => useContext(AnalyticsContext)
